import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { Environment } from '../../types/environment';
import {
  Cargo,
  CargoOrder,
  CargoOrderGroup,
  CreateEmptyCargoPayload,
} from '../../types/cargo';

@Injectable({
  providedIn: 'root',
})
export class CargoRepositoryService {
  private readonly API_CARGO = `${this.env.apiUrl}/cargo`;
  private readonly API_ORDER_GROUP = `${this.env.apiUrl}/orderGroup`;
  private readonly API_ORDER = `${this.env.apiUrl}/order`;
  constructor(
    @Inject(APP_ENVIRONMENT)
    private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  getCargoById(id: number) {
    return this.http.get<Cargo>(`${this.API_CARGO}/getById?id=${id}`);
  }

  updateCargo(cargo: Cargo) {
    return this.http.post<Cargo>(`${this.API_CARGO}/save`, cargo);
  }

  createOrderGroup(cargoId: number) {
    return this.http.post<CargoOrderGroup>(
      `${this.API_ORDER_GROUP}/create?cargoId=${cargoId}`,
      null,
    );
  }

  updateOrderGroup(orderGroup: CargoOrderGroup) {
    return this.http.post<CargoOrderGroup>(
      `${this.API_ORDER_GROUP}/save`,
      orderGroup,
    );
  }

  deleteOrderGroup(id: number) {
    return this.http.post<void>(
      `${this.API_ORDER_GROUP}/deleteById?id=${id}`,
      null,
    );
  }

  createOrder(orderGroupId: number) {
    return this.http.post<CargoOrder>(
      `${this.API_ORDER}/create?orderGroupId=${orderGroupId}`,
      null,
    );
  }

  updateOrder(order: CargoOrder) {
    return this.http.post<CargoOrder>(`${this.API_ORDER}/save`, order);
  }

  updateOrders(orders: CargoOrder[]) {
    return this.http.post<CargoOrder[]>(`${this.API_ORDER}/updateAll`, orders);
  }

  deleteOrder(id: number) {
    return this.http.post<void>(`${this.API_ORDER}/deleteById?id=${id}`, null);
  }

  createEmptyCargo(payload: {
    preOrderIds: CreateEmptyCargoPayload['preOrderIds'];
    trailerType: CreateEmptyCargoPayload['cargo']['trailerType'];
    transportType: CreateEmptyCargoPayload['cargo']['transportType'];
  }) {
    const cargoPayload: CreateEmptyCargoPayload = {
      cargo: {
        id: null,
        creationDate: null,
        uploadDate: null,
        transportType: payload.transportType,
        trailerType: payload.trailerType,
        sellerJson: {
          id: null,
          inn: null,
          name: null,
        },
        cargoPlacesQuantityJson: [],
        paymentsJson: [],
        userId: null,
        orderGroups: [],
        loadingAddressesJson: [],
        unloadingAddressesJson: [],
        carrierJson: {
          name: null,
          cost: null,
          currencyLetter: null,
          paymentType: null,
          transports: [],
        },
        status: null,
        transitPointsJson: [],
        grossWeight: null,
        payments: [],
      },
      preOrderIds: payload.preOrderIds,
    };
    return this.http.post<Cargo>(
      `${this.API_CARGO}/createEmptyCargo`,
      cargoPayload,
    );
  }

  moveFromLoadingToCalculation({
    orderIds,
    id,
  }: {
    orderIds: number[];
    id: number;
  }) {
    return this.http.post(
      `${this.API_CARGO}/createOrderGroups?id=${id}`,
      orderIds,
    );
  }

  moveFromCalculationToOnTheWay(id: number) {
    return this.http.post(`${this.API_CARGO}/onTheWay?id=${id}`, null);
  }

  getOrdersByCargoId(cargoId: number) {
    return this.http.get<CargoOrder[]>(
      `${this.API_ORDER}/getByCargoId?cargoId=${cargoId}`,
    );
  }

  addPreOrders({ preOrderIds, id }: { preOrderIds: number[]; id: number }) {
    return this.http.post<Cargo>(
      `${this.API_CARGO}/addPreOrders?id=${id}`,
      preOrderIds,
    );
  }

  deletePreOrder({ preOrderId, id }: { preOrderId: number; id: number }) {
    return this.http.post<Cargo>(
      `${this.API_CARGO}/deletePreOrder?id=${id}&preOrderId=${preOrderId}`,
      null,
    );
  }

  recalculateAddresses(id: number) {
    return this.http.post<Cargo>(
      `${this.API_CARGO}/recalculateAddresses?id=${id}`,
      null,
    );
  }
}
