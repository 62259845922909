import { Route } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { RouteNames } from '../../route-names';
import { CargoCalculationComponent } from './cargo-calculation.component';
import { CargoCalculationState } from './store/cargo-calculation.state';

export const CARGO_CALCULATION_ROUTE: Route = {
  title: 'Расчет ставки',
  path: RouteNames.CARGO_CALCULATION.concat('/:id'),
  component: CargoCalculationComponent,
  providers: [provideStates([CargoCalculationState])],
};
