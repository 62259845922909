import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { AttachmentService } from '@shared/services/attachment.service';
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { TuiButtonModule, TuiDialogContext } from '@taiga-ui/core';
import { FileInputComponent } from '@shared/components/forms/file-input/file-input.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FilesInputComponent } from '@shared/components/forms/files-input/files-input.component';
import { AlertService } from '@shared/services/alert.service';
import { AsyncPipe } from '@angular/common';
import { roundDecimal } from '@shared/utils/math';
import { mimeTypes } from '@shared/constants/mime-types';
import { Attachment } from '../../../types/attachment';

export type AttachmentsManagerInputData = {
  cargoId: number;
  attachmentId: Nullable<number>;
};

@Component({
  selector: 'app-attachments-manager',
  standalone: true,
  imports: [
    FileInputComponent,
    TuiButtonModule,
    ReactiveFormsModule,
    FilesInputComponent,
    AsyncPipe,
  ],
  templateUrl: './attachments-manager.component.html',
  styleUrl: './attachments-manager.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AttachmentsManagerComponent implements OnInit {
  refresh$ = new Subject<void>();
  attachmentId$ = new BehaviorSubject<Nullable<number>>(null);
  cargoId$ = new BehaviorSubject<number>(0);
  attachments$ = new BehaviorSubject<Attachment[]>([]);
  attach$ = new Subject<void>();
  download$ = new Subject<void>();
  delete$ = new Subject<void>();
  files = this.fb.control<Blob[]>([]);
  protected readonly roundDecimal = roundDecimal;

  protected readonly mimeTypes = mimeTypes;
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    protected readonly context: TuiDialogContext<
      Nullable<number>,
      AttachmentsManagerInputData
    >,
    private readonly attachmentService: AttachmentService,
    private readonly destroyRef: DestroyRef,
    private readonly fb: FormBuilder,
    private readonly alert: AlertService,
  ) {}

  onDownload() {
    this.download$
      .pipe(
        map(() => this.attachmentId$.value),
        filter((attachmentId): attachmentId is number => !!attachmentId),
        switchMap(attachmentId =>
          this.attachmentService
            .download(this.cargoId$.value, attachmentId)
            .pipe(
              catchError(err => {
                this.alert.showError();
                return of(null);
              }),
            ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onRefresh() {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (this.attachmentId$.value) {
            return this.attachmentService.getFileListFromAttachment(
              this.cargoId$.value,
              this.attachmentId$.value,
            );
          }
          return of([]);
        }),
        tap(attachments => {
          this.attachments$.next(attachments);
        }),
      )
      .subscribe();
  }

  onDelete() {
    this.delete$
      .pipe(
        map(() => this.attachmentId$.value),
        filter((attachmentId): attachmentId is number => !!attachmentId),
        switchMap(attachmentId =>
          this.attachmentService.delete(this.cargoId$.value, attachmentId).pipe(
            tap(() => {
              this.attachmentId$.next(null);
              this.attachments$.next([]);
              this.onAttachmentIdChange();
            }),
            catchError(() => {
              this.alert.showError();
              return of(null);
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onAttach() {
    this.attach$
      .pipe(
        switchMap(() => {
          const files = this.files.getRawValue() ?? [];
          const formData = new FormData();

          files.forEach((file, index) => {
            const fileKey = `file${index + 1}`;
            formData.append(fileKey, file as unknown as Blob);
          });

          return this.attachmentService
            .attach(this.cargoId$.value, this.attachmentId$.value, formData)
            .pipe(
              tap(id => {
                this.files.reset();
                this.attachmentId$.next(id);
                this.refresh$.next();
                this.onAttachmentIdChange();
              }),
              catchError(() => {
                this.alert.showError();
                return of(null);
              }),
            );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onAttachmentIdChange() {
    this.context.$implicit.next(this.attachmentId$.value);
  }

  ngOnInit() {
    this.attachmentId$.next(this.context.data.attachmentId);
    this.cargoId$.next(this.context.data.cargoId);
    this.onRefresh();
    this.onAttach();
    this.onDownload();
    this.onDelete();
    this.refresh$.next();
  }
}
