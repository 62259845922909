import { Inject, Injectable, Renderer2 } from '@angular/core';
import { of, switchMap, tap, withLatestFrom } from 'rxjs';
import { HtmlToCanvasService } from '@shared/services/html-to-canvas.service';
import _ from 'lodash';
import moment from 'moment';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { DOCUMENT } from '@angular/common';
import { Cargo, CargoLoadingAddress, CargoOrder } from '../../../types/cargo';

type PreparedAddress = CargoLoadingAddress & {
  coords: string;
  gateAccessCode: string;
};
@Injectable()
export class DriversReportService {
  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly htmlToCanvas: HtmlToCanvasService,
    private readonly renderer2: Renderer2,
    private readonly cargoCatalogsService: CargoCatalogsService,
  ) {}

  generate(cargo: Cargo, orders: CargoOrder[]) {
    return of({ cargo, orders }).pipe(
      withLatestFrom(this.cargoCatalogsService.farms$),
      switchMap(([{ cargo, orders }, farms]) => {
        const loadingAddresses = cargo?.loadingAddressesJson ?? [];
        const container = this.createContainer();

        const groupedByFarmName = _.groupBy(
          orders,
          preOrder => preOrder.farmJson.name ?? '',
        );

        const prepared: PreparedAddress[] = loadingAddresses.map(a => ({
          ...a,
          coords: `${a.latitude ?? 0},${a.longitude ?? 0}`,
          gateAccessCode:
            farms.find(farm => farm.name === a.farmName)?.gateAccessCode ??
            '(не указан)',
        }));

        prepared.forEach(address => {
          const table = this.createTable();
          const tbody = this.renderer2.createElement('tbody');

          this.appendAddressInfo(tbody, address);
          const farmOrders = groupedByFarmName[address.farmName ?? ''] ?? [];
          this.appendOrdersInfo(tbody, farmOrders);

          this.renderer2.appendChild(table, tbody);
          this.renderer2.appendChild(container, table);
        });

        this.document.body.appendChild(container);

        return this.htmlToCanvas
          .drawHtml(container)
          .pipe(tap(url => this.downloadImage(url, container)));
      }),
    );
  }

  private downloadImage(url: string, container: HTMLElement): void {
    const link = document.createElement('a');
    link.href = url;
    link.download = `Пункты загрузки ${moment().format('DD.MM.YYYY HH:mm:ss')}.png`;
    link.click();
    this.document.body.removeChild(container);
  }

  private appendTableRow(
    tbody: HTMLElement,
    title: string,
    value: string,
  ): void {
    const row = this.renderer2.createElement('tr');
    const tdTitle = this.renderer2.createElement('td');
    const tdValue = this.renderer2.createElement('td');

    this.renderer2.appendChild(tdTitle, this.renderer2.createText(title));
    this.renderer2.appendChild(tdValue, this.renderer2.createText(value));

    this.renderer2.appendChild(row, tdTitle);
    this.renderer2.appendChild(row, tdValue);
    this.renderer2.appendChild(tbody, row);
  }

  private appendOrdersInfo(tbody: HTMLElement, orders: CargoOrder[]): void {
    orders.forEach(order => {
      const title = `Клиент: ${order.buyerCode}`;
      const places = order.packageQuantity
        ? `BX:${order.packageQuantity} - ${order.packageTypeJson.name ?? ''};`
        : `Места: ${order.cargoPlaceQuantity} - ${order.cargoPlaceJson.name ?? ''};`;
      const cold = order.cold ? 'ХОЛОД!' : '';
      this.appendTableRow(tbody, title, `${places} ${cold}`);
    });
  }

  private appendAddressInfo(
    tbody: HTMLElement,
    address: PreparedAddress,
  ): void {
    const keys: Partial<Record<keyof PreparedAddress, string>> = {
      farmName: 'Питомник',
      coords: 'Координаты',
      gateAccessCode: 'Код от ворот',
    };

    Object.entries(keys).forEach(([key, title]) => {
      this.appendTableRow(
        tbody,
        title,
        address[key as keyof PreparedAddress]?.toString() ?? '',
      );
    });
  }

  private createContainer(): HTMLDivElement {
    const container = this.renderer2.createElement('div');
    this.renderer2.setAttribute(container, 'class', 'p-4 bg-white');
    return container;
  }

  private createTable(): HTMLTableElement {
    const table: HTMLTableElement = this.renderer2.createElement('table');
    this.renderer2.setAttribute(
      table,
      'class',
      'plain-table plain-table--bordered w-[1080px] mb-[20px]',
    );
    this.renderer2.setAttribute(table, 'style', 'text-align:left');
    this.renderer2.setAttribute(table, 'border', '1');
    return table;
  }
}
