import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { ScrollTopButtonComponent } from '@shared/components/scroll-top-button/scroll-top-button.component';
import { ActivatedRoute } from '@angular/router';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { Store } from '@ngxs/store';
import { FetchUpdates } from '@store/catalogs/catalogs.actions';
import { catchError, of, startWith, switchMap, take } from 'rxjs';
import { TransitPointService } from '@shared/services/transit-point.service';
import { AlertService } from '@shared/services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { CargoCharacteristicsComponent } from './components/cargo-characteristics/cargo-characteristics.component';
import { MainInfoComponent } from './components/main-info/main-info.component';
import { CargoLoadingService } from './services/cargo-loading.service';
import { LoadingAddressesComponent } from './components/loading-addresses/loading-addresses.component';
import { UnloadingAddressesComponent } from './components/unloading-addresses/unloading-addresses.component';
import { TransitPointsComponent } from './components/transit-points/transit-points.component';
import { CarrierComponent } from './components/carrier/carrier.component';
import { CatalogsService } from './services/catalogs.service';
import { DriversReportService } from './services/drivers-report.service';
import { OrdersComponent } from './components/orders/orders.component';
import { CommonStateService } from './services/common-state.service';

@Component({
  selector: 'app-cargo-loading',
  standalone: true,
  imports: [
    ScrollTopButtonComponent,
    MainInfoComponent,
    CargoCharacteristicsComponent,
    LoadingAddressesComponent,
    UnloadingAddressesComponent,
    TransitPointsComponent,
    CarrierComponent,
    OrdersComponent,
  ],
  providers: [CargoLoadingService, CatalogsService, DriversReportService],
  templateUrl: './cargo-loading.component.html',
  styleUrl: './cargo-loading.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoLoadingComponent implements OnInit {
  constructor(
    private readonly cargoLoadingService: CargoLoadingService,
    private readonly route: ActivatedRoute,
    private readonly cargoCatalogsService: CargoCatalogsService,
    private readonly store: Store,
    private readonly transitPointService: TransitPointService,
    private readonly commonStateService: CommonStateService,
    private readonly alert: AlertService,
    private readonly destroyRef: DestroyRef,
  ) {}

  getCargoId() {
    return this.route.snapshot.params.id as number;
  }

  refreshPreOrders() {
    this.commonStateService.refreshPreOrders$
      .pipe(
        startWith(true),
        switchMap(() =>
          this.cargoLoadingService.fetchPreOrdersByCargoId(this.getCargoId()),
        ),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.store.dispatch(new FetchUpdates());
    this.cargoCatalogsService.preload();
    const id = this.getCargoId();
    this.cargoLoadingService.setSelectedCargo(id).pipe(take(1)).subscribe();
    this.cargoLoadingService.fetchOrdersByCargoId(id).pipe(take(1)).subscribe();
    this.transitPointService.preload().pipe(take(1)).subscribe();
    this.refreshPreOrders();
  }
}
