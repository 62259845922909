import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { LoadingItemForm } from '@shared/components/loading-calculator/types';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  TuiButtonModule,
  TuiGroupModule,
  TuiHintModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { CheckboxComponent } from '@shared/components/forms/checkbox/checkbox.component';
import { TuiInputNumberModule } from '@taiga-ui/kit';
import {
  distinctUntilChanged,
  map,
  merge,
  Observable,
  shareReplay,
  skip,
  startWith,
  tap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { getCargoPlaceQuantityByTrailerType } from '@shared/constants/vehicles';
import { roundDecimal } from '@shared/utils/math';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { DEFAULT_TRAILER } from '@shared/components/loading-calculator/constants';
import { TrailerType } from '../../../../../types/trailer';

@Component({
  selector: 'app-loading-item',
  standalone: true,
  imports: [
    TuiButtonModule,
    AsyncPipe,
    AutocompleteComponent,
    ReactiveFormsModule,
    CheckboxComponent,
    TuiGroupModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    TuiHintModule,
  ],
  templateUrl: './loading-item.component.html',
  styleUrl: './loading-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingItemComponent implements OnInit {
  @Input() loadingForm: FormGroup<LoadingItemForm>;
  @Output() create = new EventEmitter<void>();
  @Output() remove = new EventEmitter<void>();
  @Input({ required: true }) last: boolean;
  hasBox$: Observable<boolean>;
  preparedPackageTypes$ = this.catalogs.packageTypes$.pipe(
    map(packageTypes =>
      packageTypes.map(packageType => ({
        ...packageType,
        itemText: `${packageType.code} - ${packageType.name}`,
      })),
    ),
  );

  cargoPlaceQuantityLimit = this.fb.control<Nullable<number>>({
    value: null,
    disabled: true,
  });

  constructor(
    private readonly fb: FormBuilder,
    protected readonly catalogs: CargoCatalogsService,
    private readonly destroyRef: DestroyRef,
  ) {}

  onPackageTypeChange() {
    this.loadingForm.controls.packageTypeJson.controls.code.valueChanges
      .pipe(
        tap(packageType => {
          this.updatePackageDetails(packageType);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onHasBoxChange() {
    this.hasBox$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        tap(hasBox => {
          if (!hasBox) {
            this.loadingForm.controls.packageTypeJson.reset();
            this.loadingForm.controls.packageQuantity.reset();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onCargoPlaceTypeChange() {
    this.cargoPlaceJson.controls.code.valueChanges
      .pipe(
        tap(type => {
          this.updateCargoPlaceDetails(type, DEFAULT_TRAILER);
          this.clearPackageDetails();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  clearPackageDetails() {
    this.packageTypeJson.reset();
    this.loadingForm.patchValue({
      packageQuantityLimit: null,
      packageQuantity: null,
    });
  }

  get cargoPlaceJson() {
    return this.loadingForm.controls.cargoPlaceJson;
  }

  get packageTypeJson() {
    return this.loadingForm.controls.packageTypeJson;
  }

  updatePackageDetails(packageTypeCode: Nullable<string>) {
    const packageDetails = this.catalogs.packageTypesMap.get(
      packageTypeCode || '',
    );
    if (packageDetails) {
      this.loadingForm.controls.packageTypeJson.patchValue(packageDetails, {
        emitEvent: false,
      });
    } else {
      this.loadingForm.controls.packageTypeJson.reset({}, { emitEvent: false });
      this.loadingForm.controls.packageTypeJson.markAsDirty();
    }
    const packageCargoPlace = packageDetails?.cargoPlaceQuantityJson.find(
      place => place.cargoPlaceId === this.cargoPlaceJson.value.id,
    );
    this.loadingForm.controls.packageQuantityLimit.setValue(
      packageCargoPlace?.quantity ?? 0,
    );
  }

  getCalculatedQuantity(
    packageQuantityLimit: Nullable<number>,
    packageQuantity: Nullable<number>,
  ) {
    return packageQuantityLimit && packageQuantity
      ? roundDecimal(packageQuantity / Number(packageQuantityLimit))
      : 0;
  }

  calculatePlaceQuantity() {
    merge(
      this.loadingForm.controls.packageQuantityLimit.valueChanges,
      this.loadingForm.controls.packageQuantity.valueChanges,
    )
      .pipe(
        map(() => {
          const { packageQuantityLimit = 0, packageQuantity = 0 } =
            this.loadingForm.getRawValue();
          return [packageQuantityLimit, packageQuantity];
        }),
        tap(([packageQuantityLimit, packageQuantity]) => {
          this.loadingForm.controls.cargoPlaceQuantity.setValue(
            this.getCalculatedQuantity(packageQuantityLimit, packageQuantity),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateCargoPlaceDetails(
    cargoPlaceId: Nullable<string>,
    trailerType: TrailerType,
  ) {
    const cargoPlaceDetails = this.catalogs.cargoPlaceMap.get(
      cargoPlaceId || '',
    );
    if (cargoPlaceDetails) {
      const limit = getCargoPlaceQuantityByTrailerType(
        trailerType,
        cargoPlaceDetails.quantityInTrailerJson,
      );
      this.cargoPlaceJson.patchValue(cargoPlaceDetails, { emitEvent: false });
      this.cargoPlaceQuantityLimit.setValue(limit);
    } else {
      this.cargoPlaceJson.reset(undefined, { emitEvent: false });
      this.cargoPlaceJson.markAsDirty();
      this.cargoPlaceQuantityLimit.setValue(null);
    }
  }

  ngOnInit() {
    this.hasBox$ = this.loadingForm.controls.box.valueChanges.pipe(
      startWith(this.loadingForm.controls.box.getRawValue()),
      map(value => value ?? false),
      takeUntilDestroyed(this.destroyRef),
      shareReplay(),
    );
    this.onPackageTypeChange();
    this.calculatePlaceQuantity();
    this.onHasBoxChange();
    this.onCargoPlaceTypeChange();
  }
}
