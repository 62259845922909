import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { tap, withLatestFrom } from 'rxjs';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import {
  countPalletsAndCarts,
  getLoadingPercent,
  getRunningMetersFromCartQuantity,
  getRunningMetersFromUSPallets,
} from '@shared/utils/cargo-calculation';
import { getComputedProperties } from '@shared/components/loading-calculator/utils';
import { CargoCalculationState } from '../../store/cargo-calculation.state';
import { Cargo, CargoOrder } from '../../../../types/cargo';
import { ComputedPropsForm } from '../../../cargo-loading/types';

@Component({
  selector: 'app-cargo-characteristics',
  standalone: true,
  imports: [
    TuiInputModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    TuiInputNumberModule,
  ],
  templateUrl: './cargo-characteristics.component.html',
  styleUrl: './cargo-characteristics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoCharacteristicsComponent implements OnInit {
  computedProperties = this.fb.group<ComputedPropsForm>({
    totalColdPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalWarmPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalBox: this.fb.control<number>({ value: 0, disabled: true }),
  });

  vehicleLoadingPercent = this.fb.control({ value: 0, disabled: true });

  sum = this.fb.control({ value: '', disabled: true });

  cargo$ = this.store.select(CargoCalculationState.getSelectedCargo);
  orderGroups$ = this.store.select(CargoCalculationState.getOrderGroups);
  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly cargoCatalogsService: CargoCatalogsService,
  ) {}

  getSum(cargo: Nullable<Cargo>) {
    const total =
      cargo?.payments.reduce(
        (previousValue, currentValue) => {
          const currency =
            currentValue.calculatedRateJson.rateClassifier?.currencyLetter ??
            '(нет валюты)';
          const { finalSum, calculatedSum } = currentValue.calculatedRateJson;
          const sum =
            (previousValue[currency] ?? 0) + (finalSum ?? calculatedSum ?? 0);
          return {
            ...previousValue,
            [currency]: sum,
          };
        },
        {} as Record<string, number>,
      ) ?? {};

    return Object.entries(total)
      .map(([currency, sum]) => `${sum} ${currency}`)
      .join('/');
  }

  updateComputedProperties() {
    this.orderGroups$
      .pipe(
        tap(groups => {
          const orders = this.getOrdersFromGroups(groups);
          this.computedProperties.setValue({
            ...getComputedProperties(orders),
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  getOrdersFromGroups(groups: Cargo['orderGroups']) {
    const orders: CargoOrder[] = [];
    groups?.forEach(group => {
      orders.push(...group.orders);
    });
    return orders;
  }

  updateVehicleLoadingPercent() {
    this.cargo$
      .pipe(
        withLatestFrom(this.cargoCatalogsService.trailersTypes$),
        tap(([cargo, trailerTypes]) => {
          const trailerType = cargo?.trailerType;
          const orders = this.getOrdersFromGroups(cargo?.orderGroups ?? []);
          if (!trailerType || !orders.length) {
            this.vehicleLoadingPercent.setValue(0);
          } else {
            const trailerTypeDetails = trailerTypes.find(
              trailer => trailer.code === trailerType,
            );
            const [pallets, carts] = countPalletsAndCarts(orders);
            const palletsMeters = getRunningMetersFromUSPallets(pallets);
            const cartsMeters = getRunningMetersFromCartQuantity(carts);
            const meters = palletsMeters + cartsMeters;
            this.vehicleLoadingPercent.setValue(
              getLoadingPercent(meters, trailerTypeDetails?.length ?? 0),
            );
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateTotalSum() {
    this.cargo$
      .pipe(
        tap(cargo => {
          this.sum.setValue(this.getSum(cargo));
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.updateComputedProperties();
    this.updateVehicleLoadingPercent();
    this.updateTotalSum();
    this.computedProperties.disable();
  }
}
