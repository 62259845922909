<div class="w-[1000px]">
  <h2 class="font-semibold text-lg mb-4">{{ title$ | async }}</h2>
  <tui-scrollbar>
    <cdk-virtual-scroll-viewport
      tuiScrollable
      [itemSize]="44"
      [style.height.px]="300"
      [maxBufferPx]="200"
      [minBufferPx]="100"
    >
      <table class="w-full" tuiTable [columns]="columns">
        <thead>
          <tr tuiThGroup>
            <th class="flex justify-center items-center" tuiTh>
              <tui-checkbox
                [ngModel]="isAllSelected$ | async"
                (ngModelChange)="selectAll$.next()"
                size="m"
              ></tui-checkbox>
            </th>
            @for (header of headers; track identity) {
              <th [width]="header.width ?? 'auto'" tuiTh>
                {{ header.name }}
              </th>
            }
          </tr>
        </thead>
        <tbody tuiTbody>
          <tr *cdkVirtualFor="let item of items$ | async" tuiTr>
            <td
              class="flex justify-center items-center"
              *tuiCell="'selected'"
              tuiTd
            >
              <tui-checkbox
                [ngModel]="isSelected(item)"
                (ngModelChange)="onSelect(item)"
                size="m"
              ></tui-checkbox>
            </td>
            @for (header of headers; track identity) {
              <td
                [width]="header.width ?? 'auto'"
                *tuiCell="stringify(header.value)"
                tuiTd
              >
                <tui-line-clamp
                  [lineHeight]="19"
                  [linesLimit]="1"
                  [content]="
                    header.isDate
                      ? (item[header.value] | date: 'dd.MM.YYYY')
                      : item[header.value]
                  "
                ></tui-line-clamp>
              </td>
            }
          </tr>
        </tbody>
      </table>
    </cdk-virtual-scroll-viewport>
  </tui-scrollbar>
  <div class="flex justify-end mt-4">
    <button
      (click)="close()"
      class="mr-2"
      appearance="outline"
      tuiButton
      size="m"
    >
      Отмена
    </button>
    <button
      [disabled]="(disabled$ | async)!"
      (click)="submit()"
      tuiButton
      size="m"
    >
      {{ submitText$ | async }}
    </button>
  </div>
</div>
