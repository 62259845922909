<div class="w-[1200px]">
  <h2 class="font-semibold mb-4 text-lg">Вложения</h2>
  <div>
    @for (file of attachments$ | async; track $index) {
      <div class="flex gap-x-2 bg-neutral-100 mb-2 rounded-xl p-2 items-center">
        <div class="font-bold">{{ $index + 1 }}</div>
        <div>{{ file.name }}</div>
        <div>{{ roundDecimal(file.length / (1024 * 1024)) }}Мб</div>
      </div>
    }
  </div>
  <div class="my-2">
    <app-files-input
      [accept]="[mimeTypes.APPLICATION_PDF, 'image/*'].join()"
      [formControl]="files"
    ></app-files-input>
  </div>

  <div class="flex gap-x-2">
    <button
      [disabled]="!files.getRawValue()?.length"
      tuiButton
      size="m"
      (click)="attach$.next()"
    >
      Загрузить файлы
    </button>
    <button
      [disabled]="!(attachments$ | async)?.length"
      appearance="outline"
      tuiButton
      size="m"
      icon="tuiIconDownload"
      (click)="download$.next()"
    >
      Скачать архив
    </button>
    <button
      class="ml-auto mr-0"
      [disabled]="!(attachments$ | async)?.length"
      appearance="secondary-destructive"
      tuiButton
      icon="tuiIconTrash"
      size="m"
      (click)="delete$.next()"
    >
      Удалить архив
    </button>
  </div>
</div>
