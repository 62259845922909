import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CargoPlaceQuantity } from '../../types/package-type';
import { CargoPlaceJsonForm } from '../../types/cargo-place';
import {
  CargoOrder,
  CargoPlaceJson,
  ClientJson,
  RateJson,
} from '../../types/cargo';
import {
  AddressWithCoordinates,
  AddressWithCoordinatesForm,
  ClientForm,
} from '../../types/client';
import { Farm, FarmForm } from '../../types/farm';
import {
  CalculatedRateJsonForm,
  DealDetailsJson,
  DealDetailsJsonForm,
} from '../../types/payment';

@Injectable({
  providedIn: 'root',
})
export class FormsService {
  constructor(private readonly fb: FormBuilder) {}

  getPackageTypeJsonForm() {
    return this.fb.group({
      id: this.fb.control<Nullable<number>>(null),
      code: '',
      name: '',
      size: '',
      cargoPlaceQuantityJson: this.fb.control<Nullable<CargoPlaceQuantity[]>>(
        [],
      ),
      weight: this.fb.control<Nullable<number>>(null),
    });
  }

  getCargoPlaceJsonForm(
    cargoPlaceJson?: CargoPlaceJson,
  ): FormGroup<CargoPlaceJsonForm> {
    return this.fb.group<CargoPlaceJsonForm>({
      id: this.fb.control<Nullable<number>>(cargoPlaceJson?.id ?? null),
      code: this.fb.control(cargoPlaceJson?.code ?? ''),
      name: this.fb.control(cargoPlaceJson?.name ?? ''),
      size: this.fb.control(cargoPlaceJson?.size ?? ''),
      quantityInTrailerJson: this.fb.nonNullable.control(
        cargoPlaceJson?.quantityInTrailerJson ?? [],
      ),
    });
  }

  getFarmJsonForm(farm?: Farm): FormGroup<FarmForm> {
    return this.fb.group<FarmForm>({
      id: this.fb.control<Nullable<number>>(farm?.id ?? null),
      flowerTypesJson: this.fb.nonNullable.control<string[]>(
        farm?.flowerTypesJson || [],
      ),
      latitude: this.fb.control(farm?.latitude ?? null),
      longitude: this.fb.control(farm?.longitude ?? null),
      gateAccessCode: this.fb.control(farm?.gateAccessCode ?? null),
      name: this.fb.control(farm?.name ?? null),
      address: this.fb.control(farm?.address ?? null),
      imported: this.fb.control<Nullable<boolean>>(farm?.imported ?? null),
      precoolingRate: this.fb.control(farm?.precoolingRate ?? null),
      currencyLetter: this.fb.control(farm?.currencyLetter ?? null),
    });
  }

  getClientJsonForm(client?: ClientJson): FormGroup<ClientForm> {
    return this.fb.group<ClientForm>({
      id: this.fb.control<Nullable<number>>(client?.id ?? null),
      name: this.fb.control(client?.name ?? null),
      inn: this.fb.control(client?.inn ?? null),
      addressesWithCoordinatesJson: this.fb.nonNullable.control(
        client?.addressesWithCoordinatesJson ?? [],
      ),
      discount: this.fb.control(client?.discount ?? null),
    });
  }

  getRateJsonForm(rate?: RateJson) {
    return this.fb.group({
      id: this.fb.control<Nullable<number>>(rate?.id ?? null),
      currencyLetter: this.fb.control<Nullable<string>>(
        rate?.currencyLetter ?? null,
      ),
      measureUnitLetter: this.fb.control<Nullable<string>>(
        rate?.measureUnitLetter ?? null,
      ),
      cargoPlaceCode: this.fb.control<Nullable<string>>(
        rate?.cargoPlaceCode ?? null,
      ),
      rate: this.fb.control<Nullable<number>>(rate?.rate ?? null),
      dOn: this.fb.control<Nullable<string>>(rate?.dOn ?? null),
      dOff: this.fb.control<Nullable<string>>(rate?.dOff ?? null),
      updated: this.fb.control<Nullable<string>>(rate?.updated ?? null),
      imported: this.fb.control<Nullable<boolean>>(rate?.imported ?? null),
      flowerTypeId: this.fb.control<Nullable<string>>(
        rate?.flowerTypeId ?? null,
      ),
    });
  }

  getClientAddressWithCoordinatesJson(
    address?: AddressWithCoordinates,
  ): FormGroup<AddressWithCoordinatesForm> {
    return this.fb.group({
      address: this.fb.control(address?.address ?? null),
      longitude: this.fb.control(address?.longitude ?? null),
      latitude: this.fb.control(address?.latitude ?? null),
    });
  }

  getCalculatedJsonForm() {
    return this.fb.group<CalculatedRateJsonForm>({
      rateClassifier: this.fb.control(null),
      calculatedSum: this.fb.control(null),
      finalSum: this.fb.control(null),
    });
  }

  getDealDetailsJsonForm(dealDetails?: DealDetailsJson) {
    return this.fb.group<DealDetailsJsonForm>({
      paid: this.fb.control(dealDetails?.paid ?? null),
      paymentType: this.fb.control(dealDetails?.paymentType ?? null),
      paymentMethod: this.fb.control(dealDetails?.paymentMethod ?? null),
      recipientName: this.fb.control(dealDetails?.recipientName ?? null),
      recipientPhone: this.fb.control(dealDetails?.recipientPhone ?? null),
      comment: this.fb.control(dealDetails?.comment ?? null),
    });
  }

  getCargoOrderForm(cargoOrder: CargoOrder) {
    const cargoPlaceJson = this.getCargoPlaceJsonForm(
      cargoOrder.cargoPlaceJson,
    );
    return this.fb.group({
      ...cargoOrder,
      packageQuantityLimit: this.fb.control({
        value: cargoOrder.packageQuantityLimit,
        disabled: true,
      }),
      cargoPlaceQuantity: this.fb.control({
        value: cargoOrder.cargoPlaceQuantity,
        disabled: true,
      }),
      rateJson: this.fb.group(cargoOrder.rateJson),
      cargoPlaceJson,
      packageTypeJson: this.fb.group({
        ...cargoOrder.packageTypeJson,
        cargoPlaceQuantityJson: this.fb.control<Nullable<CargoPlaceQuantity[]>>(
          cargoOrder.packageTypeJson.cargoPlaceQuantityJson,
        ),
      }),
      farmJson: this.getFarmJsonForm(cargoOrder.farmJson),
      clientJson: this.getClientJsonForm(cargoOrder.clientJson),
      clientAddressWithCoordinatesJson:
        this.getClientAddressWithCoordinatesJson(
          cargoOrder.clientAddressWithCoordinatesJson,
        ),
    });
  }
}
