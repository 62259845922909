import { Injectable, NgZone } from '@angular/core';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';
import { of } from 'rxjs';
import { toPng } from 'html-to-image';

@Injectable({
  providedIn: 'root',
})
export class HtmlToCanvasService {
  constructor(private readonly zone: NgZone) {}

  drawHtml(html: HTMLElement) {
    return fromPromise(toPng(html));
  }

  canvasToImage(canvas: HTMLCanvasElement, type = 'image/png') {
    return of(canvas.toDataURL(type, 1));
  }
}
