import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ScrollTopButtonComponent } from '@shared/components/scroll-top-button/scroll-top-button.component';
import { PreOrdersComponent } from '../../components/pre-orders/pre-orders.component';
import { CatalogsService } from '../../services/catalogs.service';

import { PreOrdersSortingComponent } from '../../components/pre-orders-sorting/pre-orders-sorting.component';
import { TotalInfoComponent } from '../../components/total-info/total-info.component';
import { SelectionTotalInfoComponent } from '../../components/selection-total-info/selection-total-info.component';
import { PreOrderService } from '../../services/pre-order.service';

@Component({
  selector: 'app-pre-orders-page',
  standalone: true,
  imports: [
    ScrollTopButtonComponent,
    PreOrdersComponent,
    PreOrdersSortingComponent,
    TotalInfoComponent,
    SelectionTotalInfoComponent,
  ],
  providers: [PreOrderService, CatalogsService],
  templateUrl: './pre-orders-page.component.html',
  styleUrl: './pre-orders-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreOrdersPageComponent implements OnInit {
  constructor(private readonly catalogs: CatalogsService) {}

  ngOnInit() {
    this.catalogs.preload();
  }
}
