export type Trailer = {
  id: number;
  code: string;
  name: string;
  width: number;
  length: number;
};

export enum TrailerType {
  REFRIGERATOR_WIDE = 'refrigerator_wide',
}
