@if (!formControl.value) {
  <tui-input-files [formControl]="formControl" (reject)="onReject($event)">
    <input tuiInputFiles [accept]="accept" />
  </tui-input-files>
}
<tui-files class="tui-space_top-1">
  @if (loadedFiles$ | async; as file) {
    <tui-file
      (removed)="removeFile()"
      [file]="file"
      [showDelete]="formControl.enabled"
    ></tui-file>
  }
  @if (rejectedFiles$ | async; as file) {
    <tui-file
      state="error"
      [file]="file"
      [showDelete]="formControl.enabled"
      (removed)="clearRejected()"
    ></tui-file>
  }
  @if (loadingFiles$ | async; as file) {
    <tui-file
      state="loading"
      [file]="file"
      [showDelete]="formControl.enabled"
    ></tui-file>
  }
</tui-files>
