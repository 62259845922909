import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { FormsService } from '@shared/services/forms.service';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { TuiGroupModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { MaskitoModule } from '@maskito/angular';
import { phoneMask } from '@shared/utils/masks';
import { CheckboxComponent } from '@shared/components/forms/checkbox/checkbox.component';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import {
  catchError,
  delay,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertService } from '@shared/services/alert.service';
import { fromFormControlValue } from '@shared/utils/observables';
import { AsyncPipe } from '@angular/common';
import { Payment, PaymentForm } from '../../../../../../types/payment';
import { CargoCalculationService } from '../../../../services/cargo-calculation.service';
import {
  PAYMENT_METHODS,
  PAYMENT_TYPES,
} from '../../../../../../types/payment-type';

@Component({
  selector: 'app-cargo-payment',
  standalone: true,
  imports: [
    TuiInputModule,
    TuiTextfieldControllerModule,
    TuiInputNumberModule,
    TuiGroupModule,
    MaskitoModule,
    CheckboxComponent,
    AutocompleteComponent,
    ReactiveFormsModule,
    TuiActiveZoneModule,
    AsyncPipe,
  ],
  templateUrl: './cargo-payment.component.html',
  styleUrl: './cargo-payment.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoPaymentComponent implements OnInit {
  update$ = new Subject<void>();
  form = this.fb.group<PaymentForm>({
    calculatedRateJson: this.forms.getCalculatedJsonForm(),
    id: this.fb.control(null),
    cargoId: this.fb.control(null),
    clientJson: this.forms.getClientJsonForm(),
    dealDetailsJson: this.forms.getDealDetailsJsonForm(),
  });

  currencyLetter$ = fromFormControlValue(
    this.form.controls.calculatedRateJson.controls.rateClassifier,
  ).pipe(map(rate => rate?.currencyLetter ?? ''));

  protected readonly phoneMask = phoneMask;
  protected readonly PAYMENT_TYPES = PAYMENT_TYPES;
  protected readonly PAYMENT_METHODS = PAYMENT_METHODS;
  constructor(
    private readonly fb: FormBuilder,
    private readonly forms: FormsService,
    private readonly alert: AlertService,
    private readonly cargoCalculationService: CargoCalculationService,
    private readonly destroyRef: DestroyRef,
  ) {}

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
  }

  onUpdate() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.form.pristine),
        switchMap(() =>
          this.cargoCalculationService.savePayment(this.form.getRawValue()),
        ),
        tap(() => {
          this.alert.showSuccess();
          this.form.markAsUntouched();
          this.form.markAsPristine();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  @Input() set payment(value: Payment) {
    this.form.setValue(value);
  }

  disableFields() {
    this.form.controls.clientJson.disable();
    this.form.controls.calculatedRateJson.controls.calculatedSum.disable();
  }

  ngOnInit() {
    this.onUpdate();
    this.disableFields();
  }
}
