import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { Environment } from '../../types/environment';
import { Payment } from '../../types/payment';

@Injectable({
  providedIn: 'root',
})
export class PaymentRepositoryService {
  private readonly API_PAYMENT = `${this.env.apiUrl}/payment`;
  constructor(
    @Inject(APP_ENVIRONMENT)
    private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  save(payment: Payment) {
    return this.http.post<Payment>(`${this.API_PAYMENT}/save`, payment);
  }
}
