import { Injectable } from '@angular/core';
import { Subject, switchMap } from 'rxjs';
import { Store } from '@ngxs/store';
import { CargoRepositoryService } from '@shared/services/cargo-repository.service';
import { PaymentRepositoryService } from '@shared/services/payment-repository.service';
import {
  AddOrderGroup,
  DeleteOrderGroup,
  SetSelectedCargo,
  UpdateOrderGroup,
  UpdatePayment,
} from '../store/cargo-calculation.actions';
import { Cargo, CargoOrder, CargoOrderGroup } from '../../../types/cargo';
import { Payment } from '../../../types/payment';

@Injectable()
export class CargoCalculationService {
  saveRecalculatedValues$ = new Subject<void>();
  constructor(
    private readonly store: Store,
    private readonly cargoRepository: CargoRepositoryService,
    private readonly paymentRepository: PaymentRepositoryService,
  ) {}

  getCargoById(id: number) {
    return this.cargoRepository.getCargoById(id);
  }

  setSelectedCargo(id: number) {
    return this.getCargoById(id).pipe(
      switchMap(payload => this.store.dispatch(new SetSelectedCargo(payload))),
    );
  }

  updateCargo(cargo: Cargo) {
    return this.cargoRepository
      .updateCargo(cargo)
      .pipe(
        switchMap(updatedCargo =>
          this.store.dispatch(new SetSelectedCargo(updatedCargo)),
        ),
      );
  }

  createOrderGroup(cargoId: number) {
    return this.cargoRepository
      .createOrderGroup(cargoId)
      .pipe(switchMap(group => this.store.dispatch(new AddOrderGroup(group))));
  }

  updateOrderGroup(orderGroup: CargoOrderGroup) {
    return this.cargoRepository
      .updateOrderGroup(orderGroup)
      .pipe(
        switchMap(orderGroupUpdated =>
          this.store.dispatch(new UpdateOrderGroup(orderGroupUpdated)),
        ),
      );
  }

  deleteOrderGroup(id: number) {
    return this.cargoRepository
      .deleteOrderGroup(id)
      .pipe(switchMap(() => this.store.dispatch(new DeleteOrderGroup(id))));
  }

  createOrder(orderGroupId: number) {
    return this.cargoRepository.createOrder(orderGroupId);
  }

  updateOrder(order: CargoOrder) {
    return this.cargoRepository.updateOrder(order);
  }

  updateOrders(orders: CargoOrder[]) {
    return this.cargoRepository.updateOrders(orders);
  }

  deleteOrder(id: number) {
    return this.cargoRepository.deleteOrder(id);
  }

  getOrdersByCargoId(cargoId: number) {
    return this.cargoRepository.getOrdersByCargoId(cargoId);
  }

  moveFromCalculationToOnTheWay(id: number) {
    return this.cargoRepository.moveFromCalculationToOnTheWay(id);
  }

  savePayment(payment: Payment) {
    return this.paymentRepository
      .save(payment)
      .pipe(
        switchMap(payment => this.store.dispatch(new UpdatePayment(payment))),
      );
  }
}
