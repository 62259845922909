import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs';
import { PreOrderRepositoryService } from '@shared/services/pre-order-repository.service';
import { CargoRepositoryService } from '@shared/services/cargo-repository.service';
import { PreOrder } from '../../../types/pre-order';
import {
  AddPreOrder,
  DeletePreOrder,
  SetPreOrders,
  UpdatePreOrder,
} from '../store/pre-orders.actions';
import { CreateEmptyCargoPayload } from '../../../types/cargo';

@Injectable()
export class PreOrderService {
  constructor(
    private readonly preOrderRepository: PreOrderRepositoryService,
    private readonly cargoRepository: CargoRepositoryService,
    private readonly store: Store,
  ) {}

  getAll(sorting: string[]) {
    return this.preOrderRepository
      .getAll(sorting)
      .pipe(
        switchMap(preOrders =>
          this.store.dispatch(new SetPreOrders(preOrders)),
        ),
      );
  }

  update(preOrder: PreOrder) {
    return this.preOrderRepository
      .update(preOrder)
      .pipe(
        switchMap(updatedPreOrder =>
          this.store.dispatch(new UpdatePreOrder(updatedPreOrder)),
        ),
      );
  }

  create() {
    return this.preOrderRepository
      .create()
      .pipe(
        switchMap(preOrder => this.store.dispatch(new AddPreOrder(preOrder))),
      );
  }

  deleteById(id: number) {
    return this.preOrderRepository
      .deleteById(id)
      .pipe(switchMap(() => this.store.dispatch(new DeletePreOrder(id))));
  }

  createCargo(payload: {
    preOrderIds: CreateEmptyCargoPayload['preOrderIds'];
    trailerType: CreateEmptyCargoPayload['cargo']['trailerType'];
    transportType: CreateEmptyCargoPayload['cargo']['transportType'];
  }) {
    return this.cargoRepository.createEmptyCargo(payload);
  }
}
