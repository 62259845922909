import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  catchError,
  delay,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { generateUUID } from '@shared/utils/common';
import {
  TuiInputModule,
  TuiInputNumberModule,
  TuiIslandModule,
} from '@taiga-ui/kit';
import {
  TuiButtonModule,
  TuiDialogService,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { AlertService } from '@shared/services/alert.service';
import { CURRENCIES } from '@shared/constants/currency';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import {
  AttachmentsManagerComponent,
  AttachmentsManagerInputData,
} from '@shared/components/attachments-manager/attachments-manager.component';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { CargoLoadingState } from '../../store/cargo-loading.state';
import { TransportItemComponent } from './components/transport-item/transport-item.component';
import {
  Cargo,
  CarrierJson,
  CarrierJsonTransportFormWithGuid,
} from '../../../../types/cargo';
import { CargoLoadingService } from '../../services/cargo-loading.service';
import { PAYMENT_TYPES } from '../../../../types/payment-type';

@Component({
  selector: 'app-carrier',
  standalone: true,
  imports: [
    TuiIslandModule,
    ReactiveFormsModule,
    TuiInputModule,
    TransportItemComponent,
    TuiButtonModule,
    TuiTextfieldControllerModule,
    TuiActiveZoneModule,
    TuiInputNumberModule,
    AutocompleteComponent,
  ],
  templateUrl: './carrier.component.html',
  styleUrl: './carrier.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CarrierComponent implements OnInit {
  carrierForm = this.fb.group({
    name: '',
    cost: this.fb.control<Nullable<number>>(null),
    currencyLetter: '',
    paymentType: '',
    transports: this.fb.array<FormGroup<CarrierJsonTransportFormWithGuid>>([]),
  });

  openAttachmentsDialog$ = new Subject<{
    attachmentId: Nullable<number>;
    index: number;
  }>();

  zoneChanged$ = new Subject<boolean>();
  cargo$ = this.store.select(CargoLoadingState.getSelectedCargo);

  protected readonly CURRENCIES = CURRENCIES;
  protected readonly PAYMENT_TYPES = PAYMENT_TYPES;
  constructor(
    private readonly store: Store,
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly alert: AlertService,
    private readonly cargoLoadingService: CargoLoadingService,
    private readonly dialogs: TuiDialogService,
  ) {}

  onUpdate() {
    this.zoneChanged$
      .pipe(
        delay(0),
        filter(focused => !focused && !this.carrierForm.pristine),
        withLatestFrom(this.cargo$),
        switchMap(([, cargo]) => this.saveChanges(cargo)),
        tap(() => {
          this.alert.showSuccess();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onAttachments() {
    this.openAttachmentsDialog$
      .pipe(
        withLatestFrom(this.cargo$),
        switchMap(([{ attachmentId, index }, cargo]) => {
          const data: AttachmentsManagerInputData = {
            cargoId: cargo?.id ?? 0,
            attachmentId,
          };
          return this.dialogs
            .open<Nullable<number>>(
              new PolymorpheusComponent(AttachmentsManagerComponent),
              {
                size: 'auto',
                data,
              },
            )
            .pipe(
              map(updatedAttachmentId => {
                return {
                  updatedAttachmentId,
                  attachmentId,
                  index,
                };
              }),
            );
        }),
        withLatestFrom(this.cargo$),
        switchMap(([{ updatedAttachmentId, index }, cargo]) => {
          const attachmentIdControl =
            this.carrierForm.controls.transports.at(index).controls
              .attachmentId;
          attachmentIdControl.setValue(updatedAttachmentId);
          attachmentIdControl.markAsTouched();
          attachmentIdControl.markAsDirty();
          return this.saveChanges(cargo);
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  saveChanges(cargo: Nullable<Cargo>) {
    const value = this.carrierForm.getRawValue();
    const carrierJson: CarrierJson = {
      ...value,
      transports: value.transports.map(({ guid, ...transport }) => transport),
    };
    const payload: Cargo = {
      ...cargo,
      carrierJson,
    } as Cargo;
    return this.cargoLoadingService.updateCargo(payload).pipe(
      tap(() => {
        this.carrierForm.markAsPristine();
        this.carrierForm.markAsUntouched();
      }),
    );
  }

  addTransport() {
    const transport: FormGroup<CarrierJsonTransportFormWithGuid> =
      this.fb.group({
        guid: generateUUID(),
        transportNumber: this.fb.control(''),
        trailerNumber: this.fb.control(''),
        driverSurname: this.fb.control(''),
        driverName: this.fb.control(''),
        driverPhone: this.fb.control(''),
        comment: this.fb.control(''),
        loadingDate: this.fb.control(''),
        transportWeight: this.fb.control<Nullable<number>>(null),
        trailerWeight: this.fb.control<Nullable<number>>(null),
        attachmentId: this.fb.control<Nullable<number>>(null),
      });
    this.carrierForm.controls.transports.push(transport);
  }

  removeTransport(index: number) {
    this.carrierForm.controls.transports.removeAt(index);
  }

  fillFormOnStateChange() {
    this.cargo$
      .pipe(
        tap(cargo => {
          const carrier = cargo?.carrierJson;
          if (carrier) {
            const { transports, ...carrierDetails } = carrier;
            this.carrierForm.controls.transports.clear();
            this.carrierForm.patchValue(
              { ...carrierDetails },
              { emitEvent: false },
            );
            transports.forEach(transport => {
              this.carrierForm.controls.transports.push(
                this.fb.group({ ...transport, guid: generateUUID() }),
                { emitEvent: false },
              );
            });
          } else {
            this.carrierForm.reset();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.onAttachments();
    this.fillFormOnStateChange();
    this.onUpdate();
  }
}
