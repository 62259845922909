<div class="w-[1200px]">
  <h2 class="font-semibold mb-4 text-lg">Загрузить файл</h2>
  <div class="flex gap-x-2 mb-4">
    <tui-input
      class="w-2/12"
      [formControl]="preOrder.controls.invoiceNumber"
      tuiTextfieldSize="m"
      >Номер документа</tui-input
    >
    <div class="w-2/12">
      <app-input-date
        [formControl]="preOrder.controls.invoiceDate"
        [labelOutside]="false"
        label="Дата загрузки"
      ></app-input-date>
    </div>
    <tui-input-number
      class="w-2/12"
      [formControl]="preOrder.controls.invoiceCost"
      tuiTextfieldSize="m"
      decimal="always"
      [precision]="2"
      >Сумма</tui-input-number
    >
    <app-autocomplete
      class="w-2/12"
      label="Валюта"
      [labelOutside]="false"
      [formControl]="preOrder.controls.invoiceCurrencyLetter"
      [itemValue]="'letterCode'"
      [itemText]="'name'"
      [items]="CURRENCIES"
    ></app-autocomplete>
  </div>
  <div>
    @for (file of attachments$ | async; track $index) {
      <div class="flex gap-x-2 bg-neutral-100 mb-2 rounded-xl p-2 items-center">
        <div class="font-bold">{{ $index + 1 }}</div>
        <div>{{ file.name }}</div>
        <div>{{ roundDecimal(file.length / (1024 * 1024)) }}Мб</div>
      </div>
    }
  </div>
  <div class="my-2">
    <app-files-input
      [accept]="[mimeTypes.APPLICATION_PDF, 'image/*'].join()"
      [formControl]="files"
    ></app-files-input>
  </div>

  <div class="flex gap-x-2">
    <button tuiButton size="m" (click)="saveChanges$.next()">Сохранить</button>
    <button
      [disabled]="!(attachments$ | async)?.length"
      appearance="outline"
      tuiButton
      size="m"
      icon="tuiIconDownload"
      (click)="download$.next()"
    >
      Скачать архив
    </button>
    <button
      class="ml-auto mr-0"
      [disabled]="!(attachments$ | async)?.length"
      appearance="secondary-destructive"
      tuiButton
      icon="tuiIconTrash"
      size="m"
      (click)="delete$.next()"
    >
      Удалить архив
    </button>
  </div>
</div>
