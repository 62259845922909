<tui-island
  class="bg-neutral-100"
  *tuiLet="orderGroupsByClient$ | async as groups"
>
  <table
    tuiTable
    class="plain-table w-full overflow-x-auto px-2 cursor-pointer"
    [columns]="columns"
  >
    <thead>
      <tr tuiThGroup>
        @for (header of tableHeaders; track 'value') {
          <th tuiTh>
            {{ header.name }}
          </th>
        }
      </tr>
    </thead>
    <tbody tuiTbody>
      <tr *ngFor="let group of groups" tuiTr>
        @for (header of tableHeaders; track 'value') {
          <td *tuiCell="header.value" tuiTd>
            {{ group[header.value] }}
          </td>
          <td *tuiCell="'discount'" tuiTd>
            {{ group.discount ? group.discount + '%' : '' }}
          </td>
        }
      </tr>
    </tbody>
  </table>
</tui-island>
