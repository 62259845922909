import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';

import { produce } from 'immer';
import {
  CargoLoadingReplaceLoadingAddressesJson,
  CargoLoadingReplaceUnloadingAddressesJson,
  CargoLoadingUpdateOrder,
  SetSelectedCargoLoading,
  SetSelectedCargoLoadingOrders,
  SetSelectedCargoLoadingPreOrders,
} from './cargo-loading.actions';
import { CargoLoadingStateModel } from '../types';

@State<CargoLoadingStateModel>({
  name: 'cargoLoading',
  defaults: {
    selectedCargo: null,
    orders: [],
    preOrders: [],
  },
})
@Injectable()
export class CargoLoadingState {
  @Action(SetSelectedCargoLoading)
  setSelectedCargo(
    ctx: StateContext<CargoLoadingStateModel>,
    action: SetSelectedCargoLoading,
  ) {
    ctx.patchState({
      selectedCargo: action.cargo,
    });
  }

  @Selector()
  static getSelectedCargo(state: CargoLoadingStateModel) {
    return state.selectedCargo;
  }

  @Selector()
  static getOrders(state: CargoLoadingStateModel) {
    return state.orders;
  }

  @Selector()
  static getPreOrders(state: CargoLoadingStateModel) {
    return state.preOrders;
  }

  @Selector()
  static getPreOrdersMap(state: CargoLoadingStateModel) {
    return new Map(state.preOrders.map(preOrder => [preOrder.id, preOrder]));
  }

  @Action(SetSelectedCargoLoadingOrders)
  setOrders(
    ctx: StateContext<CargoLoadingStateModel>,
    action: SetSelectedCargoLoadingOrders,
  ) {
    ctx.patchState({
      orders: action.orders,
    });
  }

  @Action(SetSelectedCargoLoadingPreOrders)
  setPreOrders(
    ctx: StateContext<CargoLoadingStateModel>,
    action: SetSelectedCargoLoadingPreOrders,
  ) {
    ctx.patchState({
      preOrders: action.preOrders,
    });
  }

  @Action(CargoLoadingReplaceLoadingAddressesJson)
  replaceLoadingAddressesJson(
    ctx: StateContext<CargoLoadingStateModel>,
    action: CargoLoadingReplaceLoadingAddressesJson,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedCargo?.loadingAddressesJson.splice(0);
        draft.selectedCargo?.loadingAddressesJson?.push(
          ...action.loadingAddressesJson,
        );
      }),
    );
  }

  @Action(CargoLoadingReplaceUnloadingAddressesJson)
  replaceUnloadingAddressesJson(
    ctx: StateContext<CargoLoadingStateModel>,
    action: CargoLoadingReplaceUnloadingAddressesJson,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedCargo?.unloadingAddressesJson.splice(0);
        draft.selectedCargo?.unloadingAddressesJson?.push(
          ...action.unloadingAddressesJson,
        );
      }),
    );
  }

  @Action(CargoLoadingUpdateOrder)
  updatePreOrder(
    ctx: StateContext<CargoLoadingStateModel>,
    action: CargoLoadingUpdateOrder,
  ) {
    ctx.setState(
      produce(draft => {
        const preOrders = draft.orders;
        const preOrderIndex = preOrders.findIndex(
          i => i.id === action.order.id,
        );
        draft.orders.splice(preOrderIndex, 1, action.order);
      }),
    );
  }
}
