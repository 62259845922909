<div class="flex gap-x-2 justify-between">
  <div>
    <button
      (click)="generateDriversReport$.next()"
      size="m"
      tuiIconButton
      icon="tuiIconDownload"
    ></button>
  </div>
  <div class="flex w-9/12 gap-x-2">
    <tui-input
      class="w-4/12 contrast-disabled-field"
      tuiTextfieldSize="m"
      [formControl]="computedProperties.controls.totalWarmPlaces"
      >Тепло</tui-input
    >
    <tui-input
      class="w-4/12 contrast-disabled-field"
      tuiTextfieldSize="m"
      [formControl]="computedProperties.controls.totalColdPlaces"
      >Холод</tui-input
    >
    <tui-input-number
      class="w-1/12 contrast-disabled-field"
      tuiTextfieldSize="m"
      [formControl]="computedProperties.controls.totalBox"
      >Коробки</tui-input-number
    >
    <tui-input-number
      tuiTextfieldSize="m"
      class="w-1/12 contrast-disabled-field"
      [formControl]="vehicleLoadingPercent"
      >Загрузка %</tui-input-number
    >
  </div>
  <div class="mx-2 w-2/12">
    <tui-input-number
      tuiTextfieldSize="m"
      class="contrast-disabled-field"
      [precision]="2"
      [formControl]="grossWeight"
      >Вес брутто по весам</tui-input-number
    >
  </div>
</div>
