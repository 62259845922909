import { Routes } from '@angular/router';
import { isAuthenticatedGuard } from '@shared/guards/is-authenticated.guard';
import { OrganizationRouteNames, RouteNames } from './route-names';
import { DocumentLayoutComponent } from './core/layouts/document-layout/document-layout.component';
import { MainLayoutComponent } from './core/layouts/main-layout/main-layout.component';
import { MainPageComponent } from './core/pages/main-page/main-page.component';
import { UserLoginComponent } from './modules/user/pages/user-login/user-login.component';
import { UserRegisterComponent } from './modules/user/pages/user-register/user-register.component';
import { UserResetPasswordComponent } from './modules/user/pages/user-reset-password/user-reset-password.component';
import { ForbiddenPageComponent } from './core/pages/forbidden-page/forbidden-page.component';
import { PRE_ORDER_ROUTE } from './modules/pre-order/routes';
import { CARGO_LOADING_ROUTE } from './modules/cargo-loading/routes';
import { CARGO_CALCULATION_ROUTE } from './modules/cargo-calculation/routes';

export const routes: Routes = [
  {
    path: '',
    component: MainLayoutComponent,
    children: [
      {
        path: RouteNames.MAIN_PAGE,
        component: MainPageComponent,
        title: 'Главная страница',
      },
      {
        path: RouteNames.LOGIN_PAGE,
        component: UserLoginComponent,
        title: 'Войти',
      },
      {
        path: RouteNames.REGISTER_PAGE,
        component: UserRegisterComponent,
        title: 'Регистрация',
        // canActivate: [uniqueKeyGuard],
      },
      {
        path: RouteNames.RESET_PASSWORD_PAGE,
        component: UserResetPasswordComponent,
        title: 'Восстановление пароля',
      },
      {
        path: RouteNames.KANBAN_PAGE,
        title: 'Канбан',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/kanban/routes').then(mod => mod.KANBAN_ROUTES),
      },
      {
        path: RouteNames.USER,
        title: 'Учетная запись',
        loadComponent: () =>
          import('./modules/user/pages/user-edit/user-edit.component').then(
            c => c.UserEditComponent,
          ),
        canActivate: [isAuthenticatedGuard],
      },
      {
        path: OrganizationRouteNames.MAIN,
        title: 'Организация',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/organization/routes').then(
            mod => mod.ORGANIZATION_ROUTES,
          ),
      },
      {
        path: RouteNames.CATALOGS_PAGE,
        title: 'Каталоги',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/catalogs/routes').then(mod => mod.CATALOGS_ROUTES),
      },
      {
        path: RouteNames.TOOLS,
        title: 'Инструменты',
        canActivate: [isAuthenticatedGuard],
        loadChildren: () =>
          import('./modules/tools/routes').then(mod => mod.TOOLS_ROUTES),
      },
      {
        path: RouteNames.FORBIDDEN_PAGE,
        title: 'Доступ запрещен',
        component: ForbiddenPageComponent,
      },
    ],
  },
  {
    path: '',
    component: DocumentLayoutComponent,
    children: [CARGO_CALCULATION_ROUTE, PRE_ORDER_ROUTE, CARGO_LOADING_ROUTE],
  },
  {
    path: '**',
    redirectTo: RouteNames.KANBAN_PAGE,
  },
];
