<div class="py-2" (tuiActiveZoneChange)="zoneChanged$.next($event)">
  <h2 class="font-semibold mb-4 text-[16px]">Промежуточные пункты</h2>
  <div class="drag-n-drop-area" cdkDropList (cdkDropListDropped)="drop($event)">
    @for (form of transitPointsForm.controls; track form.controls.guid.value) {
      <app-transit-point-form
        (remove)="removeTransitPoint($index)"
        [transitPointForm]="form"
      ></app-transit-point-form>
    }
  </div>
  <button (click)="addTransitPoint()" size="s" tuiButton icon="tuiIconPlus">
    Добавить пункт
  </button>
</div>
