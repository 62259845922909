import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { TuiAccordionModule } from '@taiga-ui/kit';
import { SpaceNumberPipe } from '@shared/pipes/space-number.pipe';
import { CargoCalculationState } from '../../store/cargo-calculation.state';
import { OrderGroupComponent } from '../order-group/order-group.component';
import { CargoPaymentComponent } from './components/cargo-payment/cargo-payment.component';

@Component({
  selector: 'app-cargo-payments',
  standalone: true,
  imports: [
    AsyncPipe,
    OrderGroupComponent,
    TuiAccordionModule,
    CargoPaymentComponent,
    DecimalPipe,
    SpaceNumberPipe,
  ],
  templateUrl: './cargo-payments.component.html',
  styleUrl: './cargo-payments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoPaymentsComponent {
  payments$ = this.store
    .select(CargoCalculationState.getSelectedCargo)
    .pipe(map(cargo => cargo?.payments ?? []));

  constructor(private readonly store: Store) {}
}
