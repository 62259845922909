import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  catchError,
  delay,
  filter,
  of,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { generateUUID } from '@shared/utils/common';
import { TuiButtonModule } from '@taiga-ui/core';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { CheckboxComponent } from '@shared/components/forms/checkbox/checkbox.component';
import { AlertService } from '@shared/services/alert.service';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { CargoLoadingService } from '../../services/cargo-loading.service';
import {
  Cargo,
  CargoTransitPoint,
  CargoTransitPointForm,
  CargoUnloadingAddress,
} from '../../../../types/cargo';
import { CargoLoadingState } from '../../store/cargo-loading.state';
import { TransitPointFormComponent } from './components/transit-point-form/transit-point-form.component';

@Component({
  selector: 'app-transit-points',
  standalone: true,
  imports: [
    TuiButtonModule,
    AutocompleteComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    TransitPointFormComponent,
    TuiActiveZoneModule,
    CdkDropList,
    CdkDrag,
  ],
  templateUrl: './transit-points.component.html',
  styleUrl: './transit-points.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitPointsComponent implements OnInit {
  transitPointsForm = this.fb.array<FormGroup<CargoTransitPointForm>>([]);
  zoneChanged$ = new Subject<boolean>();
  cargo$ = this.store.select(CargoLoadingState.getSelectedCargo);
  updateSort$ = new Subject<
    Pick<CdkDragDrop<CargoTransitPoint>, 'currentIndex' | 'previousIndex'>
  >();

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly store: Store,
    private readonly cargoLoadingService: CargoLoadingService,
    private readonly alert: AlertService,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  onUpdate() {
    this.zoneChanged$
      .pipe(
        delay(0),
        filter(focused => !focused && !this.transitPointsForm.pristine),
        withLatestFrom(this.cargo$),
        switchMap(([, cargo]) => {
          const transitPointsJson = this.transitPointsForm
            .getRawValue()
            .map(point => {
              const { guid, transitPoint, displayOnRoute } = point;
              return { transitPoint, displayOnRoute };
            });
          return this.cargoLoadingService.updateCargo({
            ...(cargo as Cargo),
            transitPointsJson,
          });
        }),
        tap(() => {
          this.alert.showSuccess();
          this.transitPointsForm.markAsUntouched();
          this.transitPointsForm.markAsPristine();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onUpdateSort() {
    this.updateSort$
      .pipe(
        filter(params => params.currentIndex !== params.previousIndex),
        tap(({ previousIndex, currentIndex }) => {
          const points = structuredClone(
            this.transitPointsForm.getRawValue() as Array<
              CargoTransitPoint & { guid: string }
            >,
          );

          moveItemInArray(points, previousIndex, currentIndex);

          this.transitPointsForm.clear({ emitEvent: false });

          const pointForms = points.map(point =>
            this.fb.group({
              ...point,
              guid: this.fb.nonNullable.control(point.guid),
              transitPoint: this.fb.group(point.transitPoint),
            }),
          );

          pointForms.forEach((form, index, items) => {
            this.transitPointsForm.push(form, {
              emitEvent: index === items.length - 1,
            });
          });
          this.transitPointsForm.markAsDirty();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  addTransitPoint() {
    const form = this.fb.group<CargoTransitPointForm>({
      guid: this.fb.nonNullable.control(generateUUID()),
      transitPoint: this.fb.group({
        id: this.fb.control<Nullable<number>>(null),
        type: '',
        name: '',
        address: '',
        latitude: '',
        longitude: '',
      }),
      displayOnRoute: this.fb.control<Nullable<boolean>>(null),
    });
    this.transitPointsForm.push(form);
    this.transitPointsForm.markAsDirty();
  }

  removeTransitPoint(index: number) {
    this.transitPointsForm.removeAt(index);
    this.transitPointsForm.markAsDirty();
  }

  fillFormOnStateChange() {
    this.store
      .select(CargoLoadingState.getSelectedCargo)
      .pipe(
        tap(cargo => {
          const points = cargo?.transitPointsJson ?? [];
          this.transitPointsForm.clear({ emitEvent: false });

          const pointForms = points.map(point =>
            this.fb.group({
              guid: this.fb.nonNullable.control(generateUUID()),
              ...point,
              transitPoint: this.fb.group(point.transitPoint),
            }),
          );

          pointForms.forEach((form, index, items) => {
            this.transitPointsForm.push(form, {
              emitEvent: index === items.length - 1,
            });
          });
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<CargoUnloadingAddress[]>) {
    this.updateSort$.next({ previousIndex, currentIndex });
  }

  ngOnInit() {
    this.onUpdateSort();
    this.fillFormOnStateChange();
    this.onUpdate();
  }
}
