<tui-input-files [formControl]="formControl" (reject)="onReject($event)">
  <input tuiInputFiles multiple [accept]="accept" />
</tui-input-files>

<tui-files class="tui-space_top-1">
  <tui-file
    *ngFor="let file of formControl.valueChanges | async"
    [file]="file"
    [showDelete]="formControl.enabled"
    (removed)="removeFile(file)"
  ></tui-file>

  <tui-file
    *ngFor="let file of rejectedFiles"
    state="error"
    [file]="file"
    [showDelete]="formControl.enabled"
    (removed)="clearRejected(file)"
  ></tui-file>
</tui-files>

<tui-error
  [error]="['maxLength'] | tuiFieldError | async"
  [formControl]="formControl"
></tui-error>
