// eslint-disable-next-line @typescript-eslint/no-empty-function @typescript-eslint/no-empty-function
import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { finalize, Observable, of, Subject, switchMap } from 'rxjs';
import { TuiFileLike, TuiInputFilesModule } from '@taiga-ui/kit';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-file-input',
  standalone: true,
  imports: [TuiInputFilesModule, AsyncPipe, ReactiveFormsModule],
  templateUrl: './file-input.component.html',
  styleUrl: './file-input.component.scss',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FileInputComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FileInputComponent implements ControlValueAccessor, OnInit {
  formControl: FormControl;

  readonly rejectedFiles$ = new Subject<TuiFileLike | null>();
  readonly loadingFiles$ = new Subject<TuiFileLike | null>();
  loadedFiles$: Observable<TuiFileLike | null>;

  @Input()
  accept = '*/*';

  constructor(private readonly injector: Injector) {}

  onReject(file: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFiles$.next(file as TuiFileLike);
  }

  onChange: any = () => {};
  onTouch: any = () => {};
  ngOnInit() {
    const ngControl = this.injector.get(NgControl);

    this.formControl = (ngControl as FormControlDirective).form;

    this.loadedFiles$ = this.formControl.valueChanges.pipe(
      switchMap(file => (file ? this.makeRequest(file) : of(null))),
    );
  }

  removeFile(): void {
    this.formControl.setValue(null);
  }

  clearRejected(): void {
    this.removeFile();
    this.rejectedFiles$.next(null);
  }

  makeRequest(file: TuiFileLike): Observable<TuiFileLike | null> {
    this.loadingFiles$.next(file);

    return of(file).pipe(finalize(() => this.loadingFiles$.next(null)));
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(): void {}
}
