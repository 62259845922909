<div class="py-2">
  <h2 class="font-semibold mb-4 text-[16px]">Заказы</h2>
  <tui-accordion [rounded]="false">
    @for (
      order of preparedOrderGroups$ | async;
      track order.original.id;
      let index = $index
    ) {
      <tui-accordion-item borders="top-bottom" [showArrow]="false">
        <div class="flex gap-x-2 py-1.5 pr-1.5">
          <div class="w-[40px] rounded-xl bg-purple-200 text-center">
            {{ index + 1 }}
          </div>
          <div class="w-[250px] overflow-hidden disappearing">
            {{ order.original.clientJson.name }}
          </div>

          <div class="w-[250px] overflow-hidden disappearing">
            {{ order.original.clientAddressWithCoordinatesJson.address }}
          </div>
          <div class="w-[250px] overflow-hidden disappearing">
            {{ order.original.farmJson.name }}
          </div>

          <div class="w-[150px] overflow-hidden disappearing">
            {{ order.computed.flowerTypeName }}
          </div>

          <div class="w-[150px] overflow-hidden disappearing">
            {{ order.original.packageQuantity ?? 0 }} BX
          </div>
          <div class="w-[150px] overflow-hidden disappearing">
            {{ order.original.cargoPlaceJson.name }}
            {{ order.original.cargoPlaceQuantity }}
          </div>
          <div class="w-80px flex gap-x-2">
            <button
              (click)="$event.stopPropagation(); delete$.next(index)"
              size="s"
              appearance="icon"
              tuiIconButton
              icon="tuiIconTrash"
            ></button>
            <tui-badged-content>
              @if (order.original.attachmentId) {
                <tui-badge-notification size="s" tuiSlot="top">
                  1
                </tui-badge-notification>
              }
              <button
                (click)="
                  $event.stopPropagation();
                  openAttachmentsDialog$.next({
                    order: order.original,
                    index
                  })
                "
                tuiIconButton
                size="s"
                icon="tuiIconPaperclip"
              ></button>
            </tui-badged-content>
          </div>
        </div>

        <ng-template tuiAccordionItemContent>
          <app-order [order]="order.original"></app-order>
        </ng-template>
      </tui-accordion-item>
    }
  </tui-accordion>

  <div class="my-4">
    <button size="s" icon="tuiIconPlus" tuiButton (click)="create$.next()">
      Добавить предзаказ
    </button>
  </div>
</div>
