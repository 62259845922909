<tui-island>
  <h2 class="font-bolder mb-4">Сортировка</h2>
  <div class="flex flex-wrap gap-x-2 mb-2">
    @for (field of notSelectedFields$ | async; track field.value) {
      <button
        (click)="commonStateService.selectSortingField(field)"
        appearance="outline"
        tuiButton
        size="s"
      >
        {{ field.name }}
      </button>
    } @empty {
      <span class="text-sm py-2">Нет доступных полей для сортировки</span>
    }
  </div>
  <div class="flex flex-wrap gap-x-2">
    @for (
      field of commonStateService.selectedSortingFields$ | async;
      track field.value
    ) {
      <button
        (click)="commonStateService.selectSortingField(field)"
        appearance="secondary"
        tuiButton
        size="s"
      >
        {{ field.name }} <tui-svg src="tuiIconClose"></tui-svg>
      </button>
    }
  </div>
</tui-island>
