<div class="flex gap-x-2">
  <div class="flex w-9/12 gap-x-2">
    <tui-input
      class="w-4/12 contrast-disabled-field"
      tuiTextfieldSize="m"
      [formControl]="computedProperties.controls.totalWarmPlaces"
      >Тепло</tui-input
    >
    <tui-input
      class="w-4/12 contrast-disabled-field"
      tuiTextfieldSize="m"
      [formControl]="computedProperties.controls.totalColdPlaces"
      >Холод</tui-input
    >
    <tui-input-number
      class="w-1/12 contrast-disabled-field"
      tuiTextfieldSize="m"
      [formControl]="computedProperties.controls.totalBox"
      >Коробки</tui-input-number
    >
    <tui-input-number
      tuiTextfieldSize="m"
      class="w-1/12 contrast-disabled-field"
      [formControl]="vehicleLoadingPercent"
      >Загрузка %</tui-input-number
    >
  </div>
  <tui-input
    class="w-2/12 contrast-disabled-field"
    tuiTextfieldSize="m"
    [formControl]="sum"
  >
    Ставка на машину
  </tui-input>
</div>
