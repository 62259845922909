import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { Environment } from '../../types/environment';
import { PreOrder } from '../../types/pre-order';

@Injectable({ providedIn: 'root' })
export class PreOrderRepositoryService {
  private readonly API_PRE_ORDER = `${this.env.apiUrl}/preOrder`;
  constructor(
    @Inject(APP_ENVIRONMENT)
    private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  getAll(sorting: string[]) {
    return this.http.post<PreOrder[]>(`${this.API_PRE_ORDER}/getAll`, sorting);
  }

  update(preOrder: PreOrder) {
    return this.http.post<PreOrder>(`${this.API_PRE_ORDER}/save`, preOrder);
  }

  create() {
    return this.http.post<PreOrder>(`${this.API_PRE_ORDER}/create`, null);
  }

  deleteById(id: number) {
    return this.http.post(`${this.API_PRE_ORDER}/deleteById?id=${id}`, null);
  }

  getByCargoId(cargoId: number) {
    return this.http.get<PreOrder[]>(
      `${this.API_PRE_ORDER}/getByCargoId?cargoId=${cargoId}`,
    );
  }
}
