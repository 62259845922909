import { ChangeDetectionStrategy, Component } from '@angular/core';
import { TuiIslandModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import { AsyncPipe } from '@angular/common';
import { BehaviorSubject, combineLatest, map } from 'rxjs';
import { SortingField } from '../../types';
import { CommonStateService } from '../../services/common-state.service';

@Component({
  selector: 'app-pre-orders-sorting',
  standalone: true,
  imports: [TuiIslandModule, TuiButtonModule, AsyncPipe, TuiSvgModule],
  templateUrl: './pre-orders-sorting.component.html',
  styleUrl: './pre-orders-sorting.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreOrdersSortingComponent {
  private readonly fields: SortingField[] = [
    {
      name: 'Клиент',
      value: "client_json->>'''name'''",
    },
    {
      name: 'Адрес клиента',
      value: 'client_address',
    },
    {
      name: 'Питомник',
      value: "farm_json->>'''name'''",
    },
    {
      name: 'Холод',
      value: 'cold',
    },
    {
      name: 'Вид места',
      value: "cargo_place_json->>'''name'''",
    },
    {
      name: 'Вид упаковки',
      value: "package_type_json->>'''name'''",
    },
    {
      name: 'Группа товаров',
      value: 'flower_type',
    },
  ];

  fields$ = new BehaviorSubject<SortingField[]>(this.fields);
  notSelectedFields$ = combineLatest([
    this.fields$,
    this.commonStateService.selectedSortingFields$,
  ]).pipe(
    map(([fields, selectedFields]) => {
      const ids = selectedFields.map(f => f.value);
      return fields.filter(field => !ids.includes(field.value));
    }),
  );

  constructor(protected readonly commonStateService: CommonStateService) {}
}
