<div #card="tuiActiveZone" (tuiActiveZoneChange)="onZoneChange($event)">
  <div class="flex gap-x-2 mb-2">
    <app-autocomplete
      class="w-2/12"
      [formControl]="preOrderForm.controls.clientJson.controls.id"
      [items]="catalogs.clients$ | async"
      [itemText]="'name'"
      [itemValue]="'id'"
      [displayValue]="'name'"
      [itemSearch]="'search'"
      label="Мой клиент"
      [labelOutside]="false"
      (valueChanged)="onClientChange()"
    ></app-autocomplete>
    <app-autocomplete
      class="w-3/12"
      label="Адрес"
      [labelOutside]="false"
      [formControl]="
        preOrderForm.controls.clientAddressWithCoordinatesJson.controls.address
      "
      [items]="availableAddresses$ | async"
      [itemText]="'address'"
      [itemValue]="'address'"
      (valueChanged)="onAddressChange()"
      tuiTextfieldSize="m"
      >Адрес</app-autocomplete
    >
    <app-autocomplete
      class="w-3/12"
      [formControl]="preOrderForm.controls.farmJson.controls.id"
      [items]="catalogs.farms$ | async"
      [itemText]="'name'"
      [itemValue]="'id'"
      [displayValue]="'name'"
      [itemSearch]="'search'"
      label="Питомник"
      [labelOutside]="false"
      (valueChanged)="onFarmChange()"
    ></app-autocomplete>
    <app-autocomplete
      class="w-2/12"
      [formControl]="preOrderForm.controls.flowerType"
      [items]="availableFlowerTypes$ | async"
      [itemSearch]="'search'"
      [itemText]="'name'"
      [itemValue]="'id'"
      [displayValue]="'name'"
      label="Группа товаров"
      [labelOutside]="false"
    ></app-autocomplete>

    <div class="w-1/12 flex items-center">
      <tui-toggle
        [formControl]="preOrderForm.controls.imported"
        size="m"
        class="mx-2"
      ></tui-toggle>
      <span class="text-sm">Импорт</span>
    </div>
    <div class="w-1/12 flex items-center">
      <app-checkbox [formControl]="preOrderForm.controls.cold"></app-checkbox>
      <span class="px-2">Холод</span>
    </div>
  </div>
  <div class="flex gap-x-2">
    <app-autocomplete
      label="Код покупателя"
      class="w-2/12"
      [labelOutside]="false"
      [formControl]="preOrderForm.controls.buyerCode"
      [items]="buyersOfSelectedFarm$ | async"
      [itemSearch]="'search'"
      [itemValue]="'code'"
      [displayValue]="'code'"
      [itemText]="'code'"
    ></app-autocomplete>

    @if ((isImport$ | async) === true) {
      <div class="flex gap-x-2 w-3/12">
        <app-autocomplete
          class="w-3/12"
          [items]="countries()"
          [itemText]="'name'"
          [itemValue]="'letterCode'"
          [itemSearch]="'search'"
          [formControl]="preOrderForm.controls.countryLetter"
        ></app-autocomplete>
        <tui-input
          class="w-4/12"
          [formControl]="preOrderForm.controls.airWaybillNumber"
          tuiTextfieldSize="m"
          >AWB</tui-input
        >
        <div class="w-1/12 flex items-center">
          <app-checkbox
            [formControl]="preOrderForm.controls.precooling"
            [tuiHint]="precooling"
          ></app-checkbox>
          <ng-template #precooling> Pre-cooling </ng-template>
        </div>

        <tui-input-number
          class="w-4/12"
          tuiTextfieldSize="m"
          [formControl]="preOrderForm.controls.weight"
          >Вес</tui-input-number
        >
      </div>
    } @else {
      <div class="w-3/12" tuiGroup [collapsed]="true">
        <app-autocomplete
          label="Грузовое место"
          [labelOutside]="false"
          class="tui-group__inherit-item w-8/12 tui-group__auto-width-item"
          componentClass="tui-group__inherit-item"
          [formControl]="preOrderForm.controls.cargoPlaceJson.controls.code"
          [items]="catalogs.cargoPlaces$ | async"
          [itemValue]="'code'"
          [itemText]="'name'"
          [displayValue]="'name'"
          [itemSearch]="'search'"
        ></app-autocomplete>
        <tui-input-number
          [formControl]="cargoPlaceQuantityLimit"
          class="w-4/12 tui-group__auto-width-item"
          tuiTextfieldSize="m"
        ></tui-input-number>
      </div>
    }

    <div class="flex items-center">
      <app-checkbox
        [formControl]="preOrderForm.controls.box"
        [tuiHint]="box"
      ></app-checkbox>
      <ng-template #box> Коробки</ng-template>
    </div>

    <div class="flex w-5/12 gap-x-2">
      @if (hasBox$ | async) {
        <div class="w-9/12" tuiGroup [collapsed]="true">
          <app-autocomplete
            label="Код"
            class="tui-group__inherit-item w-9/12 tui-group__auto-width-item"
            componentClass="tui-group__inherit-item"
            [labelOutside]="false"
            [formControl]="preOrderForm.controls.packageTypeJson.controls.code"
            [items]="preparedPackageTypes$ | async"
            [itemValue]="'code'"
            [itemText]="'itemText'"
            [displayValue]="'itemText'"
            [itemSearch]="'search'"
          ></app-autocomplete>
          <tui-input-number
            [formControl]="preOrderForm.controls.packageQuantityLimit"
            class="w-3/12 tui-group__auto-width-item"
            tuiTextfieldSize="m"
            >Лимит</tui-input-number
          >
        </div>
        <tui-input-number
          [precision]="0"
          class="w-2/12"
          tuiTextfieldSize="m"
          [formControl]="preOrderForm.controls.packageQuantity"
          >Кол-во упаковки</tui-input-number
        >
      }
    </div>

    <tui-input-number
      tuiTextfieldSize="m"
      [formControl]="preOrderForm.controls.cargoPlaceQuantity"
      >Кол-во</tui-input-number
    >
  </div>
</div>
