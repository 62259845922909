import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { CheckboxComponent } from '@shared/components/forms/checkbox/checkbox.component';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { TuiButtonModule, TuiHintModule } from '@taiga-ui/core';
import { TransitPointService } from '@shared/services/transit-point.service';
import { AsyncPipe } from '@angular/common';
import { Subject, tap, withLatestFrom } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { CdkDrag } from '@angular/cdk/drag-drop';
import { CargoTransitPointForm } from '../../../../../../types/cargo';

@Component({
  selector: 'app-transit-point-form',
  standalone: true,
  imports: [
    AutocompleteComponent,
    CheckboxComponent,
    ReactiveFormsModule,
    TuiHintModule,
    TuiButtonModule,
    AsyncPipe,
    TuiActiveZoneModule,
    CdkDrag,
  ],
  templateUrl: './transit-point-form.component.html',
  styleUrl: './transit-point-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransitPointFormComponent implements OnInit {
  @Input({ required: true })
  transitPointForm!: FormGroup<CargoTransitPointForm>;

  @Output() remove = new EventEmitter();

  transitPoints$ = this.transitPointService.getTransitPoints();
  transitPointsMap$ = this.transitPointService.getTransitPointsMap();
  fillTransitPointDetails$ = new Subject<Nullable<string>>();

  constructor(
    private readonly transitPointService: TransitPointService,
    private readonly destroyRef: DestroyRef,
  ) {}

  onFillTransitPointDetails() {
    this.fillTransitPointDetails$
      .pipe(
        withLatestFrom(this.transitPointsMap$),
        tap(([id, transitPointsMap]) => {
          const group = this.transitPointForm.controls.transitPoint;
          if (!id) {
            group.reset();
          } else {
            const point = transitPointsMap.get(Number(id));
            if (point) {
              group.setValue(point);
            } else {
              group.reset();
            }
          }
          group.markAsTouched();
          group.markAsDirty();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.onFillTransitPointDetails();
  }
}
