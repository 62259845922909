import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { produce } from 'immer';
import { CargoCalculationStateModel } from '../types';
import {
  AddOrderGroup,
  DeleteOrderGroup,
  SetSelectedCargo,
  UpdateOrderGroup,
  UpdatePayment,
} from './cargo-calculation.actions';

@State<CargoCalculationStateModel>({
  name: 'cargoCalculation',
  defaults: {
    selectedCargo: null,
  },
})
@Injectable()
export class CargoCalculationState {
  @Action(SetSelectedCargo)
  setSelectedCargo(
    ctx: StateContext<CargoCalculationStateModel>,
    action: SetSelectedCargo,
  ) {
    ctx.patchState({
      selectedCargo: action.cargo,
    });
  }

  @Action(AddOrderGroup)
  addOrderGroup(
    ctx: StateContext<CargoCalculationStateModel>,
    action: AddOrderGroup,
  ) {
    ctx.setState(
      produce(draft => {
        draft.selectedCargo?.orderGroups.push(action.orderGroup);
      }),
    );
  }

  @Action(UpdateOrderGroup)
  updateOrderGroup(
    ctx: StateContext<CargoCalculationStateModel>,
    action: UpdateOrderGroup,
  ) {
    ctx.setState(
      produce(draft => {
        const orderGroups = draft.selectedCargo?.orderGroups ?? [];
        const orderGroupsIndex = orderGroups.findIndex(
          i => i.id === action.orderGroup.id,
        );
        draft.selectedCargo?.orderGroups.splice(
          orderGroupsIndex,
          1,
          action.orderGroup,
        );
      }),
    );
  }

  @Action(UpdatePayment)
  updatePayment(
    ctx: StateContext<CargoCalculationStateModel>,
    action: UpdatePayment,
  ) {
    ctx.setState(
      produce(draft => {
        const payments = draft.selectedCargo?.payments ?? [];
        const paymentIndex = payments.findIndex(
          i => i.id === action.payment.id,
        );
        draft.selectedCargo?.payments.splice(paymentIndex, 1, action.payment);
      }),
    );
  }

  @Action(DeleteOrderGroup)
  deleteOrderGroup(
    ctx: StateContext<CargoCalculationStateModel>,
    action: DeleteOrderGroup,
  ) {
    ctx.setState(
      produce(draft => {
        const orderGroups = draft.selectedCargo?.orderGroups ?? [];
        const orderGroupsIndex = orderGroups.findIndex(i => i.id === action.id);
        draft.selectedCargo?.orderGroups.splice(orderGroupsIndex, 1);
      }),
    );
  }

  @Selector()
  static getSelectedCargo(state: CargoCalculationStateModel) {
    return state.selectedCargo;
  }

  @Selector()
  static getOrderGroups(state: CargoCalculationStateModel) {
    return state.selectedCargo?.orderGroups ?? [];
  }
}
