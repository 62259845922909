import {
  Cargo,
  CargoLoadingAddress,
  CargoOrder,
  CargoUnloadingAddress,
} from '../../../types/cargo';
import { PreOrder } from '../../../types/pre-order';

export class SetSelectedCargoLoading {
  static readonly type = '[CargoLoading] Set Selected CargoLoading';
  constructor(public cargo: Cargo) {}
}

export class SetSelectedCargoLoadingOrders {
  static readonly type = '[CargoLoading] Set Selected CargoLoading Orders';
  constructor(public orders: CargoOrder[]) {}
}

export class SetSelectedCargoLoadingPreOrders {
  static readonly type = '[CargoLoading] Set Selected CargoLoading PreOrders';
  constructor(public preOrders: PreOrder[]) {}
}

export class CargoLoadingReplaceLoadingAddressesJson {
  static readonly type = '[CargoLoading] Replace Loading Addresses Json';
  constructor(public loadingAddressesJson: CargoLoadingAddress[]) {}
}

export class CargoLoadingReplaceUnloadingAddressesJson {
  static readonly type = '[CargoLoading] Replace Unloading Addresses Json';
  constructor(public unloadingAddressesJson: CargoUnloadingAddress[]) {}
}

export class CargoLoadingUpdateOrder {
  static readonly type = '[CargoLoading] Update order';
  constructor(public order: CargoOrder) {}
}
