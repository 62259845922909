<div class="py-2" *tuiLet="boxAndPlaceQuantityPerFarm$ | async as quantity">
  <h2 class="font-semibold mb-4 text-[16px]">Адреса загрузки</h2>
  <div class="flex items-center gap-x-2 rounded-xl mb-2 bg-neutral-100 py-3">
    <div class="w-3/12 pl-2">Питомник</div>
    <div class="w-3/12">Адрес</div>
    <div class="w-2/12">Коробки</div>
    <div class="w-2/12">Места</div>
    <div class="w-2/12"></div>
  </div>
  <div class="drag-n-drop-area" cdkDropList (cdkDropListDropped)="drop($event)">
    @for (address of loadingAddresses$ | async; track $index) {
      <div cdkDrag class="flex gap-x-2 py-2 border-b items-center">
        <div class="font-semibold w-3/12 pl-2">
          {{ address.farmName }}
        </div>
        <div class="w-3/12">{{ address.address }}</div>
        <div class="w-2/12">
          {{
            quantity?.[address.farmName ?? '']?.boxQuantity ?? 0
              | number
              | spaceNumber
          }}
        </div>
        <div class="w-2/12">
          {{
            quantity?.[address.farmName ?? '']?.cargoPlaceQuantity ?? 0
              | number
              | spaceNumber
          }}
        </div>
        <div class="w-2/12 flex justify-end pr-2">
          <tui-badged-content>
            @if (address.attachmentId) {
              <tui-badge-notification size="s" tuiSlot="top">
                1
              </tui-badge-notification>
            }
            <button
              (click)="
                $event.stopPropagation();
                openAttachmentsDialog$.next({
                  attachmentId: address.attachmentId,
                  index: $index
                })
              "
              tuiIconButton
              size="s"
              icon="tuiIconPaperclip"
            ></button>
          </tui-badged-content>
        </div>
      </div>
    }
  </div>
</div>
