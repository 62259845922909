import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { AsyncPipe, DatePipe } from '@angular/common';
import {
  CdkFixedSizeVirtualScroll,
  CdkVirtualForOf,
  CdkVirtualScrollViewport,
} from '@angular/cdk/scrolling';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiScrollbarModule,
} from '@taiga-ui/core';
import { TuiCheckboxModule, TuiLineClampModule } from '@taiga-ui/kit';
import { TuiTableModule } from '@taiga-ui/addon-table';
import {
  BehaviorSubject,
  map,
  shareReplay,
  Subject,
  tap,
  withLatestFrom,
  combineLatest,
} from 'rxjs';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormsModule } from '@angular/forms';

export type ItemsSelectInputData<T> = {
  required?: boolean;
  submitText?: string;
  items: T[];
  identity: keyof T;
  headers: Array<{
    name: string;
    value: keyof T;
    width?: string;
    isDate?: boolean;
  }>;
  title: string;
};
@Component({
  selector: 'app-items-select',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkFixedSizeVirtualScroll,
    CdkVirtualForOf,
    CdkVirtualScrollViewport,
    TuiButtonModule,
    TuiCheckboxModule,
    TuiScrollbarModule,
    TuiTableModule,
    FormsModule,
    TuiLineClampModule,
    DatePipe,
  ],
  templateUrl: './items-select.component.html',
  styleUrl: './items-select.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemsSelectComponent<T> implements OnInit {
  title$ = new BehaviorSubject<string>('');
  items$: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);
  submitText$ = new BehaviorSubject('Применить');
  selectAll$ = new Subject<void>();
  selectedItems$ = new BehaviorSubject<T[]>([]);
  required$ = new BehaviorSubject(true);
  selectedItemsIds$ = new BehaviorSubject<Array<T[keyof T]>>([]);

  disabled$ = combineLatest([this.required$, this.selectedItems$]).pipe(
    map(([required, items]) => {
      if (required) {
        return !items.length;
      }
      return false;
    }),
  );

  isAllSelected$ = combineLatest([this.selectedItemsIds$, this.items$]).pipe(
    map(([selected, items]) => selected.length === items.length),
    shareReplay(),
  );

  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<T[], ItemsSelectInputData<T>>,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) {
    this.title$.next(context.data.title);
    this.submitText$.next(context.data.submitText ?? 'Применить');
    this.required$.next(context.data?.required ?? true);
  }

  onSelect(item: T) {
    const id = item[this.identity];
    if (this.selectedItemsIds$.value.includes(id)) {
      const filteredItems = this.selectedItems$.value.filter(
        item => item[this.identity] !== id,
      );
      const filteredIds = this.selectedItemsIds$.value.filter(v => v !== id);
      this.selectedItemsIds$.next(filteredIds);
      this.selectedItems$.next(filteredItems);
    } else {
      this.selectedItemsIds$.next([...this.selectedItemsIds$.value, id]);
      this.selectedItems$.next([...this.selectedItems$.value, item]);
    }
  }

  onAllSelectClick() {
    this.selectAll$
      .pipe(
        withLatestFrom(this.items$, this.isAllSelected$),
        tap(([, items, allSelected]) => {
          if (allSelected) {
            this.selectedItems$.next([]);
            this.selectedItemsIds$.next([]);
          } else {
            const ids = items.map(item => item[this.identity]);
            this.selectedItemsIds$.next(ids);
            this.selectedItems$.next(items);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  get headers() {
    return this.context.data.headers;
  }

  get identity() {
    return this.context.data.identity;
  }

  stringify(value: keyof T) {
    return value.toString();
  }

  ngOnInit() {
    this.onAllSelectClick();
    this.items$.next(this.context.data.items);
  }

  isSelected(item: T) {
    return this.selectedItemsIds$.value.includes(item[this.identity]);
  }

  close() {
    this.context.$implicit.complete();
  }

  submit() {
    this.context.$implicit.next(this.selectedItems$.value);
    this.context.$implicit.complete();
  }

  get columns() {
    const cols = this.headers.map(c => c.value);
    return ['selected', ...cols];
  }
}
