import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import {
  TuiButtonModule,
  TuiDialogService,
  TuiGroupModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TUI_PROMPT,
  TuiInputModule,
  TuiInputNumberModule,
} from '@taiga-ui/kit';
import { InputDateComponent } from '@shared/components/forms/input-date/input-date.component';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { VEHICLES } from '@shared/constants/vehicles';
import { Store } from '@ngxs/store';
import {
  catchError,
  delay,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { AlertService } from '@shared/services/alert.service';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { FormsService } from '@shared/services/forms.service';
import { Router } from '@angular/router';
import { Cargo } from '../../../../types/cargo';
import { CargoLoadingService } from '../../services/cargo-loading.service';
import { CargoLoadingState } from '../../store/cargo-loading.state';
import { RouteNames } from '../../../../route-names';

@Component({
  selector: 'app-main-info',
  standalone: true,
  imports: [
    TuiGroupModule,
    TuiInputModule,
    TuiInputNumberModule,
    InputDateComponent,
    AutocompleteComponent,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    AsyncPipe,
    TuiActiveZoneModule,
    TuiButtonModule,
  ],
  templateUrl: './main-info.component.html',
  styleUrl: './main-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainInfoComponent implements OnInit {
  update$ = new Subject<void>();
  moveToCalculation$ = new Subject<void>();
  form = this.fb.group({
    id: this.fb.nonNullable.control({ value: 0, disabled: true }),
    creationDate: this.fb.nonNullable.control({
      value: '',
      disabled: true,
    }),
    transportType: '',
    trailerType: '',
    sellerJson: this.fb.group({
      id: this.fb.control<Nullable<number>>(null),
      inn: '',
      name: '',
    }),
    uploadDate: '',
  });

  readonly vehicles = VEHICLES;
  sellers$ = this.cargoCatalogsService.sellers$;

  cargo$ = this.store.select(CargoLoadingState.getSelectedCargo);
  preOrders$ = this.store.select(CargoLoadingState.getOrders);

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    protected readonly cargoCatalogsService: CargoCatalogsService,
    private readonly alert: AlertService,
    private readonly cargoLoadingService: CargoLoadingService,
    private readonly forms: FormsService,
    private readonly router: Router,
    private readonly dialogs: TuiDialogService,
  ) {}

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
  }

  onMoveToCalculation() {
    this.moveToCalculation$
      .pipe(
        switchMap(() =>
          this.dialogs
            .open<boolean>(TUI_PROMPT, {
              data: {
                content:
                  '<h1 class="font-bold text-lg">Перевести в статус "Расчет" ?<h1>',
                yes: 'Подтвердить',
                no: 'Отмена',
              },
            })
            .pipe(filter(submit => submit)),
        ),
        withLatestFrom(this.cargo$, this.preOrders$),
        switchMap(([, cargo, orders]) => {
          if (!cargo) {
            return throwError(() => new Error('empty cargo'));
          }
          const orderIds = orders.map(p => p?.id ?? 0);
          return this.cargoLoadingService
            .moveFromLoadingToCalculation({
              orderIds,
              id: cargo.id,
            })
            .pipe(map(() => cargo.id));
        }),
        tap(id => {
          const path = [RouteNames.CARGO_CALCULATION, id].join('/');
          this.router.navigate([path]);
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  fillSellerJson(sellerId: Nullable<string>) {
    const sellerJsonControl = this.form.controls.sellerJson;
    const seller =
      this.cargoCatalogsService.sellerMap.get(sellerId?.toString() ?? '') ??
      null;
    if (seller) {
      sellerJsonControl.patchValue(seller);
    } else {
      sellerJsonControl.reset();
      sellerJsonControl.markAsDirty();
    }
  }

  fillFormOnStateChange() {
    this.store
      .select(CargoLoadingState.getSelectedCargo)
      .pipe(
        filter(state => state !== null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(cargo => {
        if (cargo) {
          this.form.patchValue(cargo);
        }
      });
  }

  updateOnChanges() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.form.pristine),
        withLatestFrom(this.cargo$),
        switchMap(([, cargo]) => {
          const payload: Cargo = {
            ...cargo,
            ...this.form.getRawValue(),
          } as Cargo;
          return this.cargoLoadingService.updateCargo(payload);
        }),
        tap(() => {
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.alert.showSuccess();
        }),
        catchError(err => {
          this.alert.showError(err.error?.error || 'Ошибка');
          return throwError(() => err);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.updateOnChanges();
    this.fillFormOnStateChange();
    this.onMoveToCalculation();
  }
}
