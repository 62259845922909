import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { VEHICLES, VehicleType } from '@shared/constants/vehicles';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import {
  TuiButtonModule,
  TuiDialogService,
  TuiGroupModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  tap,
  combineLatest,
  withLatestFrom,
  map,
  Subject,
  switchMap,
  filter,
  catchError,
  of,
} from 'rxjs';
import {
  TUI_PROMPT,
  TuiInputModule,
  TuiInputNumberModule,
} from '@taiga-ui/kit';
import { Store } from '@ngxs/store';
import { AsyncPipe } from '@angular/common';
import { roundDecimal } from '@shared/utils/math';
import {
  countPalletsAndCarts,
  getLoadingPercent,
  getRunningMetersFromCartQuantity,
  getRunningMetersFromUSPallets,
} from '@shared/utils/cargo-calculation';
import { AlertService } from '@shared/services/alert.service';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { CommonStateService } from '../../services/common-state.service';
import { PreOrdersState } from '../../store/pre-orders.state';
import { ComputedPropsForm } from '../../types';
import { PreOrder } from '../../../../types/pre-order';
import { PreOrderService } from '../../services/pre-order.service';

type TotalByPlaceType = Record<
  string,
  {
    width: number;
    length: number;
    count: number;
  }
>;

@Component({
  selector: 'app-selection-total-info',
  standalone: true,
  imports: [
    AutocompleteComponent,
    TuiGroupModule,
    ReactiveFormsModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    TuiInputModule,
    AsyncPipe,
    TuiButtonModule,
  ],
  templateUrl: './selection-total-info.component.html',
  styleUrl: './selection-total-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectionTotalInfoComponent implements OnInit {
  protected readonly vehicles = VEHICLES;

  createEmptyCargo$ = new Subject<void>();
  form = this.fb.group({
    transportType: VehicleType.TRUCK,
    trailerType: '',
  });

  computedProperties = this.fb.group<ComputedPropsForm>({
    totalColdPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalWarmPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalBox: this.fb.control<number>({ value: 0, disabled: true }),
  });

  vehicleLoadingPercent = this.fb.control({ value: 0, disabled: true });

  preOrders$ = this.store.select(PreOrdersState.getPreOrders);

  disabled$ = combineLatest([
    this.commonStateService.selectedPreOrders$,
    this.commonStateService.trailerType$,
  ]).pipe(
    map(([preOrders, trailer]) => {
      if (!preOrders.length || !trailer) {
        return true;
      }
      return false;
    }),
  );

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    protected readonly commonStateService: CommonStateService,
    private readonly store: Store,
    private readonly dialogs: TuiDialogService,
    private readonly preOrderService: PreOrderService,
    private readonly alert: AlertService,
    protected readonly cargoCatalogsService: CargoCatalogsService,
  ) {}

  onCreateEmptyCargo() {
    this.createEmptyCargo$
      .pipe(
        switchMap(() =>
          this.dialogs
            .open<boolean>(TUI_PROMPT, {
              data: {
                content: `<h1 class="font-bold text-lg">Подтвердите действие<h1>`,
                yes: 'Создать тикет',
                no: 'Отмена',
              },
            })
            .pipe(filter(submit => submit)),
        ),
        switchMap(() => {
          const formValue = this.form.getRawValue();
          return this.preOrderService
            .createCargo({
              preOrderIds: this.commonStateService.selectedPreOrders$.value,
              trailerType: formValue.trailerType ?? '',
              transportType: formValue.transportType ?? '',
            })
            .pipe(
              catchError(() => {
                this.alert.showError();
                return of(null);
              }),
            );
        }),
        catchError(() => {
          return of(null);
        }),
        tap(() => this.commonStateService.refresh$.next()),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateComputedProperties() {
    combineLatest([
      this.preOrders$,
      this.commonStateService.selectedPreOrdersMap$,
    ])
      .pipe(
        tap(([preOrders, selectedIdsMap]) => {
          const selectedPreOrders = preOrders.filter(preOrder =>
            selectedIdsMap.has(preOrder?.id ?? 0),
          );

          this.computedProperties.setValue(
            this.commonStateService.getComputedProperties(selectedPreOrders),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  calculateTrailerLoad(
    places: TotalByPlaceType,
    trailerLength: number,
    trailerWidth: number,
  ) {
    const usedLength = 0;
    const trailerAreaPerMeter = trailerWidth;
    let totalUsedLength = 0;
    const lengthUsage = [];

    for (const [name, { width, length, count }] of Object.entries(places)) {
      if (width === 0 || length === 0 || count === 0) {
        continue;
      }

      for (let i = 0; i < count; i++) {
        let placed = false;

        for (const row of lengthUsage) {
          const rowWidth = row.reduce((sum, p) => sum + p.width, 0);
          if (rowWidth + width <= trailerWidth) {
            row.push({ width, length });
            placed = true;
            break;
          }
        }

        if (!placed) {
          lengthUsage.push([{ width, length }]);
          totalUsedLength += length;
        }
      }
    }

    const totalUsedArea = totalUsedLength * trailerAreaPerMeter;
    const maxPossibleArea = trailerLength * trailerWidth;
    const loadPercentage = (totalUsedArea / maxPossibleArea) * 100;
    return roundDecimal(loadPercentage);
  }

  getCargoPlaceDimensions(preOrders: PreOrder[]) {
    const groupByCargoPlaceCode: TotalByPlaceType = {};

    return preOrders.reduce((previousValue, currentValue) => {
      const ruSeparator = 'х';
      const engSeparator = 'x';
      const code = currentValue.cargoPlaceJson.code ?? '';
      if (!code) {
        return previousValue;
      }
      const [width, length] =
        currentValue.cargoPlaceJson.size
          ?.replaceAll(ruSeparator, engSeparator)
          .split(engSeparator) ?? [];

      return {
        ...previousValue,
        [code]: {
          width: Number(width ?? 0) / 100,
          length: Number(length ?? 0) / 100,
          count:
            (previousValue[code]?.count ?? 0) +
            (currentValue.cargoPlaceQuantity ?? 0),
        },
      };
    }, groupByCargoPlaceCode);
  }

  updateVehicleLoadingPercent() {
    combineLatest([
      this.preOrders$,
      this.commonStateService.selectedPreOrdersMap$,
      this.form.controls.trailerType.valueChanges,
    ])
      .pipe(
        withLatestFrom(this.cargoCatalogsService.trailersTypes$),
        tap(([[preOrders, selectedIdsMap, trailerType], trailerTypes]) => {
          const selectedPreOrders = preOrders.filter(preOrder =>
            selectedIdsMap.has(preOrder?.id ?? 0),
          );
          if (!trailerType || !selectedPreOrders.length) {
            this.vehicleLoadingPercent.setValue(0);
          } else {
            const trailerTypeDetails = trailerTypes.find(
              trailer => trailer.code === trailerType,
            );
            const [pallets, carts] = countPalletsAndCarts(selectedPreOrders);
            const palletsMeters = getRunningMetersFromUSPallets(pallets);
            const cartsMeters = getRunningMetersFromCartQuantity(carts);
            const meters = palletsMeters + cartsMeters;
            this.vehicleLoadingPercent.setValue(
              getLoadingPercent(meters, trailerTypeDetails?.length ?? 0),
            );
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.onCreateEmptyCargo();
    this.updateComputedProperties();
    this.updateVehicleLoadingPercent();
    this.form.controls.trailerType.valueChanges
      .pipe(
        tap(value => this.commonStateService.trailerType$.next(value)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
