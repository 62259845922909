import { bootstrapApplication } from '@angular/platform-browser';
import * as Sentry from '@sentry/angular';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { environment } from './environments/environment';

Sentry.init({
  dsn: 'https://7b9c3fb3a81fac4fa1b95a68f552f430@o4508227834216448.ingest.de.sentry.io/4508892987850832',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  enabled: String(environment.production) === 'true',
  tracesSampleRate: 0.3,
  tracePropagationTargets: [
    'https://truckby.ru',
    'https://greenland.smuggler.pro',
  ],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
});
bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
