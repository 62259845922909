import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { Store } from '@ngxs/store';
import {
  catchError,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import {
  TuiButtonModule,
  TuiDialogService,
  TuiSvgModule,
} from '@taiga-ui/core';
import { AlertService } from '@shared/services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import _ from 'lodash';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SpaceNumberPipe } from '@shared/pipes/space-number.pipe';
import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import {
  AttachmentsManagerComponent,
  AttachmentsManagerInputData,
} from '@shared/components/attachments-manager/attachments-manager.component';
import {
  TuiBadgedContentModule,
  TuiBadgeNotificationModule,
} from '@taiga-ui/experimental';
import {
  Cargo,
  CargoLoadingAddress,
  CargoUnloadingAddress,
} from '../../../../types/cargo';
import { CargoLoadingService } from '../../services/cargo-loading.service';
import { CargoLoadingState } from '../../store/cargo-loading.state';
import { CargoLoadingReplaceLoadingAddressesJson } from '../../store/cargo-loading.actions';
import { getBoxAndPlaceQuantityInGroup } from '../../utils/box-place-quantity';

@Component({
  selector: 'app-loading-addresses',
  standalone: true,
  imports: [
    AsyncPipe,
    CdkDrag,
    CdkDropList,
    TuiButtonModule,
    TuiLetModule,
    DecimalPipe,
    SpaceNumberPipe,
    TuiBadgeNotificationModule,
    TuiBadgedContentModule,
    TuiBadgedContentModule,
    TuiSvgModule,
  ],
  templateUrl: './loading-addresses.component.html',
  styleUrl: './loading-addresses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingAddressesComponent implements OnInit {
  cargo$ = this.store.select(CargoLoadingState.getSelectedCargo);

  loadingAddresses$ = this.cargo$.pipe(
    map(cargo => cargo?.loadingAddressesJson ?? []),
  );

  boxAndPlaceQuantityPerFarm$ = this.store
    .select(CargoLoadingState.getOrders)
    .pipe(
      map(preOrders => {
        const groupByFarmName = _.groupBy(preOrders, preOrder => {
          return preOrder.farmJson.name;
        });
        return getBoxAndPlaceQuantityInGroup(groupByFarmName);
      }),
    );

  updateSort$ = new Subject<
    Pick<CdkDragDrop<CargoLoadingAddress>, 'currentIndex' | 'previousIndex'>
  >();

  openAttachmentsDialog$ = new Subject<{
    attachmentId: Nullable<number>;
    index: number;
  }>();

  constructor(
    private readonly store: Store,
    private readonly cargoLoadingService: CargoLoadingService,
    private readonly alert: AlertService,
    private readonly destroyRef: DestroyRef,
    private readonly dialogs: TuiDialogService,
  ) {}

  onUpdateSort() {
    this.updateSort$
      .pipe(
        filter(params => params.currentIndex !== params.previousIndex),
        withLatestFrom(this.cargo$),
        switchMap(([{ previousIndex, currentIndex }, initialCargo]) => {
          if (!initialCargo) {
            return of(null);
          }
          const loadingAddressesJson = structuredClone(
            initialCargo?.loadingAddressesJson ?? [],
          );

          moveItemInArray(loadingAddressesJson, previousIndex, currentIndex);
          this.replaceLoadingAddresses(loadingAddressesJson);
          const cargo: Cargo = {
            ...initialCargo,
            loadingAddressesJson,
          };
          return this.cargoLoadingService.updateCargo(cargo).pipe(
            tap(() => {
              this.alert.showSuccess('Сортировка сохранена');
            }),
            catchError(() => {
              this.replaceLoadingAddresses(
                initialCargo.loadingAddressesJson ?? [],
              );
              this.alert.showError();
              return of(null);
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onAttachments() {
    this.openAttachmentsDialog$
      .pipe(
        withLatestFrom(this.cargo$),
        switchMap(([{ attachmentId, index }, cargo]) => {
          const data: AttachmentsManagerInputData = {
            cargoId: cargo?.id ?? 0,
            attachmentId,
          };
          return this.dialogs
            .open<Nullable<number>>(
              new PolymorpheusComponent(AttachmentsManagerComponent),
              {
                size: 'auto',
                data,
              },
            )
            .pipe(
              map(updatedAttachmentId => {
                return {
                  updatedAttachmentId,
                  attachmentId,
                  index,
                };
              }),
            );
        }),
        withLatestFrom(this.cargo$),
        switchMap(([{ attachmentId, updatedAttachmentId, index }, cargo]) => {
          const loadingAddressesJson =
            cargo?.loadingAddressesJson.map((address, addressIndex) => {
              return addressIndex === index
                ? { ...address, attachmentId: updatedAttachmentId }
                : address;
            }) ?? [];
          const payload: Cargo = {
            ...cargo,
            loadingAddressesJson,
          } as Cargo;
          return this.cargoLoadingService.updateCargo(payload).pipe(
            tap(() => {
              this.alert.showSuccess();
            }),
          );
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  replaceLoadingAddresses(addresses: CargoLoadingAddress[]) {
    this.store.dispatch(new CargoLoadingReplaceLoadingAddressesJson(addresses));
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<CargoUnloadingAddress[]>) {
    this.updateSort$.next({ previousIndex, currentIndex });
  }

  ngOnInit() {
    this.onUpdateSort();
    this.onAttachments();
  }
}
