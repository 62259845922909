import { Action, Selector, State, StateContext } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { produce } from 'immer';
import {
  AddPreOrder,
  DeletePreOrder,
  SetPreOrders,
  UpdatePreOrder,
} from './pre-orders.actions';
import { PreOrdersStateModel } from '../types';

@State<PreOrdersStateModel>({
  name: 'preOrders',
  defaults: {
    preOrders: [],
  },
})
@Injectable()
export class PreOrdersState {
  @Action(SetPreOrders)
  setPreOrders(ctx: StateContext<PreOrdersStateModel>, action: SetPreOrders) {
    ctx.patchState({
      preOrders: action.preOrders,
    });
  }

  @Action(AddPreOrder)
  addPreOrder(ctx: StateContext<PreOrdersStateModel>, action: AddPreOrder) {
    ctx.setState(
      produce(draft => {
        draft.preOrders.push(action.preOrder);
      }),
    );
  }

  @Action(UpdatePreOrder)
  updatePreOrder(
    ctx: StateContext<PreOrdersStateModel>,
    action: UpdatePreOrder,
  ) {
    ctx.setState(
      produce(draft => {
        const preOrders = draft.preOrders;
        const preOrderIndex = preOrders.findIndex(
          i => i.id === action.preOrder.id,
        );
        draft.preOrders.splice(preOrderIndex, 1, action.preOrder);
      }),
    );
  }

  @Action(DeletePreOrder)
  deletePreOrder(
    ctx: StateContext<PreOrdersStateModel>,
    action: DeletePreOrder,
  ) {
    ctx.setState(
      produce(draft => {
        const preOrders = draft.preOrders;
        const orderGroupsIndex = preOrders.findIndex(i => i.id === action.id);
        draft.preOrders.splice(orderGroupsIndex, 1);
      }),
    );
  }

  @Selector()
  static getPreOrders(state: PreOrdersStateModel) {
    return state.preOrders;
  }
}
