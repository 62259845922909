import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule } from '@angular/forms';
import { FormsService } from '@shared/services/forms.service';
import {
  LoadingItem,
  LoadingItemForm,
} from '@shared/components/loading-calculator/types';
import { LoadingItemComponent } from '@shared/components/loading-calculator/components/loading-item/loading-item.component';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import {
  combineLatest,
  debounceTime,
  map,
  Observable,
  of,
  startWith,
  tap,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  getCartQuantityFromRunningMeters,
  getLoadingPercent,
  getRunningMetersFromCartQuantity,
  getRunningMetersFromUSPallets,
  numberOfPalletsThatCanBeLoaded,
} from '@shared/utils/cargo-calculation';
import {
  countPalletsAndCarts,
  getComputedProperties,
} from '@shared/components/loading-calculator/utils';
import { roundDecimal } from '@shared/utils/math';
import { TrailerType } from '../../../types/trailer';
import { ComputedPropsForm } from '../../../modules/pre-order/types';

type TotalForm = ComputedPropsForm;
@Component({
  selector: 'app-loading-calculator',
  standalone: true,
  imports: [
    LoadingItemComponent,
    TuiButtonModule,
    TuiInputModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
  ],
  templateUrl: './loading-calculator.component.html',
  styleUrl: './loading-calculator.component.scss',
  providers: [CargoCatalogsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoadingCalculatorComponent implements OnInit {
  items = this.fb.array<FormGroup<LoadingItemForm>>([]);
  computedProperties = this.fb.group<TotalForm>({
    totalColdPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalWarmPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalBox: this.fb.control<number>({ value: 0, disabled: true }),
  });

  canBeLoaded = this.fb.control({ value: '', disabled: true });
  items$: Observable<LoadingItem[]> = this.items.valueChanges.pipe(
    startWith([]),
    debounceTime(300),
    map(() => this.items.getRawValue()),
  );

  requiredVehiclesQuantity = this.fb.control({ value: 0, disabled: true });

  constructor(
    private readonly fb: FormBuilder,
    private readonly forms: FormsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly cargoCatalogsService: CargoCatalogsService,
    private readonly destroyRef: DestroyRef,
  ) {}

  updateComputedProperties() {
    this.items$
      .pipe(
        tap(loadingItems => {
          this.computedProperties.setValue({
            ...getComputedProperties(loadingItems),
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateRequiredVehiclesQuantity() {
    combineLatest([
      this.items$,
      of(TrailerType.REFRIGERATOR_WIDE),
      this.cargoCatalogsService.trailersTypes$,
    ])
      .pipe(
        tap(([loadingItems, trailerType, trailerTypes]) => {
          if (!trailerType || !loadingItems.length) {
            this.requiredVehiclesQuantity.setValue(0);
          } else {
            const trailerTypeDetails = trailerTypes.find(
              trailer => trailer.code === trailerType,
            );
            const [pallets, carts] = countPalletsAndCarts(loadingItems);
            const palletsMeters = getRunningMetersFromUSPallets(pallets);
            const cartsMeters = getRunningMetersFromCartQuantity(carts);
            const meters = palletsMeters + cartsMeters;
            const requiredVehiclesQuantity =
              getLoadingPercent(meters, trailerTypeDetails?.length ?? 0) / 100;
            this.requiredVehiclesQuantity.setValue(requiredVehiclesQuantity);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateCanBeLoadedProperties() {
    combineLatest([
      this.items$,
      this.cargoCatalogsService.trailersTypes$,
      this.cargoCatalogsService.cargoPlaces$,
    ])
      .pipe(
        tap(([loadingItems, trailers]) => {
          const [pallets, carts] = countPalletsAndCarts(loadingItems);
          const palletsMeters = getRunningMetersFromUSPallets(pallets);
          const cartsMeters = getRunningMetersFromCartQuantity(carts);
          const meters = palletsMeters + cartsMeters;
          const trailer = trailers.find(
            t => t.code === TrailerType.REFRIGERATOR_WIDE,
          );
          if (trailer) {
            const trailerLength = trailer.length ?? 0;
            const trailersUsed = meters / trailerLength;
            const trailersNeed = Math.max(Math.ceil(trailersUsed), 1);
            const emptyRunningMeters =
              (trailersNeed - trailersUsed) * trailerLength;
            const palletsUsQuantity =
              numberOfPalletsThatCanBeLoaded(emptyRunningMeters);
            const cartsQuantity = roundDecimal(
              getCartQuantityFromRunningMeters(emptyRunningMeters),
            );
            this.canBeLoaded.setValue(
              `${palletsUsQuantity} Палет US или ${cartsQuantity} тележек`,
            );
          } else {
            this.canBeLoaded.reset();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.updateRequiredVehiclesQuantity();
    this.updateComputedProperties();
    this.updateCanBeLoadedProperties();
  }

  addItem() {
    const group = this.fb.group<LoadingItemForm>({
      box: this.fb.nonNullable.control(false),
      cargoPlaceJson: this.forms.getCargoPlaceJsonForm(),
      cargoPlaceQuantity: this.fb.control(null),
      cold: this.fb.nonNullable.control(false),
      packageQuantity: this.fb.control(null),
      packageQuantityLimit: this.fb.control({
        value: null,
        disabled: true,
      }),
      packageTypeJson: this.forms.getPackageTypeJsonForm(),
    });
    this.items.push(group);
    this.cdr.markForCheck();
  }

  removeItem(index: number) {
    this.items.removeAt(index);
  }
}
