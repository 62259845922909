<div class="flex mb-2 gap-x-2 items-center">
  <app-autocomplete
    label="Код покупателя"
    class="w-2/12"
    [labelOutside]="false"
    [formControl]="orderForm.controls.buyerCode"
    [items]="buyersOfSelectedFarm$ | async"
    [itemSearch]="'search'"
    [itemValue]="'code'"
    [displayValue]="'code'"
    [itemText]="'code'"
  ></app-autocomplete>

  @if ((isImport$ | async) === true) {
    <div class="flex gap-x-2 w-3/12">
      <app-autocomplete
        class="w-3/12"
        [items]="countries()"
        [itemText]="'name'"
        [itemValue]="'letterCode'"
        [itemSearch]="'search'"
        [formControl]="orderForm.controls.countryLetter"
      ></app-autocomplete>
      <tui-input
        class="w-4/12"
        [formControl]="orderForm.controls.airWaybillNumber"
        tuiTextfieldSize="m"
        >AWB</tui-input
      >
      <div class="w-1/12 flex items-center">
        <app-checkbox
          [formControl]="orderForm.controls.precooling"
          [tuiHint]="precooling"
        ></app-checkbox>
        <ng-template #precooling> Pre-cooling </ng-template>
      </div>

      <tui-input-number
        class="w-4/12"
        tuiTextfieldSize="m"
        [formControl]="orderForm.controls.weight"
        >Вес</tui-input-number
      >
    </div>
  } @else {
    <div class="w-3/12" tuiGroup [collapsed]="true">
      <app-autocomplete
        label="Грузовое место"
        [labelOutside]="false"
        class="tui-group__inherit-item w-8/12 tui-group__auto-width-item"
        componentClass="tui-group__inherit-item"
        [formControl]="orderForm.controls.cargoPlaceJson.controls.code"
        [items]="cargoCatalogsService.cargoPlaces$ | async"
        [itemValue]="'code'"
        [itemText]="'name'"
        [displayValue]="'name'"
        [itemSearch]="'search'"
      ></app-autocomplete>
      <tui-input-number
        [formControl]="cargoPlaceQuantityLimit"
        class="w-4/12 tui-group__auto-width-item"
        tuiTextfieldSize="m"
      ></tui-input-number>
    </div>
  }

  <div>
    <app-checkbox
      [formControl]="orderForm.controls.box"
      [tuiHint]="box"
    ></app-checkbox>
    <ng-template #box> Коробки</ng-template>
  </div>

  <div class="flex w-5/12 gap-x-2">
    @if (hasBox$ | async) {
      <div class="w-9/12" tuiGroup [collapsed]="true">
        <app-autocomplete
          label="Код"
          class="tui-group__inherit-item w-3/12 tui-group__auto-width-item"
          componentClass="tui-group__inherit-item"
          [labelOutside]="false"
          [formControl]="orderForm.controls.packageTypeJson.controls.code"
          [items]="cargoCatalogsService.packageTypes$ | async"
          [itemValue]="'code'"
          [itemText]="'code'"
          [itemSearch]="'search'"
        ></app-autocomplete>
        <tui-input
          [formControl]="orderForm.controls.packageTypeJson.controls.name"
          class="w-6/12 tui-group__auto-width-item"
          tuiTextfieldSize="m"
          >Описание</tui-input
        >
        <tui-input-number
          [formControl]="orderForm.controls.packageQuantityLimit"
          class="w-3/12 tui-group__auto-width-item"
          tuiTextfieldSize="m"
          >Лимит</tui-input-number
        >
      </div>
      <tui-input-number
        [precision]="0"
        class="w-2/12"
        tuiTextfieldSize="m"
        [formControl]="orderForm.controls.packageQuantity"
        >Кол-во упаковки</tui-input-number
      >
    }
  </div>

  <tui-input-number
    tuiTextfieldSize="m"
    [formControl]="orderForm.controls.cargoPlaceQuantity"
    >Кол-во</tui-input-number
  >
</div>
