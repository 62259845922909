import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import {
  BehaviorSubject,
  catchError,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
} from 'rxjs';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { AttachmentService } from '@shared/services/attachment.service';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { AlertService } from '@shared/services/alert.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { FilesInputComponent } from '@shared/components/forms/files-input/files-input.component';
import { mimeTypes } from '@shared/constants/mime-types';
import { roundDecimal } from '@shared/utils/math';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { InputDateComponent } from '@shared/components/forms/input-date/input-date.component';
import { CURRENCIES } from '@shared/constants/currency';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { Attachment } from '../../../../../../types/attachment';
import { CargoOrder, CargoOrderForm } from '../../../../../../types/cargo';

export type PreOrderAttachmentsInputData = {
  cargoId: number;
  order: CargoOrder;
};

type PreOrderAttachmentsForm = Pick<
  CargoOrderForm,
  'invoiceCost' | 'invoiceCurrencyLetter' | 'invoiceDate' | 'invoiceNumber'
>;
@Component({
  selector: 'app-pre-order-attachments',
  standalone: true,
  imports: [
    AsyncPipe,
    FilesInputComponent,
    TuiButtonModule,
    ReactiveFormsModule,
    TuiInputModule,
    InputDateComponent,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    AutocompleteComponent,
  ],
  templateUrl: './order-attachments.component.html',
  styleUrl: './order-attachments.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderAttachmentsComponent implements OnInit {
  refresh$ = new Subject<void>();
  attachmentId$ = new BehaviorSubject<Nullable<number>>(null);
  cargoId$ = new BehaviorSubject<number>(0);
  attachments$ = new BehaviorSubject<Attachment[]>([]);
  attach$ = new Subject<void>();
  saveChanges$ = new Subject<void>();
  download$ = new Subject<void>();
  delete$ = new Subject<void>();
  files = this.fb.control<Blob[]>([]);
  protected readonly mimeTypes = mimeTypes;
  protected readonly roundDecimal = roundDecimal;

  preOrder = this.fb.group<PreOrderAttachmentsForm>({
    invoiceCost: this.fb.control(null),
    invoiceCurrencyLetter: this.fb.control(null),
    invoiceDate: this.fb.control(null),
    invoiceNumber: this.fb.control(null),
  });

  protected readonly CURRENCIES = CURRENCIES;
  constructor(
    @Inject(POLYMORPHEUS_CONTEXT)
    protected readonly context: TuiDialogContext<
      CargoOrder,
      PreOrderAttachmentsInputData
    >,
    private readonly attachmentService: AttachmentService,
    private readonly destroyRef: DestroyRef,
    private readonly fb: FormBuilder,
    private readonly alert: AlertService,
  ) {}

  onDownload() {
    this.download$
      .pipe(
        map(() => this.attachmentId$.value),
        filter((attachmentId): attachmentId is number => !!attachmentId),
        switchMap(attachmentId =>
          this.attachmentService
            .download(this.cargoId$.value, attachmentId)
            .pipe(
              catchError(err => {
                this.alert.showError();
                return of(null);
              }),
            ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onRefresh() {
    this.refresh$
      .pipe(
        switchMap(() => {
          if (this.attachmentId$.value) {
            return this.attachmentService.getFileListFromAttachment(
              this.cargoId$.value,
              this.attachmentId$.value,
            );
          }
          return of([]);
        }),
        tap(attachments => {
          this.attachments$.next(attachments);
        }),
      )
      .subscribe();
  }

  onDelete() {
    this.delete$
      .pipe(
        map(() => this.attachmentId$.value),
        filter((attachmentId): attachmentId is number => !!attachmentId),
        switchMap(attachmentId =>
          this.attachmentService.delete(this.cargoId$.value, attachmentId).pipe(
            tap(() => {
              this.attachmentId$.next(null);
              this.attachments$.next([]);
              this.saveOrder();
            }),
            catchError(() => {
              this.alert.showError();
              return of(null);
            }),
          ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onSaveChanges() {
    this.saveChanges$
      .pipe(
        switchMap(() => this.attachFiles()),
        tap(() => this.saveOrder()),
        tap(() => this.context.$implicit.complete()),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
      )
      .subscribe();
  }

  saveOrder() {
    const order: CargoOrder = {
      ...this.context.data.order,
      ...this.preOrder.getRawValue(),
      attachmentId: this.attachmentId$.value,
    };
    this.context.$implicit.next(order);
  }

  attachFiles() {
    const files = this.files.getRawValue() ?? [];
    if (!files) {
      return of(null);
    }
    const formData = new FormData();
    files.forEach((file, index) => {
      const fileKey = `file${index + 1}`;
      formData.append(fileKey, file as unknown as Blob);
    });

    return this.attachmentService
      .attach(this.cargoId$.value, this.attachmentId$.value, formData)
      .pipe(
        tap(id => {
          this.files.reset();
          this.attachmentId$.next(id);
          this.refresh$.next();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
      );
  }

  setInitialData() {
    const data = this.context.data;
    this.attachmentId$.next(data.order.attachmentId);
    this.preOrder.patchValue(data.order);
  }

  ngOnInit() {
    this.cargoId$.next(this.context.data.cargoId);
    this.setInitialData();
    this.onSaveChanges();
    this.onRefresh();
    this.onDownload();
    this.onDelete();
    this.refresh$.next();
  }
}
