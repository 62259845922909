import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  OnInit,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import {
  TuiButtonModule,
  TuiGroupModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { MaskitoModule } from '@maskito/angular';
import { phoneMask } from '@shared/utils/masks';
import { InputDateComponent } from '@shared/components/forms/input-date/input-date.component';
import {
  TuiBadgedContentModule,
  TuiBadgeNotificationModule,
} from '@taiga-ui/experimental';

import { fromFormControlValue } from '@shared/utils/observables';
import { map, Observable } from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { CarrierJsonTransportFormWithGuid } from '../../../../../../types/cargo';

@Component({
  selector: 'app-transport-item',
  standalone: true,
  imports: [
    TuiButtonModule,
    TuiGroupModule,
    TuiInputModule,
    TuiTextfieldControllerModule,
    MaskitoModule,
    TuiInputNumberModule,
    InputDateComponent,
    ReactiveFormsModule,
    TuiBadgeNotificationModule,
    TuiBadgedContentModule,
    AsyncPipe,
  ],
  templateUrl: './transport-item.component.html',
  styleUrl: './transport-item.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransportItemComponent implements OnInit {
  @Input({ required: true })
  transportForm: FormGroup<CarrierJsonTransportFormWithGuid>;

  @Output() remove = new EventEmitter();
  @Output() attach = new EventEmitter();
  protected readonly phoneMask = phoneMask;

  hasAttachment$: Observable<boolean>;
  ngOnInit() {
    this.hasAttachment$ = fromFormControlValue(
      this.transportForm.controls.attachmentId,
    ).pipe(map(id => !!id));
  }
}
