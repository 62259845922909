<div class="max-w-[1400px] mx-auto">
  <div class="flex gap-x-2">
    <div class="w-4/12" tuiGroup [collapsed]="true">
      <app-autocomplete
        [formControl]="form.controls.transportType"
        class="tui-group__inherit-item w-1/4 tui-group__auto-width-item"
        componentClass="tui-group__inherit-item"
        [items]="vehicles"
        [itemValue]="'value'"
        [itemText]="'name'"
        [displayValue]="'name'"
      >
      </app-autocomplete>
      <app-autocomplete
        [formControl]="form.controls.trailerType"
        class="tui-group__inherit-item w-3/4 tui-group__auto-width-item"
        componentClass="tui-group__inherit-item"
        [items]="cargoCatalogsService.trailersTypes$ | async"
        [itemValue]="'code'"
        [itemText]="'name'"
        [displayValue]="'name'"
      >
      </app-autocomplete>
    </div>

    <tui-input-number
      tuiTextfieldSize="m"
      class="w-1/12 contrast-disabled-field"
      [formControl]="vehicleLoadingPercent"
      >Загрузка %</tui-input-number
    >
    <div class="flex gap-x-2 w-6/12 mr-0 ml-auto">
      <tui-input
        class="w-5/12 contrast-disabled-field"
        tuiTextfieldSize="m"
        [formControl]="computedProperties.controls.totalWarmPlaces"
        >Тепло</tui-input
      >
      <tui-input
        class="w-5/12 contrast-disabled-field"
        tuiTextfieldSize="m"
        [formControl]="computedProperties.controls.totalColdPlaces"
        >Холод</tui-input
      >
      <tui-input-number
        class="w-2/12 contrast-disabled-field"
        tuiTextfieldSize="m"
        [formControl]="computedProperties.controls.totalBox"
        >Коробки</tui-input-number
      >
    </div>
    <button
      [disabled]="(disabled$ | async)!"
      (click)="createEmptyCargo$.next()"
      tuiButton
      appearance="accent"
      size="m"
    >
      Далее
    </button>
  </div>
</div>
