import { CargoPlaceQuantityInTrailer } from '../../types/cargo-place';
import { TrailerType } from '../../types/trailer';

export enum VehicleType {
  TRUCK = 'truck',
}

export const VEHICLES = [
  {
    name: 'Авто',
    value: VehicleType.TRUCK,
  },
];

export const getCargoPlaceQuantityByTrailerType = (
  trailerType: TrailerType,
  quantityInTrailers: CargoPlaceQuantityInTrailer[],
): Nullable<number> => {
  return (
    quantityInTrailers.find(q => q.trailerType === trailerType)?.quantity ??
    null
  );
};
