<div class="detail-page">
  <div class="sticky top-0 z-10 app-document-header py-4">
    <app-selection-total-info></app-selection-total-info>
  </div>
  <div class="detail-page__container">
    <div class="my-4">
      <app-pre-orders-sorting></app-pre-orders-sorting>
    </div>
    <div class="my-4">
      <app-pre-orders></app-pre-orders>
    </div>
  </div>
  <div class="sticky bottom-0 z-10 app-document-header py-4">
    <div class="max-w-[1400px] mx-auto flex">
      <div class="w-full flex justify-between">
        <app-total-info class="w-full"></app-total-info>
        <div class="self-end">
          <app-scroll-top-button></app-scroll-top-button>
        </div>
      </div>
    </div>
  </div>
</div>
