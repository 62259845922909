import { Route } from '@angular/router';
import { provideStates } from '@ngxs/store';
import { RouteNames } from '../../route-names';
import { PreOrdersPageComponent } from './pages/pre-orders-page/pre-orders-page.component';
import { PreOrdersState } from './store/pre-orders.state';

export const PRE_ORDER_ROUTE: Route = {
  title: 'Предзаказы',
  path: RouteNames.PRE_ORDERS_PAGE,
  component: PreOrdersPageComponent,
  providers: [provideStates([PreOrdersState])],
};
