import { provideAnimations } from '@angular/platform-browser/animations';
import { TUI_SANITIZER, TuiRootModule } from '@taiga-ui/core';
import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';
import {
  provideRouter,
  Router,
  withComponentInputBinding,
} from '@angular/router';

import { provideStore } from '@ngxs/store';
import { StorageOption, withNgxsStoragePlugin } from '@ngxs/storage-plugin';
import { withNgxsReduxDevtoolsPlugin } from '@ngxs/devtools-plugin';
import { TUI_LANGUAGE, TUI_RUSSIAN_LANGUAGE } from '@taiga-ui/i18n';
import { of } from 'rxjs';
import {
  HttpClientModule,
  provideHttpClient,
  withInterceptors,
} from '@angular/common/http';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { provideServiceWorker } from '@angular/service-worker';
import { WINDOW } from '@shared/providers/window.provider';
import { NAVIGATOR } from '@shared/providers/navigator.provider';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { NgDompurifySanitizer } from '@tinkoff/ng-dompurify';
import * as Sentry from '@sentry/angular';
import { routes } from './app.routes';
import { AuthState } from './store/auth/auth.state';
import { INDEXED_DB_CONFIG } from './config/indexed-db';
import { httpInterceptor } from './http.interceptor';
import CatalogsState from './store/catalogs/catalogs.state';
import { environment } from '../environments/environment';
import { CargoCalculationState } from './modules/cargo-calculation/store/cargo-calculation.state';

export const appConfig: ApplicationConfig = {
  providers: [
    {
      provide: APP_ENVIRONMENT,
      useFactory: () => environment,
    },
    { provide: WINDOW, useValue: window },
    { provide: NAVIGATOR, useValue: navigator },
    {
      provide: TUI_LANGUAGE,
      useValue: of(TUI_RUSSIAN_LANGUAGE),
    },
    {
      provide: TUI_SANITIZER,
      useClass: NgDompurifySanitizer,
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideAnimations(),
    provideRouter(routes, withComponentInputBinding()),
    provideHttpClient(withInterceptors([httpInterceptor])),
    provideStore(
      [AuthState, CatalogsState, CargoCalculationState],
      withNgxsStoragePlugin({
        keys: ['auth', 'catalogs', 'kanban'],
        storage: StorageOption.LocalStorage,
      }),
      withNgxsReduxDevtoolsPlugin({
        disabled: false,
      }),
    ),
    importProvidersFrom(
      HttpClientModule,
      TuiRootModule,
      NgxIndexedDBModule.forRoot(INDEXED_DB_CONFIG),
    ),
    provideServiceWorker('my-service-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
