<div class="flex gap-x-2 w-9/12">
  <tui-input-number
    class="w-1/12 contrast-disabled-field"
    tuiTextfieldSize="m"
    [formControl]="computedProperties.controls.count"
    >Заказы</tui-input-number
  >
  <tui-input
    class="w-4/12 contrast-disabled-field"
    tuiTextfieldSize="m"
    [formControl]="computedProperties.controls.totalWarmPlaces"
    >Тепло</tui-input
  >
  <tui-input
    class="w-4/12 contrast-disabled-field"
    tuiTextfieldSize="m"
    [formControl]="computedProperties.controls.totalColdPlaces"
    >Холод</tui-input
  >
  <tui-input-number
    class="w-1/12 contrast-disabled-field"
    tuiTextfieldSize="m"
    [formControl]="computedProperties.controls.totalBox"
    >Коробки</tui-input-number
  >
  <tui-input-number
    tuiTextfieldSize="m"
    class="w-2/12 contrast-disabled-field"
    [formControl]="requiredVehiclesQuantity"
    >Кол-во машин</tui-input-number
  >
</div>
