import { roundDecimal } from '@shared/utils/math';
import { CargoPlaceType } from '@shared/constants/cargo-places';
import { PreOrder } from '../../types/pre-order';
import { CargoOrder } from '../../types/cargo';

export function getLoadingPercentAsUSPallet(
  placeQuantity: number,
  trailerLength: number,
) {
  const runningMeters = Math.ceil(placeQuantity / 2) * 1.2;
  return roundDecimal((runningMeters / trailerLength) * 100);
}

export function getLoadingPercent(
  runningMeters: number,
  trailerLength: number,
) {
  return roundDecimal((runningMeters / trailerLength) * 100);
}

export function getRunningMetersFromUSPallets(placeQuantity: number) {
  return roundDecimal((placeQuantity / 2) * 1.2);
}

export function numberOfPalletsThatCanBeLoaded(runningMeters: number) {
  return roundDecimal((runningMeters / 1.2) * 2);
}

const runningMetersByCartQuantity = new Map<number, number>([
  [1, 0.57],
  [2, 1.35],
  [3, 1.35],
  [4, 1.35],
  [5, 1.92],
  [6, 1.92],
  [7, 2.48],
  [8, 2.48],
  [9, 2.83],
  [13, 4.05],
]);

export function getRunningMetersFromCartQuantity(cartQuantity: number): number {
  if (cartQuantity <= 0) {
    return 0;
  }

  const sortedKeys = Array.from(runningMetersByCartQuantity.keys()).sort(
    (a, b) => b - a,
  );

  const bestMatch = sortedKeys.find(key => key <= cartQuantity);

  if (!bestMatch) {
    return runningMetersByCartQuantity.get(1)!;
  } // Если ничего не нашли, берем значение для 1 ящика

  const remainingQuantity = cartQuantity - bestMatch;

  const additionalMeters =
    remainingQuantity > 0 && remainingQuantity < 1
      ? runningMetersByCartQuantity.get(1)!
      : 0;

  return (
    runningMetersByCartQuantity.get(bestMatch)! +
    additionalMeters +
    getRunningMetersFromCartQuantity(Math.floor(remainingQuantity))
  );
}

export function countPalletsAndCarts(preOrders: PreOrder[] | CargoOrder[]) {
  return preOrders.reduce(
    ([pallets, carts], current) => {
      const cart = current.cargoPlaceJson.code === CargoPlaceType.CART;
      const quantity = current.cargoPlaceQuantity ?? 0;
      return [
        cart ? pallets : pallets + quantity,
        cart ? carts + quantity : carts,
      ];
    },
    [0, 0],
  );
}
export function getCartQuantityFromRunningMeters(meters: number): number {
  if (meters <= 0) {
    return 0;
  }

  const sortedEntries = Array.from(runningMetersByCartQuantity.entries()).sort(
    ([, a], [, b]) => b - a,
  );

  let cartCount = 0;

  for (const [quantity, length] of sortedEntries) {
    while (meters >= length) {
      meters -= length;
      cartCount += quantity;
    }
  }

  return cartCount;
}
