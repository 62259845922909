import {
  ChangeDetectionStrategy,
  Component,
  forwardRef,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import {
  TuiFieldErrorPipeModule,
  TuiFileLike,
  TuiInputFilesModule,
} from '@taiga-ui/kit';
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from '@angular/forms';
import { AsyncPipe, NgForOf } from '@angular/common';
import { TuiErrorModule } from '@taiga-ui/core';
import { tap } from 'rxjs';

@Component({
  selector: 'app-files-input',
  standalone: true,
  imports: [
    TuiInputFilesModule,
    ReactiveFormsModule,
    AsyncPipe,
    TuiFieldErrorPipeModule,
    TuiErrorModule,
    NgForOf,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => FilesInputComponent),
      multi: true,
    },
  ],
  templateUrl: './files-input.component.html',
  styleUrl: './files-input.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FilesInputComponent implements OnInit, ControlValueAccessor {
  formControl: FormControl;

  rejectedFiles: readonly TuiFileLike[] = [];
  @Input()
  accept = '*/*';

  constructor(private readonly injector: Injector) {}
  onReject(files: TuiFileLike | readonly TuiFileLike[]): void {
    this.rejectedFiles = [...this.rejectedFiles, ...(files as TuiFileLike[])];
  }

  onChange: any = () => {};
  onTouch: any = () => {};
  ngOnInit(): void {
    const ngControl = this.injector.get(NgControl);

    this.formControl = (ngControl as FormControlDirective).form;

    this.formControl.valueChanges
      .pipe(tap(() => this.formControl.markAsTouched()))
      .subscribe();
  }

  removeFile({ name }: File): void {
    this.formControl.setValue(
      this.formControl.value?.filter(
        (current: File) => current.name !== name,
      ) ?? [],
    );
  }

  clearRejected({ name }: TuiFileLike): void {
    this.rejectedFiles = this.rejectedFiles.filter(
      rejected => rejected.name !== name,
    );
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: any): void {}
}
