import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { CargoRepositoryService } from '@shared/services/cargo-repository.service';
import { switchMap } from 'rxjs';
import { PreOrderRepositoryService } from '@shared/services/pre-order-repository.service';
import {
  CargoLoadingUpdateOrder,
  SetSelectedCargoLoading,
  SetSelectedCargoLoadingOrders,
  SetSelectedCargoLoadingPreOrders,
} from '../store/cargo-loading.actions';
import { Cargo, CargoOrder } from '../../../types/cargo';

@Injectable({
  providedIn: 'root',
})
export class CargoLoadingService {
  constructor(
    private readonly store: Store,
    private readonly cargoRepository: CargoRepositoryService,
    private readonly preOrderRepository: PreOrderRepositoryService,
  ) {}

  getCargoById(id: number) {
    return this.cargoRepository.getCargoById(id);
  }

  updateCargo(cargo: Cargo) {
    return this.cargoRepository
      .updateCargo(cargo)
      .pipe(
        switchMap(updatedCargo =>
          this.store.dispatch(new SetSelectedCargoLoading(updatedCargo)),
        ),
      );
  }

  fetchOrdersByCargoId(id: number) {
    return this.cargoRepository
      .getOrdersByCargoId(id)
      .pipe(
        switchMap(orders =>
          this.store.dispatch(new SetSelectedCargoLoadingOrders(orders)),
        ),
      );
  }

  fetchPreOrdersByCargoId(id: number) {
    return this.preOrderRepository
      .getByCargoId(id)
      .pipe(
        switchMap(preOrders =>
          this.store.dispatch(new SetSelectedCargoLoadingPreOrders(preOrders)),
        ),
      );
  }

  fetchAllPreOrders() {
    return this.preOrderRepository.getAll([]);
  }

  setSelectedCargo(id: number) {
    return this.getCargoById(id).pipe(
      switchMap(payload =>
        this.store.dispatch(new SetSelectedCargoLoading(payload)),
      ),
    );
  }

  updateOrder(order: CargoOrder) {
    return this.cargoRepository
      .updateOrder(order)
      .pipe(
        switchMap(updatedOrder =>
          this.store.dispatch(new CargoLoadingUpdateOrder(updatedOrder)),
        ),
      );
  }

  moveFromLoadingToCalculation(payload: { orderIds: number[]; id: number }) {
    return this.cargoRepository.moveFromLoadingToCalculation(payload);
  }

  addPreOrders(payload: { preOrderIds: number[]; id: number }) {
    return this.cargoRepository
      .addPreOrders(payload)
      .pipe(
        switchMap(cargo =>
          this.store.dispatch(new SetSelectedCargoLoading(cargo)),
        ),
      );
  }

  deletePreOrder(payload: { preOrderId: number; id: number }) {
    return this.cargoRepository
      .deletePreOrder(payload)
      .pipe(
        switchMap(cargo =>
          this.store.dispatch(new SetSelectedCargoLoading(cargo)),
        ),
      );
  }

  recalculateAddresses(id: number) {
    return this.cargoRepository
      .recalculateAddresses(id)
      .pipe(
        switchMap(cargo =>
          this.store.dispatch(new SetSelectedCargoLoading(cargo)),
        ),
      );
  }
}
