import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import {
  catchError,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { Store } from '@ngxs/store';
import { AsyncPipe, DecimalPipe } from '@angular/common';
import { TuiButtonModule, TuiDialogService } from '@taiga-ui/core';
import {
  CdkDrag,
  CdkDragDrop,
  CdkDropList,
  moveItemInArray,
} from '@angular/cdk/drag-drop';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertService } from '@shared/services/alert.service';
import _ from 'lodash';
import { TuiLetModule } from '@taiga-ui/cdk';
import { SpaceNumberPipe } from '@shared/pipes/space-number.pipe';

import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import {
  RoutePreviewComponent,
  RoutePreviewInputData,
} from '@shared/components/google-map/route-preview/route-preview.component';
import { TransitPointService } from '@shared/services/transit-point.service';
import { CargoLoadingState } from '../../store/cargo-loading.state';
import { Cargo, CargoUnloadingAddress } from '../../../../types/cargo';
import { CargoLoadingService } from '../../services/cargo-loading.service';
import { CargoLoadingReplaceUnloadingAddressesJson } from '../../store/cargo-loading.actions';
import { getBoxAndPlaceQuantityInGroup } from '../../utils/box-place-quantity';

@Component({
  selector: 'app-unloading-addresses',
  standalone: true,
  imports: [
    AsyncPipe,
    TuiButtonModule,
    CdkDrag,
    CdkDropList,
    TuiLetModule,
    DecimalPipe,
    SpaceNumberPipe,
  ],
  templateUrl: './unloading-addresses.component.html',
  styleUrl: './unloading-addresses.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UnloadingAddressesComponent implements OnInit {
  viewRoute$ = new Subject<void>();

  cargo$ = this.store.select(CargoLoadingState.getSelectedCargo);

  unloadingAddresses$ = this.cargo$.pipe(
    map(cargo => cargo?.unloadingAddressesJson ?? []),
  );

  updateSort$ = new Subject<
    Pick<CdkDragDrop<CargoUnloadingAddress>, 'currentIndex' | 'previousIndex'>
  >();

  boxAndPlaceQuantityPerClientAddress$ = this.store
    .select(CargoLoadingState.getOrders)
    .pipe(
      map(orders => {
        const groupByClientAddress = _.groupBy(orders, preOrder => {
          return this.getKey(
            preOrder.clientJson.name,
            preOrder.clientAddressWithCoordinatesJson.address,
          );
        });
        return getBoxAndPlaceQuantityInGroup(groupByClientAddress);
      }),
    );

  constructor(
    private readonly store: Store,
    private readonly cargoLoadingService: CargoLoadingService,
    private readonly alert: AlertService,
    private readonly destroyRef: DestroyRef,
    private readonly dialogs: TuiDialogService,
    private readonly transitPointService: TransitPointService,
  ) {}

  onUpdateSort() {
    this.updateSort$
      .pipe(
        filter(params => params.currentIndex !== params.previousIndex),
        withLatestFrom(this.cargo$),
        switchMap(([{ previousIndex, currentIndex }, initialCargo]) => {
          if (!initialCargo) {
            return of(null);
          }
          const unloadingAddressesJson = structuredClone(
            initialCargo?.unloadingAddressesJson ?? [],
          );

          moveItemInArray(unloadingAddressesJson, previousIndex, currentIndex);
          this.replaceUnloadingAddresses(unloadingAddressesJson);
          const cargo: Cargo = {
            ...initialCargo,
            unloadingAddressesJson,
          };
          return this.cargoLoadingService.updateCargo(cargo).pipe(
            tap(() => {
              this.alert.showSuccess('Сортировка сохранена');
            }),
            catchError(() => {
              this.replaceUnloadingAddresses(
                initialCargo.unloadingAddressesJson ?? [],
              );
              this.alert.showError();
              return of(null);
            }),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onViewRoute() {
    this.viewRoute$
      .pipe(
        withLatestFrom(this.cargo$),
        switchMap(([, cargo]) => {
          if (!cargo) {
            return throwError(() => new Error('empty cargo'));
          }
          const load = cargo.loadingAddressesJson.map(a => [
            a.latitude,
            a.longitude,
          ]);
          const transit = cargo.transitPointsJson.map(point => [
            point.transitPoint.latitude,
            point.transitPoint.longitude,
          ]);
          const unload = cargo.unloadingAddressesJson.map(a => [
            a.latitude,
            a.longitude,
          ]);
          const data: RoutePreviewInputData = {
            routes: [...load, ...transit, ...unload]
              .filter(([lat, lng]) => lat && lng)
              .map(([lat, lng]) => [Number(lat), Number(lng)]),
          };

          return this.dialogs.open(
            new PolymorpheusComponent(RoutePreviewComponent),
            {
              size: 'auto',
              data,
            },
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  getKey(name: Nullable<string>, address: Nullable<string>) {
    return `${name}-${address}`;
  }

  replaceUnloadingAddresses(addresses: CargoUnloadingAddress[]) {
    this.store.dispatch(
      new CargoLoadingReplaceUnloadingAddressesJson(addresses),
    );
  }

  drop({ previousIndex, currentIndex }: CdkDragDrop<CargoUnloadingAddress[]>) {
    this.updateSort$.next({ previousIndex, currentIndex });
  }

  ngOnInit() {
    this.onUpdateSort();
    this.onViewRoute();
  }
}
