<div
  class="py-2"
  *tuiLet="boxAndPlaceQuantityPerClientAddress$ | async as quantity"
>
  <h2 class="font-semibold mb-4 text-[16px]">Адреса разгрузки</h2>
  <div class="flex items-center gap-x-2 rounded-xl mb-2 bg-neutral-100 py-3">
    <div class="w-3/12 pl-2">Клиент</div>
    <div class="w-3/12">Адрес</div>
    <div class="w-2/12">Коробки</div>
    <div class="w-2/12">Места</div>
  </div>
  <div class="drag-n-drop-area" cdkDropList (cdkDropListDropped)="drop($event)">
    @for (address of unloadingAddresses$ | async; track $index) {
      <div cdkDrag class="flex gap-x-2 py-2 border-b items-center">
        <div class="font-semibold w-3/12 pl-2">
          {{ address.clientName }}
        </div>
        <div class="w-3/12">{{ address.address }}</div>
        <div class="w-2/12">
          {{
            quantity?.[getKey(address.clientName, address.address)]
              ?.boxQuantity ?? 0
              | number
              | spaceNumber
          }}
        </div>
        <div class="w-2/12">
          {{
            quantity?.[getKey(address.clientName, address.address)]
              ?.cargoPlaceQuantity ?? 0
              | number
              | spaceNumber
          }}
        </div>
      </div>
    }
  </div>
</div>

<div>
  <button appearance="accent" size="s" (click)="viewRoute$.next()" tuiButton>
    Проложить маршрут
  </button>
</div>
