<div class="flex mb-2 gap-x-2 items-center">
  <div class="border w-full rounded-xl p-2">
    <div class="flex gap-x-2 mb-2">
      <div class="flex w-3/12" tuiGroup [collapsed]="true">
        <tui-input
          [formControl]="transportForm.controls.transportNumber"
          tuiTextfieldSize="m"
          >Номер ТС</tui-input
        >
        <tui-input
          [formControl]="transportForm.controls.trailerNumber"
          tuiTextfieldSize="m"
          >Номер прицепа</tui-input
        >
      </div>
      <div class="flex w-3/12" tuiGroup [collapsed]="true">
        <tui-input
          [formControl]="transportForm.controls.driverSurname"
          tuiTextfieldSize="m"
          >Водитель Фамилия</tui-input
        >
        <tui-input
          [formControl]="transportForm.controls.driverName"
          tuiTextfieldSize="m"
          >Имя</tui-input
        >
      </div>
      <tui-input
        [formControl]="transportForm.controls.driverPhone"
        class="w-2/12"
        [maskito]="phoneMask"
        tuiTextfieldSize="m"
        >Телефон</tui-input
      >
      <tui-input
        [formControl]="transportForm.controls.comment"
        class="w-2/12"
        tuiTextfieldSize="m"
        >Комментарий</tui-input
      >
    </div>
    <div class="flex gap-x-2">
      <div class="w-2/12">
        <app-input-date
          [formControl]="transportForm.controls.loadingDate"
          label="Факт. дата загрузки"
        ></app-input-date>
      </div>
      <div class="flex w-3/12" tuiGroup [collapsed]="true">
        <tui-input-number
          [formControl]="transportForm.controls.transportWeight"
          tuiTextfieldSize="m"
          >Вес тягача</tui-input-number
        >
        <tui-input-number
          [formControl]="transportForm.controls.trailerWeight"
          tuiTextfieldSize="m"
          >Полуприцепа</tui-input-number
        >
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-y-2">
    <tui-badged-content>
      @if (hasAttachment$ | async) {
        <tui-badge-notification size="s" tuiSlot="top">
          1
        </tui-badge-notification>
      }
      <button
        (click)="$event.stopPropagation(); attach.emit()"
        tuiIconButton
        size="s"
        icon="tuiIconPaperclip"
      ></button>
    </tui-badged-content>
    <button
      appearance="accent"
      size="s"
      tuiIconButton
      icon="tuiIconTrash"
      (click)="remove.emit()"
    ></button>
  </div>
</div>
