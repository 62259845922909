<div #card="tuiActiveZone" (tuiActiveZoneChange)="onZoneChange($event)">
  <div class="flex gap-x-2">
    <app-autocomplete
      class="w-2/12"
      [formControl]="orderGroupForm.controls.farmJson.controls.id"
      [items]="cargoCatalogsService.farms$ | async"
      [itemText]="'name'"
      [itemValue]="'id'"
      [displayValue]="'name'"
      [itemSearch]="'search'"
      label="Питомник"
      [labelOutside]="false"
      (valueChanged)="onFarmChange()"
    ></app-autocomplete>

    <app-autocomplete
      class="w-2/12"
      [formControl]="orderGroupForm.controls.clientJson.controls.id"
      [items]="cargoCatalogsService.clients$ | async"
      [itemText]="'name'"
      [itemValue]="'id'"
      [displayValue]="'name'"
      [itemSearch]="'search'"
      label="Мой клиент"
      [labelOutside]="false"
      (valueChanged)="onClientChange()"
    ></app-autocomplete>

    <app-autocomplete
      class="w-2/12"
      [formControl]="orderGroupForm.controls.flowerType"
      [items]="availableFlowerTypes$ | async"
      [itemSearch]="'search'"
      [itemText]="'name'"
      [itemValue]="'id'"
      [displayValue]="'name'"
      label="Группа товаров"
      [labelOutside]="false"
    ></app-autocomplete>

    <div class="w-2/12 flex items-center">
      <tui-toggle
        [formControl]="orderGroupForm.controls.imported"
        size="m"
        class="mx-2"
      ></tui-toggle>
      <span class="text-sm">Импорт</span>
    </div>
  </div>
  <div class="my-5" *tuiLet="formData$ | async as form">
    <app-order
      *ngFor="let order of ordersArray; last as last; index as index"
      [tuiActiveZoneParent]="card"
      [orderForm]="order"
      [isImport]="form?.isImport ?? false"
      [farmId]="form?.farmId ?? null"
      [clientId]="form?.clientId ?? null"
      [last]="last"
      [index]="index"
      (create)="addOrder$.next()"
      (remove)="removeOrder$.next(order.controls.id.getRawValue() ?? 0)"
    ></app-order>
    @if (!ordersArray.length) {
      <button (click)="addOrder$.next()" size="s" tuiButton icon="tuiIconPlus">
        Добавить заказ
      </button>
    }
  </div>
  <div class="flex" *tuiLet="groupedPallets$ | async as pallets">
    <table
      tuiTable
      class="plain-table w-1/2 overflow-x-auto px-2 cursor-pointer"
      [columns]="columns"
    >
      <tbody tuiTbody>
        <tr *ngFor="let pallet of pallets" tuiTr>
          @for (header of tableHeaders; track 'value') {
            <td width="100px" *tuiCell="header.value" tuiTd>
              {{ pallet[header.value] }}
            </td>
          }
          <td width="100px" *tuiCell="'cost'" tuiTd>
            {{ pallet.cost }} {{ pallet.currencyLetter }}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
