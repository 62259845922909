import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
} from '@angular/core';
import { AsyncPipe } from '@angular/common';
import {
  GoogleMap,
  MapAdvancedMarker,
  MapDirectionsRenderer,
  MapDirectionsService,
} from '@angular/google-maps';
import {
  TuiButtonModule,
  TuiDialogContext,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import { TuiInputModule } from '@taiga-ui/kit';
import { TuiLetModule } from '@taiga-ui/cdk';
import { BehaviorSubject, map, Observable, Subject, tap } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { POLYMORPHEUS_CONTEXT } from '@tinkoff/ng-polymorpheus';
import { AlertService } from '@shared/services/alert.service';
import { Environment } from '../../../../types/environment';

export type RoutePreviewInputData = {
  routes: Array<[number, number]>;
};
@Component({
  selector: 'app-route-preview',
  standalone: true,
  imports: [
    AsyncPipe,
    GoogleMap,
    MapAdvancedMarker,
    TuiButtonModule,
    TuiInputModule,
    TuiLetModule,
    TuiTextfieldControllerModule,
    MapDirectionsRenderer,
  ],
  templateUrl: './route-preview.component.html',
  styleUrl: './route-preview.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutePreviewComponent implements OnInit {
  search$ = new Subject<void>();
  loading$ = new BehaviorSubject(false);
  search = this.fb.control('', Validators.required);
  options$ = new BehaviorSubject<Nullable<google.maps.MapOptions>>({
    zoom: 4,
    center: { lat: 53.893009, lng: 27.567444 },
    fullscreenControl: false,
    keyboardShortcuts: false,
    mapTypeControl: false,
  });

  directionsResults$: Observable<google.maps.DirectionsResult | undefined>;

  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    @Inject(POLYMORPHEUS_CONTEXT)
    private readonly context: TuiDialogContext<
      google.maps.LatLngLiteral,
      RoutePreviewInputData
    >,
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    private readonly alert: AlertService,
    private readonly mapDirectionsService: MapDirectionsService,
  ) {}

  // onSubmit() {
  //   if (this.markerPosition$.value) {
  //     this.context.$implicit.next(this.markerPosition$.value);
  //     this.context.$implicit.complete();
  //   }
  // }

  get mapId() {
    return this.env.googleMapId;
  }

  ngOnInit() {
    const items = this.context.data.routes;
    if (items.length >= 2) {
      const [start, end] = items;
      const wps = items.length > 2 ? items.slice(1, -1) : [];
      const waypoints: google.maps.DirectionsWaypoint[] = wps.map(
        ([lat, lng]) => {
          return {
            location: {
              lat,
              lng,
            },
          };
        },
      );
      const request: google.maps.DirectionsRequest = {
        destination: {
          lat: start[0],
          lng: start[1],
        },
        waypoints,
        origin: { lat: end[0], lng: end[1] },
        travelMode: 'DRIVING' as google.maps.TravelMode.DRIVING,
      };
      this.directionsResults$ = this.mapDirectionsService.route(request).pipe(
        map(response => response.result),
        tap(v => console.log(v)),
      );
    }
  }
}
