import { Cargo, CargoOrder, CargoOrderGroup } from '../../../types/cargo';
import { Payment } from '../../../types/payment';

export class SetSelectedCargo {
  static readonly type = '[Cargo] Set Selected Cargo';
  constructor(public cargo: Cargo) {}
}

export class AddOrderGroup {
  static readonly type = '[Cargo] Add Order Group';
  constructor(public orderGroup: CargoOrderGroup) {}
}

export class UpdateOrderGroup {
  static readonly type = '[Cargo] Update Order Group';
  constructor(public orderGroup: CargoOrderGroup) {}
}

export class DeleteOrderGroup {
  static readonly type = '[Cargo] Delete Order Group';
  constructor(public id: number) {}
}

export class AddOrder {
  static readonly type = '[Cargo] Add Order ';
  constructor(public orderGroup: CargoOrder) {}
}

export class UpdateOrder {
  static readonly type = '[Cargo] Update Order';
  constructor(public orderGroup: CargoOrder) {}
}

export class DeleteOrder {
  static readonly type = '[Cargo] Delete Order';
  constructor(public id: number) {}
}

export class UpdatePayment {
  static readonly type = '[Cargo] Update Payment';
  constructor(public payment: Payment) {}
}
