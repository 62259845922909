<div class="py-2">
  <tui-island
    class="bg-neutral-100"
    (tuiActiveZoneChange)="zoneChanged$.next($event)"
  >
    <h2 class="font-semibold mb-4">Перевозчик</h2>
    <div class="flex w-full">
      <tui-input
        class="w-3/12"
        tuiTextfieldSize="m"
        [formControl]="carrierForm.controls.name"
        >Перевозчик</tui-input
      >
      <div class="flex mr-0 ml-auto gap-x-2 w-6/12 justify-end">
        <tui-input-number
          [formControl]="carrierForm.controls.cost"
          class="w-4/12"
          tuiTextfieldSize="m"
          >Ставка перевозчику</tui-input-number
        >
        <app-autocomplete
          class="w-2/12"
          label="Валюта"
          [labelOutside]="false"
          [itemValue]="'letterCode'"
          [itemText]="'name'"
          [items]="CURRENCIES"
          [formControl]="carrierForm.controls.currencyLetter"
        ></app-autocomplete>
        <app-autocomplete
          class="w-4/12"
          label="Вид оплаты"
          [labelOutside]="false"
          [itemValue]="'value'"
          [itemText]="'name'"
          [displayValue]="'name'"
          [items]="PAYMENT_TYPES"
          [formControl]="carrierForm.controls.paymentType"
        ></app-autocomplete>
      </div>
    </div>

    <div class="my-2">
      @for (
        transport of carrierForm.controls.transports.controls;
        track transport.controls.guid.value
      ) {
        <app-transport-item
          (attach)="
            openAttachmentsDialog$.next({
              attachmentId: transport.controls.attachmentId.getRawValue(),
              index: $index
            })
          "
          (remove)="removeTransport($index)"
          [transportForm]="transport"
        ></app-transport-item>
      }
      <button
        class="my-2"
        (click)="addTransport()"
        size="s"
        appearance="primary"
        tuiButton
        icon="tuiIconPlus"
      >
        Добавить транспорт
      </button>
    </div>
  </tui-island>
</div>
