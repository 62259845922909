<div class="w-[1200px]">
  <h2 class="font-semibold mb-4">Маршрут</h2>
  <div class="py-2">
    <google-map
      [mapId]="mapId"
      width="100%"
      height="400px"
      [options]="(options$ | async)!"
    >
      @if (directionsResults$ | async; as directionsResults) {
        <map-directions-renderer [directions]="directionsResults" />
      }
    </google-map>
  </div>
  <div class="flex justify-between">
    <!--    <button-->
    <!--      tuiButton-->
    <!--      size="m"-->
    <!--      appearance="outline"-->
    <!--      (click)="markerPosition$.next(null)"-->
    <!--    >-->
    <!--      Очистить-->
    <!--    </button>-->
    <!--    <button-->
    <!--      tuiButton-->
    <!--      size="m"-->
    <!--      appearance="primary"-->
    <!--      [disabled]="(markerPosition$ | async) === null"-->
    <!--      (click)="onSubmit()"-->
    <!--    >-->
    <!--      Выбрать-->
    <!--    </button>-->
  </div>
</div>
