import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { combineLatest, map, of, tap, withLatestFrom } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TuiTextfieldControllerModule } from '@taiga-ui/core';
import {
  countPalletsAndCarts,
  getLoadingPercent,
  getRunningMetersFromCartQuantity,
  getRunningMetersFromUSPallets,
} from '@shared/utils/cargo-calculation';
import { AsyncPipe } from '@angular/common';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { CommonStateService } from '../../services/common-state.service';
import { PreOrdersState } from '../../store/pre-orders.state';
import { ComputedPropsForm } from '../../types';
import { TrailerType } from '../../../../types/trailer';

type TotalForm = ComputedPropsForm & {
  count: FormControl<Nullable<number>>;
};
@Component({
  selector: 'app-total-info',
  standalone: true,
  imports: [
    TuiInputModule,
    ReactiveFormsModule,
    TuiTextfieldControllerModule,
    TuiInputNumberModule,
    AsyncPipe,
  ],
  templateUrl: './total-info.component.html',
  styleUrl: './total-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TotalInfoComponent implements OnInit {
  computedProperties = this.fb.group<TotalForm>({
    totalColdPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalWarmPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalBox: this.fb.control<number>({ value: 0, disabled: true }),
    count: this.fb.control({ value: 0, disabled: true }),
  });

  preOrders$ = this.store.select(PreOrdersState.getPreOrders);

  requiredVehiclesQuantity = this.fb.control({ value: 0, disabled: true });

  trailerTypeName$ = this.commonStateService.trailerType$.pipe(
    withLatestFrom(this.cargoCatalogsService.trailersTypes$),
    map(
      ([trailerType, trailerTypes]) =>
        trailerTypes.find(type => type.code === trailerType)?.name ?? '',
    ),
  );

  constructor(
    private readonly fb: FormBuilder,
    private readonly destroyRef: DestroyRef,
    protected readonly commonStateService: CommonStateService,
    private readonly cargoCatalogsService: CargoCatalogsService,
    private readonly store: Store,
  ) {}

  updateComputedProperties() {
    this.preOrders$
      .pipe(
        tap(preOrders => {
          this.computedProperties.setValue({
            ...this.commonStateService.getComputedProperties(preOrders),
            count: preOrders.length,
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateRequiredVehiclesQuantity() {
    combineLatest([
      this.preOrders$,
      of(TrailerType.REFRIGERATOR_WIDE),
      this.cargoCatalogsService.trailersTypes$,
    ])
      .pipe(
        tap(([preOrders, trailerType, trailerTypes]) => {
          if (!trailerType || !preOrders.length) {
            this.requiredVehiclesQuantity.setValue(0);
          } else {
            const trailerTypeDetails = trailerTypes.find(
              trailer => trailer.code === trailerType,
            );
            const [pallets, carts] = countPalletsAndCarts(preOrders);
            const palletsMeters = getRunningMetersFromUSPallets(pallets);
            const cartsMeters = getRunningMetersFromCartQuantity(carts);
            const meters = palletsMeters + cartsMeters;
            const requiredVehiclesQuantity =
              getLoadingPercent(meters, trailerTypeDetails?.length ?? 0) / 100;
            this.requiredVehiclesQuantity.setValue(requiredVehiclesQuantity);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.updateRequiredVehiclesQuantity();
    this.updateComputedProperties();
  }
}
