import { provideStates } from '@ngxs/store';
import { Route } from '@angular/router';
import { RouteNames } from '../../route-names';
import { CargoLoadingComponent } from './cargo-loading.component';
import { CargoLoadingState } from './store/cargo-loading.state';

export const CARGO_LOADING_ROUTE: Route = {
  title: 'Загрузка',
  path: RouteNames.CARGO_LOADING_PAGE.concat('/:id'),
  component: CargoLoadingComponent,
  providers: [provideStates([CargoLoadingState])],
};
