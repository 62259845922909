import { PreOrder } from '../../../types/pre-order';

export class SetPreOrders {
  static readonly type = '[Pre orders] Set PreOrders';
  constructor(public preOrders: PreOrder[]) {}
}

export class AddPreOrder {
  static readonly type = '[Pre orders] Add PreOrder';
  constructor(public preOrder: PreOrder) {}
}

export class UpdatePreOrder {
  static readonly type = '[Pre orders] Update PreOrder';
  constructor(public preOrder: PreOrder) {}
}

export class DeletePreOrder {
  static readonly type = '[Pre orders] Delete PreOrder';
  constructor(public id: number) {}
}
//
// export class AddOrder {
//   static readonly type = '[Cargo] Add Order ';
//   constructor(public orderGroup: CargoOrder) {}
// }
//
// export class UpdateOrder {
//   static readonly type = '[Cargo] Update Order';
//   constructor(public orderGroup: CargoOrder) {}
// }

// export class DeleteOrder {
//   static readonly type = '[Cargo] Delete Order';
//   constructor(public id: number) {}
// }
