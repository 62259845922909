import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import {
  TuiButtonModule,
  TuiDialogService,
  TuiGroupModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import {
  TUI_PROMPT,
  TuiInputModule,
  TuiInputNumberModule,
} from '@taiga-ui/kit';
import { InputDateComponent } from '@shared/components/forms/input-date/input-date.component';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { VEHICLES } from '@shared/constants/vehicles';
import { Store } from '@ngxs/store';
import {
  catchError,
  delay,
  filter,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AsyncPipe } from '@angular/common';
import { AlertService } from '@shared/services/alert.service';
import { TuiActiveZoneModule } from '@taiga-ui/cdk';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import { TrailerTypeService } from '@shared/services/trailer-type.service';
import { FormsService } from '@shared/services/forms.service';
import { Router } from '@angular/router';
import { CargoCalculationState } from '../../store/cargo-calculation.state';
import { CargoCalculationService } from '../../services/cargo-calculation.service';
import { Cargo } from '../../../../types/cargo';
import { RouteNames } from '../../../../route-names';

@Component({
  selector: 'app-main-info',
  standalone: true,
  imports: [
    TuiGroupModule,
    TuiInputModule,
    TuiInputNumberModule,
    InputDateComponent,
    AutocompleteComponent,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    AsyncPipe,
    TuiActiveZoneModule,
    TuiButtonModule,
  ],
  templateUrl: './main-info.component.html',
  styleUrl: './main-info.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainInfoComponent implements OnInit {
  update$ = new Subject<void>();
  moveToOnTheWay$ = new Subject<void>();
  form = this.fb.group({
    id: this.fb.nonNullable.control({ value: 0, disabled: true }),
    creationDate: this.fb.nonNullable.control({
      value: '',
      disabled: true,
    }),
    transportType: this.fb.control({ value: '', disabled: true }),
    trailerType: this.fb.control({ value: '', disabled: true }),
    sellerJson: this.fb.group({
      id: this.fb.control<Nullable<number>>(null),
      inn: '',
      name: '',
    }),
    uploadDate: this.fb.control({ value: '', disabled: true }),
  });

  readonly vehicles = VEHICLES;
  sellers$ = this.cargoCatalogsService.sellers$;

  trailerTypes$ = this.trailerTypeService.getAll();
  cargo$ = this.store.select(CargoCalculationState.getSelectedCargo);
  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    private readonly cargoCatalogsService: CargoCatalogsService,
    private readonly alert: AlertService,
    private readonly cargoService: CargoCalculationService,
    private readonly trailerTypeService: TrailerTypeService,
    private readonly forms: FormsService,
    private readonly dialogs: TuiDialogService,
    private readonly router: Router,
  ) {}

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
  }

  onMoveClick() {
    this.moveToOnTheWay$
      .pipe(
        switchMap(() =>
          this.dialogs
            .open<boolean>(TUI_PROMPT, {
              data: {
                content:
                  '<h1 class="font-bold text-lg">Перевести в статус "В пути" ?<h1>',
                yes: 'Подтвердить',
                no: 'Отмена',
              },
            })
            .pipe(
              filter(submit => submit),
              withLatestFrom(this.cargo$),
              switchMap(([, cargo]) =>
                this.cargoService.moveFromCalculationToOnTheWay(cargo?.id ?? 0),
              ),
              tap(() => {
                this.alert.showSuccess();
                this.router.navigate([RouteNames.KANBAN_PAGE]);
              }),
            ),
        ),
        catchError(err => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  fillSellerJson(sellerId: Nullable<string>) {
    const sellerJsonControl = this.form.controls.sellerJson;
    const seller =
      this.cargoCatalogsService.sellerMap.get(sellerId?.toString() ?? '') ??
      null;
    if (seller) {
      sellerJsonControl.patchValue(seller);
    } else {
      sellerJsonControl.reset();
      sellerJsonControl.markAsDirty();
    }
  }

  fillFormOnStateChange() {
    this.store
      .select(CargoCalculationState.getSelectedCargo)
      .pipe(
        filter(state => state !== null),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(cargo => {
        if (cargo) {
          this.form.patchValue(cargo);
        }
      });
  }

  updateOnChanges() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.form.pristine),
        withLatestFrom(this.cargo$),
        switchMap(([, cargo]) => {
          const payload: Cargo = {
            ...cargo,
            ...this.form.getRawValue(),
          } as Cargo;
          return this.cargoService.updateCargo(payload);
        }),
        tap(() => {
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.alert.showSuccess();
        }),
        catchError(err => {
          this.alert.showError(err.error?.error || 'Ошибка');
          return throwError(() => err);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.onMoveClick();
    this.updateOnChanges();
    this.fillFormOnStateChange();
  }
}
