<div #card="tuiActiveZone" (tuiActiveZoneChange)="onZoneChange($event)">
  <div class="flex gap-x-2 mb-2">
    <tui-input
      class="w-2/12"
      [formControl]="form.controls.clientJson.controls.name"
      tuiTextfieldSize="m"
      >Клиент</tui-input
    >
    <tui-input
      class="w-1/12"
      [formControl]="form.controls.clientJson.controls.discount"
      tuiTextfieldSize="m"
      tuiTextfieldPostfix="%"
      >Дисконт</tui-input
    >
    <tui-input-number
      class="w-2/12"
      [formControl]="form.controls.calculatedRateJson.controls.calculatedSum"
      decimal="always"
      [precision]="2"
      tuiTextfieldSize="m"
      [tuiTextfieldPostfix]="(currencyLetter$ | async)!"
      >Расчетная ставка</tui-input-number
    >
    <tui-input-number
      class="w-2/12"
      [formControl]="form.controls.calculatedRateJson.controls.finalSum"
      decimal="always"
      [precision]="2"
      tuiTextfieldSize="m"
      [tuiTextfieldPostfix]="(currencyLetter$ | async)!"
      >Ставка</tui-input-number
    >
    <tui-input
      class="w-5/12"
      [formControl]="form.controls.dealDetailsJson.controls.comment"
      tuiTextfieldSize="m"
      >Комментарий</tui-input
    >
  </div>
  <div class="flex gap-x-2">
    <div class="flex w-4/12" tuiGroup [collapsed]="true">
      <tui-input
        [formControl]="form.controls.dealDetailsJson.controls.recipientPhone"
        [maskito]="phoneMask"
        tuiTextfieldSize="m"
        >Телефон клиента на выгрузку</tui-input
      >
      <tui-input
        [formControl]="form.controls.dealDetailsJson.controls.recipientName"
        tuiTextfieldSize="m"
        >Имя</tui-input
      >
    </div>

    <div class="w-1/12 flex items-center">
      <app-checkbox
        [formControl]="form.controls.dealDetailsJson.controls.paid"
      ></app-checkbox>
      <span class="pl-2">Оплачено</span>
    </div>
    <app-autocomplete
      class="w-2/12"
      [formControl]="form.controls.dealDetailsJson.controls.paymentMethod"
      label="Условия оплаты"
      [labelOutside]="false"
      [itemValue]="'value'"
      [itemText]="'name'"
      [displayValue]="'name'"
      [items]="PAYMENT_METHODS"
    ></app-autocomplete>
    <app-autocomplete
      class="w-2/12"
      [formControl]="form.controls.dealDetailsJson.controls.paymentType"
      label="Вид платежа"
      [labelOutside]="false"
      [itemValue]="'value'"
      [itemText]="'name'"
      [displayValue]="'name'"
      [items]="PAYMENT_TYPES"
    ></app-autocomplete>
  </div>
</div>
