import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import { TuiButtonModule, TuiSvgModule } from '@taiga-ui/core';
import {
  catchError,
  combineLatest,
  delay,
  map,
  merge,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { AsyncPipe } from '@angular/common';
import { TuiAccordionItemComponent, TuiAccordionModule } from '@taiga-ui/kit';
import { Store } from '@ngxs/store';
import { TuiLetModule } from '@taiga-ui/cdk';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { DomSanitizer } from '@angular/platform-browser';
import { PreOrderService } from '../../services/pre-order.service';
import { PreOrdersState } from '../../store/pre-orders.state';
import { PreOrder } from '../../../../types/pre-order';
import { PreOrderComponent } from '../pre-order/pre-order.component';
import { CatalogsService } from '../../services/catalogs.service';
import { CommonStateService } from '../../services/common-state.service';

type PreparedPreOrder = {
  original: PreOrder;
  computed: {
    flowerTypeName: string;
  };
};

@Component({
  selector: 'app-pre-orders',
  standalone: true,
  imports: [
    TuiButtonModule,
    AsyncPipe,
    TuiAccordionModule,
    PreOrderComponent,
    TuiLetModule,
    TuiSvgModule,
  ],
  templateUrl: './pre-orders.component.html',
  styleUrl: './pre-orders.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PreOrdersComponent implements OnInit {
  @ViewChildren(TuiAccordionItemComponent, { read: TuiAccordionItemComponent })
  accordionItems: QueryList<TuiAccordionItemComponent>;

  create$ = new Subject<void>();
  delete$ = new Subject<number>();

  preOrders$ = this.store.select(PreOrdersState.getPreOrders);
  preparedOrderGroups$: Observable<PreparedPreOrder[]> = combineLatest([
    this.preOrders$,
  ]).pipe(
    map(([preOrders]) => {
      const flowerTypesMap = this.catalogs.flowerTypesMap;
      return preOrders.map(preOrder => {
        return {
          original: preOrder,
          computed: {
            flowerTypeName:
              flowerTypesMap.get(preOrder.flowerType ?? '')?.name ?? '',
          },
        };
      });
    }),
  );

  snowflakeUrl = 'assets/icons/snowflake.svg';
  constructor(
    private readonly preOrderService: PreOrderService,
    private readonly alert: AlertService,
    private readonly store: Store,
    private readonly catalogs: CatalogsService,
    protected readonly commonStateService: CommonStateService,
    private readonly destroyRef: DestroyRef,
    protected readonly sanitizer: DomSanitizer,
  ) {}

  onCtrlClick(event: MouseEvent, preOrder: PreparedPreOrder) {
    if (event.shiftKey) {
      event.stopPropagation();
      this.commonStateService.selectPreOrder(preOrder.original.id ?? 0);
    }
  }

  onCreatePreOrder() {
    this.create$
      .pipe(
        switchMap(() => {
          return this.preOrderService.create();
        }),
        delay(0),
        tap(() => {
          this.openLastPreOrder();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
      )
      .subscribe();
  }

  onDeletePreOrder() {
    this.delete$
      .pipe(
        switchMap(id => {
          return this.preOrderService.deleteById(id);
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
      )
      .subscribe();
  }

  openLastPreOrder() {
    const component = this.accordionItems.last;

    const clickEvent = new MouseEvent('click', {
      view: window,
      bubbles: true,
      cancelable: false,
    });
    component?.nativeFocusableElement?.dispatchEvent(clickEvent);
  }

  fetchPreOrders() {
    merge(
      this.commonStateService.selectedSortingFields$,
      this.commonStateService.refresh$,
    )
      .pipe(
        withLatestFrom(this.commonStateService.selectedSortingFields$),
        switchMap(([, fields]) => {
          const sorting = fields.map(({ value }) => value);
          return this.preOrderService.getAll(sorting);
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.fetchPreOrders();
    this.onCreatePreOrder();
    this.onDeletePreOrder();
  }
}
