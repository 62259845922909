<div class="w-[1200px]">
  <h2 class="text-lg mb-2 font-semibold">Калькулятор загрузки</h2>
  <div>
    <div class="flex gap-x-2 mb-2">
      <tui-input
        class="contrast-disabled-field w-1/3"
        tuiTextfieldSize="m"
        [formControl]="canBeLoaded"
        >Можно загрузить</tui-input
      >
    </div>
    <div class="h-[500px] overflow-y-auto">
      @for (item of items.controls; track $index) {
        <app-loading-item
          (create)="addItem()"
          (remove)="removeItem($index)"
          [loadingForm]="item"
          [last]="$last"
        ></app-loading-item>
      } @empty {
        <div class="flex h-full w-full items-center justify-center">
          <button tuiButton icon="tuiIconPlus" size="s" (click)="addItem()">
            Добавить
          </button>
        </div>
      }
    </div>
    <div class="flex gap-x-2 w-9/12">
      <tui-input
        class="w-4/12 contrast-disabled-field"
        tuiTextfieldSize="m"
        [formControl]="computedProperties.controls.totalWarmPlaces"
        >Тепло</tui-input
      >
      <tui-input
        class="w-4/12 contrast-disabled-field"
        tuiTextfieldSize="m"
        [formControl]="computedProperties.controls.totalColdPlaces"
        >Холод</tui-input
      >
      <tui-input-number
        class="w-1/12 contrast-disabled-field"
        tuiTextfieldSize="m"
        [formControl]="computedProperties.controls.totalBox"
        >Коробки</tui-input-number
      >
      <tui-input-number
        tuiTextfieldSize="m"
        class="w-2/12 contrast-disabled-field"
        [formControl]="requiredVehiclesQuantity"
        >Кол-во машин</tui-input-number
      >
    </div>
  </div>
</div>
