import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { TuiActiveZoneModule, TuiLetModule } from '@taiga-ui/cdk';
import {
  BehaviorSubject,
  catchError,
  combineLatest,
  delay,
  distinctUntilChanged,
  filter,
  map,
  merge,
  Observable,
  of,
  shareReplay,
  skip,
  startWith,
  Subject,
  switchMap,
  tap,
  withLatestFrom,
} from 'rxjs';
import { AsyncPipe } from '@angular/common';
import { AutocompleteComponent } from '@shared/components/forms/autocomplete/autocomplete.component';
import {
  TuiInputModule,
  TuiInputNumberModule,
  TuiToggleModule,
} from '@taiga-ui/kit';
import { fromFormControlValue } from '@shared/utils/observables';
import { CheckboxComponent } from '@shared/components/forms/checkbox/checkbox.component';
import {
  TuiGroupModule,
  TuiHintModule,
  TuiTextfieldControllerModule,
} from '@taiga-ui/core';
import CatalogsState from '@store/catalogs/catalogs.state';
import { Store } from '@ngxs/store';
import { CargoPlaceType } from '@shared/constants/cargo-places';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AlertService } from '@shared/services/alert.service';
import { roundDecimal } from '@shared/utils/math';
import { getCargoPlaceQuantityByTrailerType } from '@shared/constants/vehicles';
import { FormsService } from '@shared/services/forms.service';
import { CompareWithFieldDirective } from '@shared/directives/compare-with-field.directive';
import { CommonStateService } from '../../../../services/common-state.service';
import { CatalogsService } from '../../../../services/catalogs.service';
import { CargoLoadingService } from '../../../../services/cargo-loading.service';
import { CargoLoadingState } from '../../../../store/cargo-loading.state';
import { TrailerType } from '../../../../../../types/trailer';
import { CargoOrder, CargoOrderForm } from '../../../../../../types/cargo';
import { PreOrder } from '../../../../../../types/pre-order';

@Component({
  selector: 'app-order',
  standalone: true,
  imports: [
    TuiActiveZoneModule,
    AsyncPipe,
    AutocompleteComponent,
    TuiToggleModule,
    ReactiveFormsModule,
    CheckboxComponent,
    TuiGroupModule,
    TuiInputModule,
    TuiInputNumberModule,
    TuiTextfieldControllerModule,
    TuiHintModule,
    TuiLetModule,
    CompareWithFieldDirective,
  ],
  templateUrl: './order.component.html',
  styleUrl: './order.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderComponent implements OnInit {
  update$ = new Subject<void>();
  isActiveZone$ = new BehaviorSubject(false);

  hasBox$: Observable<boolean>;

  orderForm = this.fb.group<CargoOrderForm>({
    id: this.fb.control<Nullable<number>>(null),
    cargoId: this.fb.control<Nullable<number>>(null),
    box: this.fb.control<Nullable<boolean>>(null),
    packageTypeJson: this.forms.getPackageTypeJsonForm(),
    packageQuantityLimit: this.fb.control<Nullable<number>>({
      value: null,
      disabled: true,
    }),
    packageQuantity: this.fb.control<Nullable<number>>(null),
    cargoPlaceJson: this.forms.getCargoPlaceJsonForm(),
    cargoPlaceQuantity: this.fb.control<Nullable<number>>(null),
    countryLetter: this.fb.control(''),
    airWaybillNumber: this.fb.control(''),
    precooling: this.fb.control<Nullable<boolean>>(null),
    weight: this.fb.control<Nullable<number>>(null),
    buyerCode: this.fb.control(''),
    farmJson: this.forms.getFarmJsonForm(),
    clientJson: this.forms.getClientJsonForm(),
    flowerType: this.fb.control(''),
    imported: this.fb.control<Nullable<boolean>>({
      value: null,
      disabled: true,
    }),
    cold: this.fb.control<Nullable<boolean>>(null),
    clientAddressWithCoordinatesJson:
      this.forms.getClientAddressWithCoordinatesJson(),
    attachmentId: this.fb.control<Nullable<number>>(null),
    invoiceCost: this.fb.control<Nullable<number>>(null),
    invoiceCurrencyLetter: this.fb.control<Nullable<string>>(null),
    invoiceDate: this.fb.control<Nullable<string>>(null),
    invoiceNumber: this.fb.control<Nullable<string>>(null),
    orderGroupId: this.fb.control<Nullable<number>>(null),
    preOrderId: this.fb.control<Nullable<number>>(null),
    rateJson: this.forms.getRateJsonForm(),
  });

  isImport$ = fromFormControlValue(this.orderForm.controls.imported);

  availableAddresses$ =
    this.addressesWithCoordinatesJsonControl.valueChanges.pipe(
      startWith([]),
      map(() => this.addressesWithCoordinatesJsonControl.getRawValue()),
    );

  preparedPackageTypes$ = this.catalogs.packageTypes$.pipe(
    map(packageTypes =>
      packageTypes.map(packageType => ({
        ...packageType,
        itemText: `${packageType.code} - ${packageType.name}`,
      })),
    ),
  );

  availableFlowerTypes$ = combineLatest([
    this.catalogs.flowerTypes$,
    fromFormControlValue(
      this.orderForm.controls.farmJson.controls.flowerTypesJson,
    ),
  ]).pipe(
    map(([flowerTypes, farmFlowerTypeIds]) => {
      if (
        !flowerTypes.length ||
        !farmFlowerTypeIds ||
        !farmFlowerTypeIds.length
      ) {
        return [];
      }
      return flowerTypes.filter(flowerType => {
        return farmFlowerTypeIds.includes(flowerType.id.toString());
      });
    }),
  );

  buyersOfSelectedFarm$ = combineLatest([
    fromFormControlValue(this.orderForm.controls.farmJson.controls.id),
    fromFormControlValue(this.orderForm.controls.clientJson.controls.id),
    this.catalogs.buyers$,
  ]).pipe(
    map(([farmId, clientId, buyers]) => {
      if (!farmId || !clientId) {
        return [];
      }
      return buyers.filter(
        buyer => buyer.farmId === farmId && buyer.clientId === clientId,
      );
    }),
  );

  countries = this.store.selectSignal(CatalogsState.getNsiCountries);
  cargoPlaceQuantityLimit = this.fb.control<Nullable<number>>({
    value: null,
    disabled: true,
  });

  trailerType$ = this.store
    .select(CargoLoadingState.getSelectedCargo)
    .pipe(map(cargo => cargo?.trailerType));

  preOrdersMap$ = this.store.select(CargoLoadingState.getPreOrdersMap);
  parentPreOrder$: Observable<Nullable<PreOrder>> = combineLatest([
    this.preOrdersMap$,
    fromFormControlValue(this.orderForm.controls.preOrderId),
  ]).pipe(
    map(([preOrdersMap, preOrderId]) => {
      return preOrdersMap.get(preOrderId) ?? null;
    }),
    shareReplay(),
  );

  constructor(
    protected readonly catalogs: CatalogsService,
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly alert: AlertService,
    private readonly destroyRef: DestroyRef,
    private readonly commonState: CommonStateService,
    private readonly forms: FormsService,
    private readonly cargoLoadingService: CargoLoadingService,
  ) {}

  onFarmChange() {
    const id =
      this.orderForm.controls.farmJson.controls.id.value?.toString() ?? '';
    this.setFarmDetails(id);
    this.clearClientCodes();
    this.setDefaultCargoPlaceIfImport();
  }

  onAddressChange() {
    // const address =
    //   this.clientAddressWithCoordinatesJson.controls.address.value?.toString() ??
    //   '';
    //
    // if (!address) {
    //   this.clientAddressWithCoordinatesJson.reset();
    // } else {
    //   const addresses = this.addressesWithCoordinatesJsonControl.getRawValue();
    //   const details = addresses.find(a => a.address === address);
    //   if (details) {
    //     this.clientAddressWithCoordinatesJson.patchValue(details);
    //   } else {
    //     this.clientAddressWithCoordinatesJson.reset();
    //   }
    // }
    // this.clientAddressWithCoordinatesJson.markAsDirty();
  }

  onClientChange() {
    const id =
      this.orderForm.controls.clientJson.controls.id.value?.toString() ?? '';
    this.setClientName(id);
    this.clearClientCodes();
    this.clearClientAddress();
  }

  onZoneChange(focused: boolean) {
    if (!focused) {
      this.update$.next();
    }
    this.isActiveZone$.next(focused);
  }

  onUpdate() {
    this.update$
      .pipe(
        delay(0),
        filter(() => !this.orderForm.pristine),
        switchMap(() => {
          const order = this.orderForm.getRawValue() as CargoOrder;
          return this.cargoLoadingService
            .updateOrder(order)
            .pipe(
              switchMap(() =>
                this.cargoLoadingService.recalculateAddresses(
                  order?.cargoId ?? 0,
                ),
              ),
            );
        }),
        tap(() => {
          this.alert.showSuccess();
          this.orderForm.markAsUntouched();
          this.orderForm.markAsPristine();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onPackageTypeChange() {
    this.orderForm.controls.packageTypeJson.controls.code.valueChanges
      .pipe(
        tap(packageType => {
          this.updatePackageDetails(packageType);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onHasBoxChange() {
    this.hasBox$
      .pipe(
        skip(1),
        distinctUntilChanged(),
        tap(hasBox => {
          if (!hasBox) {
            this.orderForm.controls.packageTypeJson.reset();
            this.orderForm.controls.packageQuantity.reset();
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onCargoPlaceTypeChange() {
    this.cargoPlaceJson.controls.code.valueChanges
      .pipe(
        withLatestFrom(this.trailerType$),
        tap(([type, trailerType]) => {
          this.updateCargoPlaceDetails(type, trailerType as TrailerType);
          this.clearPackageDetails();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onTrailerTypeChange() {
    this.trailerType$
      .pipe(
        skip(1),
        tap(trailerType => {
          const cargoPlaceType = this.cargoPlaceJson.controls.code.value ?? '';
          const packageType = this.packageTypeJson.controls.code.value ?? '';
          this.updateCargoPlaceDetails(
            cargoPlaceType,
            trailerType as TrailerType,
          );
          this.updatePackageDetails(packageType);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  get addressesWithCoordinatesJsonControl() {
    return this.orderForm.controls.clientJson.controls
      .addressesWithCoordinatesJson;
  }

  clearClientAddress() {
    this.orderForm.controls.clientAddressWithCoordinatesJson.reset();
  }

  clearPackageDetails() {
    this.packageTypeJson.reset();
    this.orderForm.patchValue({
      packageQuantityLimit: null,
      packageQuantity: null,
    });
  }

  get farmJson() {
    return this.orderForm.controls.farmJson;
  }

  get clientJson() {
    return this.orderForm.controls.clientJson;
  }

  get cargoPlaceJson() {
    return this.orderForm.controls.cargoPlaceJson;
  }

  get packageTypeJson() {
    return this.orderForm.controls.packageTypeJson;
  }

  setFarmDetails(farmId: Nullable<string>) {
    if (!farmId) {
      this.orderForm.patchValue({
        flowerType: null,
        imported: null,
      });
      this.farmJson.reset();
    } else {
      const farm = this.catalogs.farmMap.get(farmId.toString());
      if (farm) {
        this.farmJson.patchValue(farm);
        this.orderForm.patchValue({
          flowerType: null,
          imported: farm.imported,
        });
      } else {
        this.farmJson.reset();
      }
    }
    this.farmJson.markAsDirty();
  }

  setDefaultCargoPlaceIfImport() {
    if (this.orderForm.controls.imported.getRawValue()) {
      const cargoPlace = this.catalogs.cargoPlaceMap.get(
        CargoPlaceType.PALLET_US,
      );

      if (cargoPlace) {
        this.orderForm.controls.cargoPlaceJson.patchValue(cargoPlace);
      } else {
        this.orderForm.controls.cargoPlaceJson.reset();
      }
    }
  }

  setClientName(id: Nullable<string>) {
    if (!id) {
      this.clientJson.reset();
    } else {
      const client = this.catalogs.clientMap.get(id.toString());
      if (client) {
        this.clientJson.patchValue(client);
      } else {
        this.clientJson.reset();
      }
    }
    this.clientJson.markAsDirty();
  }

  clearClientCodes() {
    this.orderForm.controls.buyerCode.setValue(null);
  }

  @Input({ required: true }) set order(order: CargoOrder) {
    this.orderForm.setValue(order, { emitEvent: false });
  }

  updatePackageDetails(packageTypeCode: Nullable<string>) {
    const packageDetails = this.catalogs.packageTypesMap.get(
      packageTypeCode || '',
    );
    if (packageDetails) {
      this.orderForm.controls.packageTypeJson.patchValue(packageDetails, {
        emitEvent: false,
      });
    } else {
      this.orderForm.controls.packageTypeJson.reset({}, { emitEvent: false });
      this.orderForm.controls.packageTypeJson.markAsDirty();
    }
    const packageCargoPlace = packageDetails?.cargoPlaceQuantityJson.find(
      place => place.cargoPlaceId === this.cargoPlaceJson.value.id,
    );
    this.orderForm.controls.packageQuantityLimit.setValue(
      packageCargoPlace?.quantity ?? 0,
    );
  }

  getCalculatedQuantity(
    packageQuantityLimit: Nullable<number>,
    packageQuantity: Nullable<number>,
  ) {
    return packageQuantityLimit && packageQuantity
      ? roundDecimal(packageQuantity / Number(packageQuantityLimit))
      : 0;
  }

  calculatePlaceQuantity() {
    merge(
      this.orderForm.controls.packageQuantityLimit.valueChanges,
      this.orderForm.controls.packageQuantity.valueChanges,
    )
      .pipe(
        map(() => {
          const { packageQuantityLimit = 0, packageQuantity = 0 } =
            this.orderForm.getRawValue();
          return [packageQuantityLimit, packageQuantity];
        }),
        tap(([packageQuantityLimit, packageQuantity]) => {
          this.orderForm.controls.cargoPlaceQuantity.setValue(
            this.getCalculatedQuantity(packageQuantityLimit, packageQuantity),
          );
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateCargoPlaceDetails(
    cargoPlaceId: Nullable<string>,
    trailerType: TrailerType,
  ) {
    const cargoPlaceDetails = this.catalogs.cargoPlaceMap.get(
      cargoPlaceId || '',
    );
    if (cargoPlaceDetails) {
      const limit = getCargoPlaceQuantityByTrailerType(
        trailerType,
        cargoPlaceDetails.quantityInTrailerJson,
      );
      this.cargoPlaceJson.patchValue(cargoPlaceDetails, { emitEvent: false });
      this.cargoPlaceQuantityLimit.setValue(limit);
    } else {
      this.cargoPlaceJson.reset(undefined, { emitEvent: false });
      this.cargoPlaceJson.markAsDirty();
      this.cargoPlaceQuantityLimit.setValue(null);
    }
  }

  get clientAddressWithCoordinatesJson() {
    return this.orderForm.controls.clientAddressWithCoordinatesJson;
  }

  setCargoPlaceLimit() {
    const cargoPlaceJson = this.cargoPlaceJson.getRawValue();
    const trailerType =
      this.store.selectSnapshot(CargoLoadingState.getSelectedCargo)
        ?.trailerType ?? '';
    const limit = getCargoPlaceQuantityByTrailerType(
      trailerType as TrailerType,
      cargoPlaceJson.quantityInTrailerJson,
    );

    this.cargoPlaceQuantityLimit.setValue(limit);
  }

  ngOnInit() {
    this.hasBox$ = this.orderForm.controls.box.valueChanges.pipe(
      startWith(this.orderForm.controls.box.getRawValue()),
      map(value => value ?? false),
      takeUntilDestroyed(this.destroyRef),
      shareReplay(),
    );
    this.onUpdate();
    this.onPackageTypeChange();
    this.calculatePlaceQuantity();
    this.onHasBoxChange();
    this.onCargoPlaceTypeChange();
    this.setCargoPlaceLimit();
    this.onTrailerTypeChange();
  }
}
