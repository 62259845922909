import { Inject, Injectable } from '@angular/core';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { HttpClient } from '@angular/common/http';
import { mimeTypes } from '@shared/constants/mime-types';
import { tap } from 'rxjs';
import {
  downloadFile,
  getFilenameFromContentDisposition,
} from '@shared/utils/download-file';
import { Environment } from '../../types/environment';
import { Attachment } from '../../types/attachment';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  API = this.env.apiUrl;
  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    private readonly http: HttpClient,
  ) {}

  attach(cargoId: number, id: Nullable<number>, formData: FormData) {
    let url = `${this.API}/attachment/attach?cargoId=${cargoId}`;
    if (id) {
      url += `&id=${id}`;
    }
    return this.http.post<number>(url, formData);
  }

  getFileListFromAttachment(cargoId: number, id: number) {
    return this.http.get<Attachment[]>(
      `${this.API}/attachment/getFileListFromAttachment?cargoId=${cargoId}&id=${id}`,
    );
  }

  download(cargoId: number, id: number) {
    return this.http
      .get(`${this.API}/attachment/download?cargoId=${cargoId}&id=${id}`, {
        responseType: 'blob',
        observe: 'response',
      })
      .pipe(
        tap(res => {
          if (res.body) {
            const name = getFilenameFromContentDisposition(
              res.headers.get('content-disposition'),
            );
            downloadFile({
              data: res.body,
              type: mimeTypes.APPLICATION_ZIP,
              name,
            });
          }
        }),
      );
  }

  delete(cargoId: number, id: number) {
    return this.http.post(
      `${this.API}/attachment/delete?cargoId=${cargoId}&id=${id}`,
      null,
    );
  }
}
