<tui-accordion
  *tuiLet="commonStateService.selectedPreOrdersMap$ | async as selected"
  [rounded]="false"
>
  @for (
    preOrder of preparedOrderGroups$ | async;
    track preOrder.original.id;
    let index = $index
  ) {
    <tui-accordion-item
      (click)="onCtrlClick($event, preOrder)"
      borders="top-bottom"
      [showArrow]="false"
      [class.selected]="selected?.has(preOrder.original.id ?? 0)"
    >
      <div class="flex gap-x-2" (click)="onCtrlClick($event, preOrder)">
        <div class="w-[40px] rounded-xl bg-purple-200 text-center">
          {{ index + 1 }}
        </div>
        <div class="w-[250px] overflow-hidden disappearing">
          {{ preOrder.original.clientJson.name }}
        </div>

        <div class="w-[250px] overflow-hidden disappearing">
          {{ preOrder.original.clientAddressWithCoordinatesJson.address }}
        </div>
        <div class="w-[250px] overflow-hidden disappearing">
          {{ preOrder.original.farmJson.name }}
        </div>

        <div class="w-[150px] overflow-hidden disappearing">
          {{ preOrder.computed.flowerTypeName }}
        </div>
        <div class="w-[150px] overflow-hidden disappearing">
          @if (preOrder.original.cold) {
            <tui-svg [src]="snowflakeUrl"></tui-svg>
          }
        </div>
        <div class="w-[150px] overflow-hidden disappearing">
          {{ preOrder.original.buyerCode }}
        </div>
        <div class="w-[150px] overflow-hidden disappearing">
          {{ preOrder.original.packageQuantity ?? 0 }} BX
        </div>
        <div class="w-[150px] overflow-hidden disappearing">
          {{ preOrder.original.cargoPlaceJson.name }}
          {{ preOrder.original.cargoPlaceQuantity }}
        </div>
        <!--        <div class="w-[140px] disappearing">-->
        <!--          {{ preOrder.computed.boxCount }}-->
        <!--        </div>-->
        <!--        <div class="w-[140px] disappearing">-->
        <!--          {{ orderGroup.computed.cartCount }}-->
        <!--        </div>-->
        <!--        <div class="w-[140px] disappearing">-->
        <!--          {{ orderGroup.computed.palletCount }}-->
        <!--        </div>-->
        <div class="w-50px">
          <button
            size="s"
            appearance="icon"
            (click)="
              $event.stopPropagation(); delete$.next(preOrder.original.id ?? 0)
            "
            tuiIconButton
            icon="tuiIconTrash"
          ></button>
        </div>
      </div>

      <ng-template tuiAccordionItemContent>
        <app-pre-order [preOrder]="preOrder.original"></app-pre-order>
      </ng-template>
    </tui-accordion-item>
  }
</tui-accordion>

<div class="my-4">
  <button size="s" icon="tuiIconPlus" tuiButton (click)="create$.next()">
    Добавить предзаказ
  </button>
</div>
