export enum PaymentType {
  CASH = 'CASH',
  SWIFT = 'SWIFT',
  CRYPT = 'CRYPT',
}

export const PAYMENT_TYPES = [
  {
    name: 'Наличные',
    value: PaymentType.CASH,
  },
  {
    name: 'SWIFT',
    value: PaymentType.SWIFT,
  },
  {
    name: 'Крипто',
    value: PaymentType.CRYPT,
  },
];

export enum PaymentMethod {
  PRE_PAYMENT = 'PRE_PAYMENT',
  POST_PAYMENT = 'POST_PAYMENT',
}

export const PAYMENT_METHODS = [
  {
    name: 'Предоплата',
    value: PaymentMethod.PRE_PAYMENT,
  },
  {
    name: 'По факту',
    value: PaymentMethod.POST_PAYMENT,
  },
];
