import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngxs/store';
import { TuiInputModule, TuiInputNumberModule } from '@taiga-ui/kit';
import { TuiButtonModule, TuiTextfieldControllerModule } from '@taiga-ui/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  catchError,
  combineLatest,
  filter,
  map,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import {
  countPalletsAndCarts,
  getLoadingPercent,
  getRunningMetersFromCartQuantity,
  getRunningMetersFromUSPallets,
} from '@shared/utils/cargo-calculation';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';

import { AlertService } from '@shared/services/alert.service';
import { getComputedProperties } from '@shared/components/loading-calculator/utils';
import { CargoLoadingState } from '../../store/cargo-loading.state';
import { CommonStateService } from '../../services/common-state.service';
import { ComputedPropsForm } from '../../types';
import { DriversReportService } from '../../services/drivers-report.service';
import { Cargo } from '../../../../types/cargo';
import { CargoLoadingService } from '../../services/cargo-loading.service';

@Component({
  selector: 'app-cargo-characteristics',
  standalone: true,
  imports: [
    TuiInputModule,
    TuiTextfieldControllerModule,
    ReactiveFormsModule,
    TuiInputNumberModule,
    TuiButtonModule,
  ],
  templateUrl: './cargo-characteristics.component.html',
  styleUrl: './cargo-characteristics.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CargoCharacteristicsComponent implements OnInit {
  @ViewChild('container') container: ElementRef;
  generateDriversReport$ = new Subject<void>();
  computedProperties = this.fb.group<ComputedPropsForm>({
    totalColdPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalWarmPlaces: this.fb.control<string>({ value: '', disabled: true }),
    totalBox: this.fb.control<number>({ value: 0, disabled: true }),
  });

  vehicleLoadingPercent = this.fb.control({ value: 0, disabled: true });

  grossWeight = this.fb.control<Nullable<number>>(null, { updateOn: 'blur' });

  orders$ = this.store.select(CargoLoadingState.getOrders);
  cargo$ = this.store.select(CargoLoadingState.getSelectedCargo);
  trailerType$ = this.cargo$.pipe(map(cargo => cargo?.trailerType));

  constructor(
    private readonly fb: FormBuilder,
    private readonly store: Store,
    private readonly destroyRef: DestroyRef,
    protected readonly commonStateService: CommonStateService,
    private readonly cargoCatalogsService: CargoCatalogsService,
    private readonly driversReport: DriversReportService,
    private readonly alert: AlertService,
    private readonly cargoLoadingService: CargoLoadingService,
  ) {}

  onGenerateDriversReport() {
    this.generateDriversReport$
      .pipe(
        withLatestFrom(this.cargo$, this.orders$),
        map(([, cargo, orders]) => ({
          cargo,
          orders,
        })),
        filter(data => !!data.cargo),
        switchMap(({ cargo, orders }) =>
          this.driversReport.generate(cargo as Cargo, orders),
        ),
        catchError(e => {
          console.log(e);
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateComputedProperties() {
    this.orders$
      .pipe(
        tap(orders => {
          this.computedProperties.setValue({
            ...getComputedProperties(orders),
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateVehicleLoadingPercent() {
    combineLatest([this.orders$, this.trailerType$])
      .pipe(
        withLatestFrom(this.cargoCatalogsService.trailersTypes$),
        tap(([[preOrders, trailerType], trailerTypes]) => {
          if (!trailerType || !preOrders.length) {
            this.vehicleLoadingPercent.setValue(0);
          } else {
            const trailerTypeDetails = trailerTypes.find(
              trailer => trailer.code === trailerType,
            );
            const [pallets, carts] = countPalletsAndCarts(preOrders);
            const palletsMeters = getRunningMetersFromUSPallets(pallets);
            const cartsMeters = getRunningMetersFromCartQuantity(carts);
            const meters = palletsMeters + cartsMeters;
            this.vehicleLoadingPercent.setValue(
              getLoadingPercent(meters, trailerTypeDetails?.length ?? 0),
            );
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  fillFormOnStateChange() {
    this.cargo$
      .pipe(
        tap(cargo => {
          this.grossWeight.setValue(cargo?.grossWeight ?? null, {
            emitEvent: false,
          });
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  updateOnChanges() {
    this.grossWeight.valueChanges
      .pipe(
        filter(() => !this.grossWeight.pristine),
        withLatestFrom(this.cargo$),
        switchMap(([grossWeight, cargo]) => {
          const payload: Cargo = {
            ...cargo,
            grossWeight,
          } as Cargo;
          return this.cargoLoadingService.updateCargo(payload);
        }),
        tap(() => {
          this.grossWeight.markAsPristine();
          this.grossWeight.markAsUntouched();
          this.alert.showSuccess();
        }),
        catchError(err => {
          this.alert.showError(err.error?.error || 'Ошибка');
          return throwError(() => err);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  ngOnInit() {
    this.onGenerateDriversReport();
    this.updateComputedProperties();
    this.updateVehicleLoadingPercent();
    this.fillFormOnStateChange();
    this.updateOnChanges();
    this.computedProperties.disable();
  }
}
