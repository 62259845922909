<div class="flex mb-2 items-center gap-x-2" cdkDrag>
  <app-autocomplete
    class="w-10/12"
    [formControl]="transitPointForm.controls.transitPoint.controls.id"
    [items]="transitPoints$ | async"
    [itemText]="'itemText'"
    [itemValue]="'id'"
    [displayValue]="'itemText'"
    [itemSearch]="'itemSearch'"
    (valueChanged)="fillTransitPointDetails$.next($event)"
  ></app-autocomplete>
  <app-checkbox
    [formControl]="transitPointForm.controls.displayOnRoute"
    [tuiHint]="hint"
  ></app-checkbox>
  <ng-template #hint> Отображать на маршруте </ng-template>
  <button
    (click)="remove.emit()"
    class="mr-0 ml-auto"
    appearance="accent"
    tuiIconButton
    size="s"
    icon="tuiIconTrash"
  ></button>
</div>
