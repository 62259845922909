import {
  TuiAlertModule,
  TuiButtonModule,
  TuiDialogModule,
  TuiRootModule,
} from '@taiga-ui/core';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { APP_ENVIRONMENT } from '@shared/providers/environment.provider';
import { DOCUMENT } from '@angular/common';
import { FooterComponent } from './core/footer/footer.component';
import { NavbarComponent } from './core/navbar/navbar.component';
import { Environment } from './types/environment';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    NavbarComponent,
    FooterComponent,
    TuiRootModule,
    TuiDialogModule,
    TuiAlertModule,
    TuiButtonModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  title = 'greenland-ui';
  readonly WORKER_NOT_FOUND = 'Worker not found. Use HTTPS connection!';
  // workerMessage$ = defer(() => {
  //   const worker = this.navigator?.serviceWorker ?? null;
  //   return worker
  //     ? fromEvent<MessageEvent>(worker, 'message')
  //     : throwError(() => new Error(this.WORKER_NOT_FOUND));
  // });
  //
  // workerReady$ = defer(() => {
  //   const worker = this.navigator?.serviceWorker ?? null;
  //   return worker
  //     ? fromPromise(worker.ready)
  //     : throwError(() => new Error(this.WORKER_NOT_FOUND));
  // }).pipe(
  //   filter(s => s !== null),
  //   takeUntilDestroyed(this.destroyRef),
  // );

  // constructor(
  //   private readonly destroyRef: DestroyRef,
  //   private readonly swUpdate: SwUpdate,
  //   @Inject(NAVIGATOR) private readonly navigator: Navigator,
  //   @Inject(WINDOW) private readonly window: Window,
  //   @Inject(TuiDialogService) private readonly dialogs: TuiDialogService,
  // ) {}

  constructor(
    @Inject(APP_ENVIRONMENT) private readonly env: Environment,
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly renderer2: Renderer2,
  ) {}

  // showNotification() {
  //   return this.dialogs.open(
  //     new PolymorpheusComponent(VersionAvailableComponent),
  //     {
  //       size: 's',
  //       closeable: false,
  //       dismissible: false,
  //     },
  //   );
  // }

  // checkNewVersion() {
  //   this.swUpdate?.versionUpdates
  //     .pipe(
  //       filter((evt): evt is VersionReadyEvent => evt.type === 'VERSION_READY'),
  //       switchMap(() => this.showNotification()),
  //       takeUntilDestroyed(this.destroyRef),
  //     )
  //     .subscribe();
  // }

  // checkIfDocumentAlreadyOpened() {
  //   this.workerReady$.subscribe(worker => {
  //     worker?.active?.postMessage({ type: 'APP_INSTANCE_CREATED' });
  //   });
  // }

  // closeWindowIfDocumentAlreadyOpened() {
  //   this.workerMessage$.subscribe(event => {
  //     const { type, message } = event.data;
  //     if (type === 'DOCUMENT_ALREADY_OPENED') {
  //       this.window.alert(message);
  //       this.window.close();
  //     }
  //   });
  // }

  ngOnInit() {
    // this.checkIfDocumentAlreadyOpened();
    // this.closeWindowIfDocumentAlreadyOpened();
    // this.checkNewVersion();
  }
}
