import {
  ChangeDetectorRef,
  DestroyRef,
  Directive,
  HostBinding,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { FormControl, FormControlDirective, NgControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  BehaviorSubject,
  startWith,
  tap,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs';

type CompareWith = Nullable<number | boolean | string> | undefined;
@Directive({
  selector: '[formControl][appCompareWithField]',
  standalone: true,
})
export class CompareWithFieldDirective implements OnInit {
  compareWith$ = new BehaviorSubject<CompareWith>(undefined);
  @HostBinding('class.field-has-changes') hasChanges = true;
  formControl: FormControl;
  @Input() nullableBoolean = false;
  constructor(
    private readonly injector: Injector,
    private readonly destroyRef: DestroyRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  @Input() set appCompareWithField(value: CompareWith) {
    this.compareWith$.next(value);
  }

  ngOnInit() {
    const ngControl = this.injector.get(NgControl);

    this.formControl = (ngControl as FormControlDirective).form;

    combineLatest([
      this.formControl.valueChanges.pipe(
        debounceTime(400),
        distinctUntilChanged(),
        startWith(this.formControl.value),
      ),
      this.compareWith$,
    ])
      .pipe(
        tap(([value, compareWith]) => {
          if (this.nullableBoolean) {
            this.hasChanges = (value ?? false) !== (compareWith ?? false);
          } else {
            this.hasChanges = value !== compareWith;
          }
          this.cdr.markForCheck();
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }
}
