import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Subject } from 'rxjs';
import { roundDecimal } from '@shared/utils/math';
import { PreOrder } from '../../../types/pre-order';
import { SortingField } from '../types';

@Injectable({
  providedIn: 'root',
})
export class CommonStateService {
  selectedSortingFields$ = new BehaviorSubject<SortingField[]>([]);
  trailerType$ = new BehaviorSubject<Nullable<string>>(null);
  selectedPreOrders$ = new BehaviorSubject<number[]>([]);
  selectedPreOrdersMap$ = this.selectedPreOrders$.pipe(
    map(ids => new Map(ids.map(id => [id, true]))),
  );

  refresh$ = new Subject<void>();

  selectSortingField(field: SortingField) {
    const ids = this.selectedSortingFields$.value.map(field => field.value);
    if (ids.includes(field.value)) {
      const filtered = this.selectedSortingFields$.value.filter(
        f => f.value !== field.value,
      );
      this.selectedSortingFields$.next(filtered);
    } else {
      this.selectedSortingFields$.next([
        ...this.selectedSortingFields$.value,
        field,
      ]);
    }
  }

  selectPreOrder(id: number) {
    const ids = this.selectedPreOrders$.value;
    if (ids.includes(id)) {
      const filtered = ids.filter(value => value !== id);
      this.selectedPreOrders$.next(filtered);
    } else {
      this.selectedPreOrders$.next([...ids, id]);
    }
  }

  getComputedProperties(items: PreOrder[]) {
    const total: {
      totalColdPlaces: Record<string, number>;
      totalWarmPlaces: Record<string, number>;
      totalBox: number;
    } = {
      totalColdPlaces: {},
      totalWarmPlaces: {},
      totalBox: 0,
    };
    const result = items.reduce((previousValue, currentValue) => {
      const coldPlaces = currentValue.cold
        ? currentValue.cargoPlaceQuantity ?? 0
        : 0;
      const warmPlaces = currentValue.cold
        ? 0
        : currentValue.cargoPlaceQuantity ?? 0;

      const cargoPlaceName = currentValue.cargoPlaceJson.name ?? '(без типа)';

      const totalBox =
        previousValue.totalBox + (currentValue.packageQuantity ?? 0);
      return {
        totalColdPlaces: currentValue.cold
          ? {
              ...previousValue.totalColdPlaces,
              [cargoPlaceName]:
                (previousValue.totalColdPlaces[cargoPlaceName] ?? 0) +
                coldPlaces,
            }
          : {
              ...previousValue.totalColdPlaces,
            },
        totalWarmPlaces: !currentValue.cold
          ? {
              ...previousValue.totalWarmPlaces,
              [cargoPlaceName]:
                (previousValue.totalWarmPlaces[cargoPlaceName] ?? 0) +
                warmPlaces,
            }
          : {
              ...previousValue.totalWarmPlaces,
            },
        totalBox,
      };
    }, total);

    return {
      totalBox: roundDecimal(result.totalBox),
      totalColdPlaces: Object.entries(result.totalColdPlaces)
        .map(([place, quantity]) => `${roundDecimal(quantity)} ${place}`)
        .join(' / '),
      totalWarmPlaces: Object.entries(result.totalWarmPlaces)
        .map(([place, quantity]) => `${roundDecimal(quantity)} ${place}`)
        .join(' / '),
    };
  }
}
