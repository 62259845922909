import { PreOrder } from '../../../types/pre-order';

export function getBoxAndPlaceQuantityInGroup(
  groups: Record<string, PreOrder[]>,
) {
  const total: Record<
    string,
    { boxQuantity: number; cargoPlaceQuantity: number }
  > = {};

  return Object.entries(groups).reduce((previousValue, [key, value]) => {
    const { boxQuantity, cargoPlaceQuantity } = value.reduce(
      (p, c) => {
        return {
          boxQuantity: p.boxQuantity + (c?.packageQuantity ?? 0),
          cargoPlaceQuantity:
            p.cargoPlaceQuantity + (c?.cargoPlaceQuantity ?? 0),
        };
      },
      { boxQuantity: 0, cargoPlaceQuantity: 0 },
    );
    return {
      ...previousValue,
      [key]: {
        boxQuantity,
        cargoPlaceQuantity,
      },
    };
  }, total);
}
