import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  OnInit,
} from '@angular/core';
import {
  TuiButtonModule,
  TuiDialogService,
  TuiSvgModule,
} from '@taiga-ui/core';
import {
  catchError,
  map,
  Observable,
  of,
  Subject,
  switchMap,
  tap,
  throwError,
  withLatestFrom,
} from 'rxjs';
import { AlertService } from '@shared/services/alert.service';
import { AsyncPipe } from '@angular/common';
import { TuiAccordionModule } from '@taiga-ui/kit';
import { Store } from '@ngxs/store';
import { TuiLetModule } from '@taiga-ui/cdk';

import { PolymorpheusComponent } from '@tinkoff/ng-polymorpheus';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  TuiBadgedContentModule,
  TuiBadgeNotificationModule,
} from '@taiga-ui/experimental';
import {
  ItemsSelectComponent,
  ItemsSelectInputData,
} from '@shared/components/items-select/items-select.component';
import { CargoCatalogsService } from '@shared/services/cargo-catalogs.service';
import _ from 'lodash';
import { PreOrder } from '../../../../types/pre-order';
import { CargoLoadingState } from '../../store/cargo-loading.state';
import { CargoLoadingService } from '../../services/cargo-loading.service';
import {
  Cargo,
  CargoLoadingAddress,
  CargoOrder,
  CargoUnloadingAddress,
} from '../../../../types/cargo';
import { OrderComponent } from './components/order/order.component';
import { OrderAttachmentsComponent } from './components/order-attachments/order-attachments.component';
import { CommonStateService } from '../../services/common-state.service';

type PreparedPreOrder = {
  original: CargoOrder;
  computed: {
    flowerTypeName: string;
  };
};

type CreateDialogPreOrder = PreOrder & {
  flowerTypeName: string;
  clientName: string;
  farmName: string;
};

@Component({
  selector: 'app-orders',
  standalone: true,
  imports: [
    TuiButtonModule,
    AsyncPipe,
    TuiAccordionModule,
    TuiLetModule,
    TuiSvgModule,
    TuiBadgeNotificationModule,
    TuiBadgedContentModule,
    OrderComponent,
  ],
  templateUrl: './orders.component.html',
  styleUrl: './orders.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrdersComponent implements OnInit {
  create$ = new Subject<void>();
  delete$ = new Subject<number>();

  openAttachmentsDialog$ = new Subject<{
    order: CargoOrder;
    index: number;
  }>();

  orders$ = this.store.select(CargoLoadingState.getOrders);

  preparedOrderGroups$: Observable<PreparedPreOrder[]> = this.orders$.pipe(
    map(orders => {
      const flowerTypesMap = this.cargoCatalogsService.flowerTypesMap;
      return orders.map(order => {
        return {
          original: order,
          computed: {
            flowerTypeName:
              flowerTypesMap.get(order.flowerType ?? '')?.name ?? '',
          },
        };
      });
    }),
  );

  cargo$ = this.store.select(CargoLoadingState.getSelectedCargo);

  constructor(
    private readonly alert: AlertService,
    private readonly store: Store,
    private readonly cargoCatalogsService: CargoCatalogsService,
    protected readonly cargoLoadingService: CargoLoadingService,
    private readonly destroyRef: DestroyRef,
    private readonly dialogs: TuiDialogService,
    private readonly commonStateService: CommonStateService,
  ) {}

  onDeleteOrders() {
    this.delete$
      .pipe(
        withLatestFrom(this.orders$, this.cargo$),
        switchMap(([index, orders, cargo]) => {
          if (!cargo) {
            return throwError(() => new Error('empty cargo'));
          }
          const cargoOrder = orders[index];
          return this.cargoLoadingService
            .deletePreOrder({
              preOrderId: cargoOrder.preOrderId ?? 0,
              id: cargo.id ?? 0,
            })
            .pipe(
              switchMap(() =>
                this.cargoLoadingService.fetchOrdersByCargoId(cargo.id),
              ),
            );
        }),
        tap(() => {
          this.alert.showSuccess();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
      )
      .subscribe();
  }

  onAddOrders() {
    this.create$
      .pipe(
        switchMap(() =>
          this.cargoLoadingService.fetchAllPreOrders().pipe(
            map(preOrders => {
              const flowerTypesMap = this.cargoCatalogsService.flowerTypesMap;
              const value: CreateDialogPreOrder[] = preOrders.map(preOrder => {
                return {
                  ...preOrder,
                  clientName: preOrder.clientJson?.name ?? '',
                  farmName: preOrder.farmJson?.name ?? '',
                  flowerTypeName:
                    flowerTypesMap.get(preOrder.flowerType ?? '')?.name ?? '',
                  cargoPlaceQuantity: preOrder.cargoPlaceQuantity ?? 0,
                  packageQuantity: preOrder.packageQuantity ?? 0,
                };
              });
              return value;
            }),
          ),
        ),
        switchMap(preOrders => {
          const data: ItemsSelectInputData<CreateDialogPreOrder> = {
            headers: [
              {
                name: 'id',
                value: 'id',
              },
              {
                name: 'Клиент',
                value: 'clientName',
              },
              {
                name: 'Питомник',
                value: 'farmName',
              },
              {
                name: 'Группа товаров',
                value: 'flowerTypeName',
              },
              {
                name: 'BX',
                value: 'packageQuantity',
              },
              {
                name: 'Места',
                value: 'cargoPlaceQuantity',
              },
            ],
            identity: 'id',
            items: preOrders,
            title: 'Выбор предзаказа',
          };
          return this.dialogs.open<CreateDialogPreOrder[]>(
            new PolymorpheusComponent(ItemsSelectComponent),
            {
              size: 'auto',
              data,
            },
          );
        }),
        withLatestFrom(this.cargo$),
        switchMap(([createDialogPreOrders, cargo]) => {
          const preOrderIds: number[] = createDialogPreOrders.map(
            c => c.id ?? 0,
          );
          return this.cargoLoadingService
            .addPreOrders({
              preOrderIds,
              id: cargo?.id ?? 0,
            })
            .pipe(
              switchMap(() =>
                this.cargoLoadingService.fetchOrdersByCargoId(cargo?.id ?? 0),
              ),
            );
        }),
        tap(() => {
          this.commonStateService.refreshPreOrders$.next();
          this.alert.showSuccess();
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  onAttachments() {
    this.openAttachmentsDialog$
      .pipe(
        withLatestFrom(this.cargo$),
        switchMap(([{ order, index }, cargo]) => {
          const data = {
            cargoId: cargo?.id ?? 0,
            order,
          };
          return this.dialogs
            .open<CargoOrder>(
              new PolymorpheusComponent(OrderAttachmentsComponent),
              {
                size: 'auto',
                data,
              },
            )
            .pipe(
              map(updatedOrder => {
                return {
                  updatedOrder,
                  order,
                  index,
                };
              }),
            );
        }),
        withLatestFrom(this.orders$),
        switchMap(([{ updatedOrder }]) => {
          return this.cargoLoadingService.updateOrder(updatedOrder).pipe(
            tap(() => {
              this.alert.showSuccess();
            }),
          );
        }),
        catchError(() => {
          this.alert.showError();
          return of(null);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe();
  }

  removeAddress(
    order: CargoOrder,
    orders: CargoOrder[],
    cargo: Cargo,
  ): {
    loadingAddresses: CargoLoadingAddress[];
    unloadingAddresses: CargoUnloadingAddress[];
  } {
    const restPreOrders = orders.filter(p => p.id !== order.id);
    const restLoadingAddresses = _.groupBy(restPreOrders, preOrder => {
      return preOrder.farmJson.name ?? '';
    });
    const restUnloadingAddresses = _.groupBy(restPreOrders, preOrder => {
      return `${preOrder.clientJson.name ?? ''}-${preOrder.clientAddressWithCoordinatesJson.address ?? ''}`;
    });

    const newLoadingAddresses: CargoLoadingAddress[] =
      cargo.loadingAddressesJson.filter(address => {
        const key = address.farmName ?? '';
        return restLoadingAddresses[key] !== undefined;
      });
    const newUnloadingAddresses: CargoUnloadingAddress[] =
      cargo.unloadingAddressesJson.filter(address => {
        const key = `${address.clientName ?? ''}-${address.address ?? ''}`;
        return restUnloadingAddresses[key] !== undefined;
      });
    return {
      loadingAddresses: newLoadingAddresses,
      unloadingAddresses: newUnloadingAddresses,
    };
  }

  ngOnInit() {
    this.onAttachments();
    this.onAddOrders();
    this.onDeleteOrders();
  }
}
