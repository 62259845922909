import { roundDecimal } from '@shared/utils/math';
import { LoadingItem } from '@shared/components/loading-calculator/types';
import { CargoPlaceType } from '@shared/constants/cargo-places';
import { PreOrder } from '../../../types/pre-order';
import { CargoOrder } from '../../../types/cargo';

export function getComputedProperties(
  items: PreOrder[] | CargoOrder[] | LoadingItem[],
) {
  const total: {
    totalColdPlaces: Record<string, number>;
    totalWarmPlaces: Record<string, number>;
    totalBox: number;
  } = {
    totalColdPlaces: {},
    totalWarmPlaces: {},
    totalBox: 0,
  };
  const result = items.reduce((previousValue, currentValue) => {
    const coldPlaces = currentValue.cold
      ? currentValue.cargoPlaceQuantity ?? 0
      : 0;
    const warmPlaces = currentValue.cold
      ? 0
      : currentValue.cargoPlaceQuantity ?? 0;

    const cargoPlaceName = currentValue.cargoPlaceJson.name ?? '(без типа)';

    const totalBox =
      previousValue.totalBox + (currentValue.packageQuantity ?? 0);
    return {
      totalColdPlaces: currentValue.cold
        ? {
            ...previousValue.totalColdPlaces,
            [cargoPlaceName]:
              (previousValue.totalColdPlaces[cargoPlaceName] ?? 0) + coldPlaces,
          }
        : {
            ...previousValue.totalColdPlaces,
          },
      totalWarmPlaces: !currentValue.cold
        ? {
            ...previousValue.totalWarmPlaces,
            [cargoPlaceName]:
              (previousValue.totalWarmPlaces[cargoPlaceName] ?? 0) + warmPlaces,
          }
        : {
            ...previousValue.totalWarmPlaces,
          },
      totalBox,
    };
  }, total);

  return {
    totalBox: roundDecimal(result.totalBox),
    totalColdPlaces: Object.entries(result.totalColdPlaces)
      .map(([place, quantity]) => `${roundDecimal(quantity)} ${place}`)
      .join(' / '),
    totalWarmPlaces: Object.entries(result.totalWarmPlaces)
      .map(([place, quantity]) => `${roundDecimal(quantity)} ${place}`)
      .join(' / '),
  };
}

export function countPalletsAndCarts(preOrders: LoadingItem[]) {
  return preOrders.reduce(
    ([pallets, carts], current) => {
      const cart = current.cargoPlaceJson.code === CargoPlaceType.CART;
      const quantity = current.cargoPlaceQuantity ?? 0;
      return [
        cart ? pallets : pallets + quantity,
        cart ? carts + quantity : carts,
      ];
    },
    [0, 0],
  );
}
