<div class="flex gap-x-2 mb-2 bg-neutral-100 rounded-xl p-2 items-center">
  <app-autocomplete
    label="Грузовое место"
    [labelOutside]="false"
    class="tui-group__inherit-item w-2/12 tui-group__auto-width-item"
    componentClass="tui-group__inherit-item"
    [formControl]="loadingForm.controls.cargoPlaceJson.controls.code"
    [items]="catalogs.cargoPlaces$ | async"
    [itemValue]="'code'"
    [itemText]="'name'"
    [displayValue]="'name'"
    [itemSearch]="'search'"
  ></app-autocomplete>
  <div class="flex items-center">
    <app-checkbox
      [formControl]="loadingForm.controls.box"
      [tuiHint]="box"
    ></app-checkbox>
    <ng-template #box> Коробки</ng-template>
  </div>
  <div class="flex w-5/12 gap-x-2">
    @if (hasBox$ | async) {
      <div class="w-9/12" tuiGroup [collapsed]="true">
        <app-autocomplete
          label="Код"
          class="tui-group__inherit-item w-9/12 tui-group__auto-width-item"
          componentClass="tui-group__inherit-item"
          [labelOutside]="false"
          [formControl]="loadingForm.controls.packageTypeJson.controls.code"
          [items]="preparedPackageTypes$ | async"
          [itemValue]="'code'"
          [itemText]="'itemText'"
          [displayValue]="'itemText'"
          [itemSearch]="'search'"
        ></app-autocomplete>
        <tui-input-number
          [formControl]="loadingForm.controls.packageQuantityLimit"
          class="w-3/12 tui-group__auto-width-item"
          tuiTextfieldSize="m"
          >Лимит</tui-input-number
        >
      </div>
      <tui-input-number
        [precision]="0"
        class="w-2/12"
        tuiTextfieldSize="m"
        [formControl]="loadingForm.controls.packageQuantity"
        >Кол-во упаковки</tui-input-number
      >
    }
  </div>

  <tui-input-number
    tuiTextfieldSize="m"
    [formControl]="loadingForm.controls.cargoPlaceQuantity"
    >Кол-во</tui-input-number
  >
  <div class="w-1/12 flex items-center">
    <app-checkbox [formControl]="loadingForm.controls.cold"></app-checkbox>
    <span class="px-2">Холод</span>
  </div>
  <div class="flex gap-x-2 mr-0 ml-auto">
    <button
      tuiIconButton
      icon="tuiIconTrash"
      size="s"
      appearance="icon"
      (click)="remove.emit()"
    ></button>
    <button
      tuiIconButton
      icon="tuiIconPlus"
      size="s"
      (click)="create.emit()"
    ></button>
  </div>
</div>
