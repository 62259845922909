<div>
  <h2 class="mb-2 font-semibold text-lg">Расчет ставки</h2>
  <div class="flex gap-x-2 px-5 py-4">
    <div class="w-[40px]"></div>
    <div class="w-[250px]">Клиент</div>
    <div class="w-[150px]">Дисконт</div>
    <div class="w-[250px]">Расчетная ставка</div>
    <div class="w-[250px]">Ставка</div>
    <div class="w-[150px]">Комментарий</div>
  </div>
  <tui-accordion [rounded]="false">
    @for (payment of payments$ | async; track payment.id; let index = $index) {
      <tui-accordion-item borders="top-bottom" [showArrow]="false">
        <div class="flex gap-x-2">
          <div class="w-[40px] rounded-xl bg-purple-200 text-center">
            {{ index + 1 }}
          </div>
          <div class="w-[250px] overflow-hidden disappearing">
            {{ payment.clientJson.name }}
          </div>
          <div class="w-[150px] overflow-hidden disappearing">
            {{
              payment.clientJson.discount || 0 | number: '1.2-2' | spaceNumber
            }}%
          </div>
          <div class="w-[250px] overflow-hidden disappearing">
            {{
              payment.calculatedRateJson.calculatedSum || 0
                | number: '1.2-2'
                | spaceNumber
            }}
            {{ payment.calculatedRateJson.rateClassifier?.currencyLetter }}
          </div>
          <div class="w-[250px] overflow-hidden disappearing">
            {{
              payment.calculatedRateJson.finalSum || 0
                | number: '1.2-2'
                | spaceNumber
            }}
            {{ payment.calculatedRateJson.rateClassifier?.currencyLetter }}
          </div>
          <div class="w-[150px] overflow-hidden disappearing">
            {{ payment.dealDetailsJson.comment }}
          </div>
        </div>

        <ng-template tuiAccordionItemContent>
          <app-cargo-payment [payment]="payment"></app-cargo-payment>
        </ng-template>
      </tui-accordion-item>
    }
  </tui-accordion>
</div>
